import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postSignup } from "../../utils/apis";
import { RegistraionSliceState } from "../../utils/types";

const initialState: RegistraionSliceState = {
  data: null,
  loading: false,
  error: null,
  status: 0,
  confirmPass: false,
  aadharVer: false,
  hasChecked: false,
  termsError: "",
  empType: "",
  empSubType: "",
  isSameAddress: false,
  isAadharVerified: false,
  isPanVerified: false,
  aadharVerifiedData: null,
  isAgentAadharVerified: false,
  isAgentPanVerified: false,
  isOccupationSchemaType:"",
  addressFlag:true,
};

export const handleRegistration = createAsyncThunk(
  "postRegistraion",
  async (data: any) => {
    const response = await postSignup(data);
    return response;
  }
);
const registrationSlice = createSlice({
  name: "postRegistraion",
  initialState,
  reducers: {
    handleAadharVerify: (state, action) => {
      state.aadharVer = action.payload;
    },
    handleHideAndShowPassword: (state, action) => {
      state.confirmPass = action.payload;
    },
    handleCheckboxChange: (state, action) => {
      state.hasChecked = action.payload;
    },
    setTermsError: (state, action) => {
      state.termsError = action.payload;
    },
    setEmpType: (state, action) => {
      state.empType = action.payload;
    },
    setEmpSubType: (state, action) => {
      state.empSubType = action.payload;
    },
    setIsAadharVerified: (state, action) => {
      state.isAadharVerified = action.payload;
    },
    isAadharVerified: (state, action) => {
      state.isAadharVerified = action.payload;
    },
    isPanVerified: (state, action) => {
      state.isPanVerified = action.payload;
    },
    setIsAgentAadharVerified: (state, action) => {
      state.isAgentAadharVerified = action.payload;
    },
    setIsPanVerified: (state, action) => {
      state.isPanVerified = action.payload;
    },
    setAadharVerifiedData: (state, action) => {
      state.aadharVerifiedData = action.payload;
    },
    setAddressFlag: (state, action) => {
      state.addressFlag = action.payload;
    },
    setPanVerifiedData: (state, action) => {
      state.isAgentPanVerified = action.payload;
    },
    setIsOccupationSchemaType: (state, action) => {
      state.isOccupationSchemaType = action.payload;
    },
    setIsSameAddress: (state) => {
      state.isSameAddress = !state.isSameAddress;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleRegistration.pending, (state) => {
        state.loading = true;
        state.error = null;
        console.log("pending");
      })
      .addCase(handleRegistration.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(handleRegistration.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "postRegistraion failed";
        console.log("rejected");
      });
  },
});

export default registrationSlice.reducer;
export const {
  handleAadharVerify,
  handleHideAndShowPassword,
  handleCheckboxChange,
  setTermsError,
  setEmpType,
  setEmpSubType,
  setIsSameAddress,
  setIsAadharVerified,
  isAadharVerified,
  isPanVerified,
  setIsAgentAadharVerified,
  setIsPanVerified,
  setAadharVerifiedData,
  setPanVerifiedData,
  setIsOccupationSchemaType,
  setAddressFlag
} = registrationSlice.actions;
