import axios, { AxiosResponse } from "axios";
import { getUserFromLocalStorage } from "./localStorageUtil";
import { callApi } from "./apis";

interface AadhaarVerificationRequest {
  aadhaarNumber: string;
}

interface submitOtpAadhaarVerification {
  client_id: string;
  otp: string;
}

interface submitOtpAadhaarVerificationRes {
  isValid: boolean;
  message: string;
  [key: string]: any; // To accommodate additional fields from the API response
}
const token = getUserFromLocalStorage()?.token;
const secretKey = process.env.REACT_APP_API_INVINCIBLE_SECRET_KEY;
const clientId = process.env.REACT_APP_API_INVICIBLE_CLEINT_ID;
// Function to verify Aadhaar number using a third-party API
export const verifyAadhaarRequest = async (
  endPoint: string,
  aadhaarNumber: string
): Promise<any> => {
  const apiUrl = `${process.env.REACT_APP_API_INVICIBLE}${endPoint}`; // Replace with the actual API endpoint
  const apiKey = "YOUR_API_KEY"; // Replace with your API key or authentication details

  try {
    const response: AxiosResponse<any> = await axios.post(
      apiUrl,
      {
        aadhaarNumber,
      } as AadhaarVerificationRequest,
      {
        headers: {
          "Content-Type": "application/json",
          //   Authorization: `Bearer ${token}`, // Include authentication header if required
          secretKey: secretKey,
          clientId: clientId,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    console.error("Error verifying Aadhaar:", error);

    if (error.response) {
      // Handle API error response
      throw new Error(
        `API Error: ${error.response.status} - ${error.response.data.message}`
      );
    }

    throw new Error("An unexpected error occurred while verifying Aadhaar.");
  }
};
export const submitOtpForVerifyAadhaar = async (
  endPoint: string,
  client_id: string,
  otp: string
): Promise<any> => {
  const apiUrl = `${process.env.REACT_APP_API_INVICIBLE}${endPoint}`; // Replace with the actual API endpoint

  try {
    const response: AxiosResponse<any> = await axios.post(
      apiUrl,
      {
        client_id,
        otp,
      } as submitOtpAadhaarVerification,
      {
        headers: {
          "Content-Type": "application/json",
          //   Authorization: `Bearer ${token}`, // Include authentication header if required
          secretKey: secretKey,
          clientId: clientId,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    console.error("Error verifying Aadhaar:", error);

    if (error.response) {
      // Handle API error response
      throw new Error(
        `API Error: ${error.response.status} - ${error.response.data.message}`
      );
    }

    throw new Error("An unexpected error occurred while verifying Aadhaar.");
  }
};
interface PanVerificationRequest {
  panNumber: string;
}
interface IfscCodeVerificationRequest {
  ifscCode: string;
}
interface BankAccountVerificationRequest {
  ifsc: string;
  bankAccount: string;
}
interface upiIdVerificationRequest {
  upiId: string;
}

// Function to verify PAN using a third-party API
export const verifyPan = async (
  endPoint: string,
  panNumber: string
): Promise<any> => {
  const apiUrl = `${process.env.REACT_APP_API_INVICIBLE}${endPoint}`; // Replace with the actual API endpoint

  try {
    const response: AxiosResponse<any> = await axios.post(
      apiUrl,
      {
        panNumber,
      } as PanVerificationRequest,
      {
        headers: {
          "Content-Type": "application/json",
          secretKey: secretKey,
          clientId: clientId,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    console.error("Error verifying PAN:", error);

    if (error.response) {
      // Handle API error response
      throw new Error(
        `API Error: ${error.response.status} - ${error.response.data.message}`
      );
    }

    throw new Error("An unexpected error occurred while verifying PAN.");
  }
};
export const verifyIFSC = async (
  endPoint: string,
  ifscCode: string
): Promise<any> => {
  const apiUrl = `${process.env.REACT_APP_API_INVICIBLE}${endPoint}`; // Replace with the actual API endpoint

  try {
    const response: AxiosResponse<any> = await axios.post(
      apiUrl,
      {
        ifscCode,
      } as IfscCodeVerificationRequest,
      {
        headers: {
          "Content-Type": "application/json",
          secretKey: secretKey,
          clientId: clientId,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    console.error("Error verifying IFSC:", error);

    if (error.response) {
      // Handle API error response
      throw new Error(
        `API Error: ${error.response.status} - ${error.response.data.message}`
      );
    }

    throw new Error("An unexpected error occurred while verifying IFSC.");
  }
};
export const verifyUpi = async (
  endPoint: string,
  upiId: string
): Promise<any> => {
  const apiUrl = `${process.env.REACT_APP_API_INVICIBLE}${endPoint}`; // Replace with the actual API endpoint

  try {
    const response: AxiosResponse<any> = await axios.post(
      apiUrl,
      {
        upiId,
      } as upiIdVerificationRequest,
      {
        headers: {
          "Content-Type": "application/json",
          secretKey: secretKey,
          clientId: clientId,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    console.error("Error verifying upiId:", error);

    if (error.response) {
      // Handle API error response
      throw new Error(
        `API Error: ${error.response.status} - ${error.response.data.message}`
      );
    }

    throw new Error("An unexpected error occurred while verifying upiId.");
  }
};

export const verifyBankAccount = async (
  endPoint: string,
  ifsc: string,
  bankAccount: string
): Promise<any> => {
  const apiUrl = `${process.env.REACT_APP_API_INVICIBLE}${endPoint}`; // Replace with the actual API endpoint

  try {
    const response: AxiosResponse<any> = await axios.post(
      apiUrl,
      {
        bankAccount: String(bankAccount),
        ifsc,
      } as BankAccountVerificationRequest,
      {
        headers: {
          "Content-Type": "application/json",
          secretKey: secretKey,
          clientId: clientId,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    console.error("Error verifying Bank Account:", error);

    if (error.response) {
      // Handle API error response
      throw new Error(
        `API Error: ${error.response.status} - ${error.response.data.message}`
      );
    }

    throw new Error(
      "An unexpected error occurred while verifying Bank Account."
    );
  }
};

export const getOtpCount = async (aadhaarNumber: any) => {
  return await callApi(`/v1/getOtpCount/${aadhaarNumber}/1`, "GET", {});
};
