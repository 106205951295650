// loanData.ts
import In_GoldLoanBg from "../assets/inner-banners/Gold Lons_Banner.jpg";
import In_PersonalLoanBG from "../assets/inner-banners/personalloanBG.jpg";
import In_HomeLoanBG from "../assets/inner-banners/homeloanBG.jpg";
import In_VehicleLoanBG from "../assets/inner-banners/vehicleLoanBg.jpg";
import In_BusinessLoanBG from "../assets/inner-banners/businessLoanBG.jpg";
import In_PropertyLoanBG from "../assets/inner-banners/propertyloanBG.jpg";
import In_ArtsAntiqueLoanBG from "../assets/inner-banners/Art-&-Antique.jpg";
import In_SharesSecurityLoanBG from "../assets/inner-banners/shares-securityBG.jpg";
import In_SMELoanBG from "../assets/inner-banners/sme-loansBG.jpg";
import In_HolidayLoanBG from "../assets/inner-banners/holiday-loansBG.jpg";
import In_WeddingLoanBG from "../assets/inner-banners/weddings-loansBG.jpg";
import In_CALoanBG from "../assets/inner-banners/CA-LoansBG.jpg";
import In_DoctorLoanBG from "../assets/inner-banners/doctor-loansBG.jpg";

import Ad1 from "../assets/loans/ads/Ads1.png";
import Ad2 from "../assets/loans/ads/Ads2.jpg";
import Ad3 from "../assets/loans/ads/homeLoan-ads.jpg";
import Ad4 from "../assets/loans/ads/vehicle-loan.png";
import Ad5 from "../assets/loans/ads/arts-antique-loan.jpg";
import Ad6 from "../assets/loans/ads/shares-security-ads.jpg";
import Ad7 from "../assets/loans/ads/sme-Ads.jpg";
import Ad8 from "../assets/loans/ads/holiday-Ads.jpg";
import Ad9 from "../assets/loans/ads/wedding-Ads.jpg";
import Ad10 from "../assets/loans/ads/CA-Ads.jpg";
import Ad11 from "../assets/loans/ads/doctor-loan-ads.jpg";

import PersonalLoanEMICalculator from "../components/LoanEMICalculator/PersonalLoanEMICalculator";
import GoldLoanEMICalculator from "../components/LoanEMICalculator/GoldLoanEMICalculator";

export interface LoanInfo {
  title: string;
  bannerImage: string;
  description: string[];
  buttonText: string;
  emiCalculator: boolean;
  Calculator: React.ComponentType | null; // Allow null for no calculator
  adImage: string;
}

export const loanData: Record<string, LoanInfo> = {
  "gold-loan": {
    title: "Gold Loan",
    bannerImage: In_GoldLoanBg,
    description: [
      "Asha Purti Gold Loans allow you to access funds in lieu of your gold jewellery, ornaments, etc. If you are unable to get credit from banking channels or are unable to get the exact amount you need, a gold loan is the easiest way to meet your financial needs As part of our core business, we provide loans secured by gold jewellery or gold loans. ",
      "We primarily cater to individuals who possess gold jewellery but cannot access formal credit within a reasonable time, or for whom credit may not be available at all. We help our customers meet unanticipated or other short-term",
    ],
    buttonText: "Apply Now",
    emiCalculator: false,
    Calculator: null,
    adImage: Ad1,
  },

  "personal-loan": {
    title: "Personal Loan",
    bannerImage: In_PersonalLoanBG,
    description: [
      "Life can throw up a surprise anytime in the form of opportunities or challenges. A personal loan that can be used for a variety of end uses, including medical treatment, home renovation, travel, wedding, and any other urgent financial requirement.",
      "Asha Purti's personal loan serves multiple purposes and is available for both salaried and self-employed individuals. Now you need not postpone things on your bucket list! Our instant personal loan is available for salaried professionals in India at attractive interest rates and no hidden charges. You can get a Personal Loan of Rs. 50,000 up to Rs. 50,00,000 with minimal documentation and speedy approval from us. With our flexible tenors ranging up to 60 months, repay your loan at your convenience. What's more, you can also reduce your EMIs by up to 50%* with our Flexi Personal Loan.",
    ],
    buttonText: "Apply Now",
    emiCalculator: true,
    Calculator: PersonalLoanEMICalculator,
    adImage: Ad2,
  },

  "home-loan": {
    title: "Home Loan",
    bannerImage: In_HomeLoanBG,
    description: [
      "Owning a home is everyone's dream and we at Asha Purti turn this dream into a reality. Asha Purti's Home Loan offerings are tailor-made for purchasing a home or constructing one. We also offer to transfer your home loan balance. Asha Purti home loans extend from Rs. 2.5 lakhs up to Rs. 10.5 crores and start at an affordable interest rate of just 6.90%*. You can choose the home loan amount, tenure, and EMI period at your convenience. And what's more, is that our home loan processing involves minimum documentation and is easy and seamless.",
      "Our hassle-free documentation process makes your home-buying process very smooth and provides you with a home loan based on your requirements.",
    ],
    buttonText: "Apply Now",
    emiCalculator: false,
    Calculator: null,
    adImage: Ad3,
  },

  "vehicle-loan": {
    title: "Vehicle Loan",
    bannerImage: In_VehicleLoanBG,
    description: [
      "Owning your very own vehicle will help you meet your needs of comfort travel and convenience, no matter how varied it may be. A vehicle loan can help you get a step closer to owning your dream car.",
      "An instant vehicle loan from Asha Purti offers financial assistance for those seeking to purchase a new or used car. Our quick digital application process is designed to make your experience as comfortable as possible. We offer bike loans with a maximum loan to value - in some cases, even up to 100% at attractive interest rates and flexible repayment tenures up to 72 months to ensure that your monthly EMI payments are well within your budge. Our loan offerings include top-ups on existing vehicle loans, attractive interest rates, quick and speedy processing, and hassle-free documentation processing.",
    ],
    buttonText: "Apply Now",
    emiCalculator: false,
    Calculator: null,
    adImage: Ad4,
  },

  "business-loan": {
    title: "Business Loan",
    bannerImage: In_BusinessLoanBG,
    description: [
      "Business Loan is an unsecured credit you can avail to meet your growing business's urgent requirements. The quick infusion of cash through business finance enables you to make necessary purchases and payments on time ensuring smooth business functioning.",
      "At Asha Purti we understand that each business has unique financial needs. We take great pride and effort in ensuring that business owners and entrepreneurs meet their business goals by offering them the best possible financial solutions. We have designed an end-to-end digital process so that you can apply for a business loan from the convenience of your home, anytime and anywhere. An unsecured Business Loan can assist in meeting these financial requirements for your business which can include revamping the current business, setting up new machinery, purchasing inventory, increasing working capital or even keeping above the competitors.",
    ],
    buttonText: "Apply Now",
    emiCalculator: false,
    Calculator: null,
    adImage: Ad2,
  },

  "property-loan": {
    title: "Property Loan",
    bannerImage: In_PropertyLoanBG,
    description: [
      "Loan against property allows you to use the value locked up in a property to meet any expenses you may have. While the loan amount depends on the total value of your property, you are free to use the funds as you see fit. Your property acts as collateral for the loan, but you can continue to use it as before.",
      "Asha Purti offers an array of attractive options based on their specific needs along with flexible loan tenure and attractive interest rates. Your property is truly one of the most valuable assets you possess. You can use that property (both residential and commercial) as security to get immediate access to funds through our Loan Against Property. You can meet your business aspirations or any other personal requirement that shouldn't be compromised. We have put the power in your hands to make the best out of your property and your finance.",
    ],
    buttonText: "Apply Now",
    emiCalculator: false,
    Calculator: null,
    adImage: Ad1,
  },

  "arts-antique-loan": {
    title: "Arts & Antique Loan",
    bannerImage: In_ArtsAntiqueLoanBG,
    description: [
      "Selling an artwork is not just the only way to tap its value. If you want to retain possession of a piece but need to generate cash from it, you can simply use it as collateral for a loan. Borrowers have varying reasons for taking an art-backed loan.",
      "Commonly, loans secured by artworks are used to keep cash flowing in a new business, cover an unexpected need, or buy more art. But ultimately, most have taken this type of loan because it's convenient and practical.",
    ],
    buttonText: "Apply Now",
    emiCalculator: false,
    Calculator: null,
    adImage: Ad5,
  },

  "shares-security-loan": {
    title: "Shares Security Loan",
    bannerImage: In_SharesSecurityLoanBG,
    description: [
      "Meet your urgent need for cash without selling off your securities. Pledge your securities and get an instant loan/credit facility with attractive interest rates and minimal documentation.",
      "Asha Purti Loan allows individuals or entities to borrow money by pledging their existing shares, stocks, bonds, or other securities as collateral. Asha Purti assesses the value of these securities and provides you a loan amount based on a certain percentage of the collateral's total market value.",
    ],
    buttonText: "Apply Now",
    emiCalculator: false,
    Calculator: null,
    adImage: Ad6,
  },

  "sme-loan": {
    title: "SME Loan",
    bannerImage: In_SMELoanBG,
    description: [
      "Being a growing enterprise, we understand the challenges and needs of running a business. In order to successfully operate and make a profit, businesses need monetary support to carry out various activities such as resource building, inventory storage, etc.",
      "Asha Purti can give you a competitive edge by partnering in your growth story. Our quick and hassle-free loans are customized to suit your various business needs. ",
    ],
    buttonText: "Apply Now",
    emiCalculator: false,
    Calculator: null,
    adImage: Ad7,
  },

  "holiday-loan": {
    title: "Holiday Loan",
    bannerImage: In_HolidayLoanBG,
    description: [
      "It is designed to help individuals or families cover the extra expenses associated with holidays, Asha Purti Holiday loans provide borrowers with a lump sum of money to use for holiday-related expenditures.",
      "You can now walk into any of our Asha purti branches offering Holiday loans and can avail of service for any value instantly. With our simple and easy documentation process, the loan can be availed across the counter.",
    ],
    buttonText: "Apply Now",
    emiCalculator: false,
    Calculator: null,
    adImage: Ad8,
  },

  "wedding-loan": {
    title: "Wedding Loan",
    bannerImage: In_WeddingLoanBG,
    description: [
      "Marriage is a significant milestone in life, and you may want to celebrate it lavishly. With rituals and functions spanning several days, the event is quite an expensive one.",
      "This is where Asha Purti Wedding Loan steps in, allowing you to fund the day of your dreams without exhausting your savings. Also known as a wedding loan by specific lenders, this is a personal loan that helps you meet all marriage-related expenses without any stress.",
    ],
    buttonText: "Apply Now",
    emiCalculator: false,
    Calculator: null,
    adImage: Ad9,
  },

  "ca-loan": {
    title: "CA Loan",
    bannerImage: In_CALoanBG,
    description: [
      "These loans cater to the unique financial needs and circumstances of Chartered Accountants, who often have specific requirements related to their practice, business expansion, or personal financial goals.",
      "Asha Purti Loans provides Chartered Accountants with access to funds that can be used for various purposes, it may be used for purposes such as setting up or expanding a CA practice, pursuing further education or certifications, meeting personal financial goals, or addressing unexpected financial challenges.",
    ],
    buttonText: "Apply Now",
    emiCalculator: false,
    Calculator: null,
    adImage: Ad10,
  },

  "doctor-loan": {
    title: "Doctor Loan",
    bannerImage: In_DoctorLoanBG,
    description: [
      "Discover our tailored Doctor Loan program, designed exclusively for medical professionals seeking financial support. Whether you're establishing a practice, upgrading equipment, expanding services, or consolidating debt, our flexible financing options provide swift access to funds with competitive rates and convenient repayment terms.",
      "Asha Purti Doctor Loan offers specialized funding solutions catering to the unique needs of healthcare practitioners. Benefit from a seamless application process, personalized service, and dedicated support from a team that understands the challenges and aspirations of medical professionals. Empower your practice and achieve your professional goals with our comprehensive Doctor Loan.",
    ],
    buttonText: "Apply Now",
    emiCalculator: false,
    Calculator: null,
    adImage: Ad11,
  },

  // Add more loan types here if needed
};
console.log("loanData object:", loanData);
