import Header from '../../../components/Header'
import Container from '../../../components/ui/Container'
import DashboardBG from '../../../assets/BG.png'
import { getUserFromLocalStorage } from '../../../utils/localStorageUtil'
import { useEffect, useState } from 'react'
import {
	getAdminDashboardData,
	getAgentDashboardData,
} from '../../../utils/apis'

function AgentDashboard() {
	const style: React.CSSProperties = {
		backgroundImage: `url(${DashboardBG})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
	}

	const [agentData, setAgentData] = useState<any>({})
	const agentId = getUserFromLocalStorage()?.borrowerId
	const role = getUserFromLocalStorage()?.role

	useEffect(() => {
		role === 'ADMIN'
			? getAdminDashboardData().then((res) => {
					setAgentData(res)
			  })
			: getAgentDashboardData(agentId).then((res) => {
					setAgentData(res)
			  })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<Header name="Dashboard" />
			<div className="flex min-h-screen mt-16 md:mt-0 relative" style={style}>
				<Container className="w-full">
					<div className="px-8 py-6 mt-16">
						<div className="grid grid-cols-3 gap-4">
							<div className="bg-white flex items-center justify-between border border-solid border-[#ba8b40] shadow-shadow1">
								<label className="p-6 mb-0 text-green-700 text-lg font-semibold lette -tracking-wider">
									No. of Borrowers:
								</label>
								<span className="h-full w-24 flex items-center justify-center border-l border-solid border-[#b1b1b1] bg-[#fff7e7] text-lg text-yellow-950 font-semibold">
									{role === 'ADMIN'
										? agentData?.Data?.allAdminBorrower
										: agentData?.totalBorrowers}
								</span>
							</div>
							<div className="bg-white flex items-center justify-between border border-solid border-[#ba8b40] shadow-shadow1">
								<label className="p-6 mb-0 text-green-700 text-lg font-semibold lette -tracking-wider">
									No. of Pending Borrowers:
								</label>
								<span className="h-full w-24 flex items-center justify-center border-l border-solid border-[#b1b1b1] bg-[#fff7e7] text-lg text-yellow-950 font-semibold">
									{role === 'ADMIN'
										? agentData?.Data?.pendingAdminBorrower
										: agentData?.pendingBorrowers}
								</span>
							</div>
							<div className="bg-white flex items-center justify-between border border-solid border-[#ba8b40] shadow-shadow1">
								<label className="p-6 mb-0 text-green-700 text-lg font-semibold lette -tracking-wider">
									No. of Approved Borrowers:
								</label>
								<span className="h-full w-24 flex items-center justify-center border-l border-solid border-[#b1b1b1] bg-[#fff7e7] text-lg text-yellow-950 font-semibold">
									{role === 'ADMIN'
										? agentData?.Data?.approvedAdminBorrowers
										: agentData?.approvedBorrowers}
								</span>
							</div>
						</div>
						{role === 'ADMIN' && (
							<div className="grid grid-cols-3 gap-4 mt-16">
								<div className="bg-white flex items-center justify-between border border-solid border-[#ba8b40] shadow-shadow1">
									<label className="p-6 mb-0 text-green-700 text-lg font-semibold lette -tracking-wider">
										Total Loan Amount:
									</label>
									<span className="h-full w-24 flex items-center justify-center border-l border-solid border-[#b1b1b1] bg-[#fff7e7] text-lg text-yellow-950 font-semibold">
										{agentData?.Data?.totalDisbursedAmount}
									</span>
								</div>
								<div className="bg-white flex items-center justify-between border border-solid border-[#ba8b40] shadow-shadow1">
									<label className="p-6 mb-0 text-green-700 text-lg font-semibold lette -tracking-wider">
										Total EMI Received:
									</label>
									<span className="h-full w-24 flex items-center justify-center border-l border-solid border-[#b1b1b1] bg-[#fff7e7] text-lg text-yellow-950 font-semibold">
										{agentData?.Data?.totalEmiReceived}
									</span>
								</div>
								<div className="bg-white flex items-center justify-between border border-solid border-[#ba8b40] shadow-shadow1">
									<label className="p-6 mb-0 text-green-700 text-lg font-semibold lette -tracking-wider">
										Total Agent Count:
									</label>
									<span className="h-full w-24 flex items-center justify-center border-l border-solid border-[#b1b1b1] bg-[#fff7e7] text-lg text-yellow-950 font-semibold">
										{agentData?.Data?.totalAgentCount}
									</span>
								</div>
							</div>
						)}
						{role === 'AGENT' && (
							<>
								<div className="grid grid-cols-3 gap-4 mt-16">
									<div className="bg-white flex items-center justify-between border border-solid border-[#ba8b40] shadow-shadow1">
										<label className="p-6 mb-0 text-green-700 text-lg font-semibold lette -tracking-wider">
											Total Loan Amount:
										</label>
										<span className="h-full w-24 flex items-center justify-center border-l border-solid border-[#b1b1b1] bg-[#fff7e7] text-lg text-yellow-950 font-semibold">
											{agentData?.sumOfLoanAmount}
										</span>
									</div>
									<div className="bg-white flex items-center justify-between border border-solid border-[#ba8b40] shadow-shadow1">
										<label className="p-6 mb-0 text-green-700 text-lg font-semibold lette -tracking-wider">
											Total EMI Received:
										</label>
										<span className="h-full w-24 flex items-center justify-center border-l border-solid border-[#b1b1b1] bg-[#fff7e7] text-lg text-yellow-950 font-semibold">
											{agentData?.sumOfEmi}
										</span>
									</div>
									<div className="bg-white flex items-center justify-between border border-solid border-[#ba8b40] shadow-shadow1">
										<label className="p-6 mb-0 text-green-700 text-lg font-semibold lette -tracking-wider">
											Total Commission Received:
										</label>
										<span className="h-full w-24 flex items-center justify-center border-l border-solid border-[#b1b1b1] bg-[#fff7e7] text-lg text-yellow-950 font-semibold">
											{agentData?.commissionOnEmi}
										</span>
									</div>
								</div>
								<div className="grid grid-cols-3 gap-4 mt-16">
									<div className="bg-white flex items-center justify-between border border-solid border-[#ba8b40] shadow-shadow1">
										<label className="p-6 mb-0 text-green-700 text-lg font-semibold lette -tracking-wider">
											Total EMI Due:
										</label>
										<span className="h-full w-24 flex items-center justify-center border-l border-solid border-[#b1b1b1] bg-[#fff7e7] text-lg text-yellow-950 font-semibold">
											{agentData?.dueEmiAmount}
										</span>
									</div>
								</div>
							</>
						)}
					</div>
				</Container>
			</div>
		</>
	)
}

export default AgentDashboard
