import { useEffect, useState } from "react";
import CustomModal from "../CustomModal";
import { PrivacyPolicyModalData } from "../../utils/PrivacyPolicyModalData";
import { TermsModalData } from "../../utils/TermsModalData";
import CustomDropdown from "./Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { updatePersonalInfo } from "../../redux/registration/registrationFormDataSlice";
import {
  handleHideAndShowPassword,
  handleCheckboxChange,
  setTermsError,
  setIsSameAddress,
  setIsPanVerified,
  setIsAadharVerified,
  setAddressFlag,
} from "../../redux/registration/registrationSlice";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { AppDispatch, RootState } from "../../utils/store";
import { PersonalInfoProp } from "../../utils/types";
import LoanEMICalculator from "../LoanEMICalculator";
import "../../styles/usersStyles/registerModal.css";
import { getUserFromLocalStorage } from "../../utils/localStorageUtil";
import {
  getOtpCount,
  submitOtpForVerifyAadhaar,
  verifyAadhaarRequest,
  verifyPan,
} from "../../utils/verificationApis";
import OTPVerificationInput from "../OTPVerificationInput";
import { toast } from "react-toastify";
import { FiCheckCircle } from "react-icons/fi";
import Loader from "../../utils/Loader";

const PersonalInfo: React.FC<PersonalInfoProp> = ({
  formik,
  handnleAllInputsBlur,
  handnleAllInputsChange,
  setTenureMessage,
  tenureMessage,
  handleBack,
  handleSubmit,
  activeTab,
}) => {
  const [otpSent, setOtpSent] = useState(false);
  // const [addressFlag, setAddressFlag] = useState(true);
  const [aadharVerificationLoader, setAadharVerificationLoader] =
    useState(false);
  const [panVerificationLoader, setPanVerificationLoader] = useState(false);
  const [aadharSumbitOtpLoader, setAadharSumbitOtpLoader] = useState(false);
  const [otp, setOtp] = useState("");
  const [aadharOtp, setAadhaOtp] = useState("");
  const [aadharVerReq, setAadharVerReq] = useState<any>();
  const [aadharNumForVerify, setAadharForVerify] = useState<any>();
  const [panForVerify, setPanForVerify] = useState<any>();
  const [aadharVerifiedData, setAadharVerifiedData] = useState<any>();
  const [panVerifiedData, setPanVerifiedData] = useState<any>();
  const [otpMobile, setOtpMobile] = useState("");
  const [otpVerifiedMobile, setOtpVerifiedMobile] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [otpErrorMobile, setOtpErrorMobile] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModalMobile, setShowModalMobile] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isCustModalOpenCalculator, setIsCustModalOpenCalculator] =
    useState(false);
  const [AadharOtpModal, setAadharOtpModal] = useState(false);
  const [isCustModalOpenTermsConditions, setIsCustModalOpenTermsConditions] =
    useState(false);
  const [isCustModalOpenPrivacyPolicy, setIsCustModalOpenPrivacyPolicy] =
    useState(false);
  const [tenureOptions, setTenureOptions] = useState([
    "Select an option",
    "100 days",
    "200 days",
    "300 days",
    "52 weeks",
    "104 weeks",
  ]);
  const [selectedTenure, setSelectedTenure] = useState("");

  const { personalInfo } = useSelector(
    (state: RootState) => state.registrationFormData
  );
  const {
    confirmPass,
    hasChecked,
    isSameAddress,
    isAadharVerified,
    isPanVerified,
    addressFlag,
  } = useSelector((state: RootState) => state.registrationSlice);
  const dispatch = useDispatch<AppDispatch>();

  const roleType = getUserFromLocalStorage()?.role;

  const maritalStatusoption = ["Married", "Unmarried"];
  const genderOption = ["Male", "Female"];
  const loanoptions = ["Personal Loan"];
  let selectedOptionLoantype =
    personalInfo?.loanTypeId === 1
      ? "Personal Loan"
      : personalInfo?.loanTenureId === 2
      ? "Gold Loan"
      : "";
  let randomNumber = Math.floor(1000 + Math.random() * 9000);

  const handleGetOtpCount = async () => {
    try {
      if (formik?.values?.aadhaarNumber) {
        setAadharVerificationLoader(true);
        let res = await getOtpCount(formik?.values?.aadhaarNumber);
        if (
          res?.statusCode === 200 &&
          res?.status?.toLowerCase() === "success"
        ) {
          setAadharVerificationLoader(false);
          return res?.otpCount;
        } else {
          setAadharVerificationLoader(false);
          toast.error(res?.message);
          return null;
        }
      } else {
        setAadharVerificationLoader(false);
        toast.error("please enter Aadhar number");
      }
    } catch (error) {
      setAadharVerificationLoader(false);
      toast.error("server error !");
    }
  };

  const handleVerifyAadhar = async () => {
    try {
      if (aadharNumForVerify) {
        setAadharVerificationLoader(true);
        const result = await verifyAadhaarRequest(
          "aadhaarVerification/requestOtp",
          aadharNumForVerify
        );
        if (result?.result?.message_code === "success") {
          setAadharVerReq(result);
          openAadharOtpModal();
          setAadharVerificationLoader(false);
          toast.success("OTP send successfully !");
        } else {
          toast.error(result?.result?.message);
        }
      }
    } catch (err: any) {
      toast.error("Something went wrong.");
      setAadharVerificationLoader(false);
    }
  };

  const handleConfirmationForResendOtp = async () => {
    let countStatus = await handleGetOtpCount();
    if (countStatus) {
      Number(countStatus) < 4 && handleVerifyAadhar();
      // Number(countStatus) < 4 && alert(countStatus);
    }
  };

  const handleVerifyPan = async () => {
    try {
      if (panForVerify) {
        setPanVerificationLoader(true);
        const result = await verifyPan("panAllInOne", panForVerify);
        console.log("send otp", result);
        if (result?.code === 200) {
          setPanVerificationLoader(false);
          toast.success("Verified Successully");
          setPanVerifiedData(result);
          dispatch(setIsPanVerified(true));
        } else {
          setPanVerificationLoader(false);
          toast.error(result?.message);
        }
      }
    } catch (err: any) {
      setPanVerificationLoader(false);
      console.log("handleVerifyPan :", err);
      toast.error("Something went wrong.");
    }
  };

  const handleSubmitAadharOtp = async () => {
    try {
      if ((aadharVerReq?.result?.data?.client_id, aadharOtp)) {
        setAadharSumbitOtpLoader(true);
        const result = await submitOtpForVerifyAadhaar(
          "aadhaarVerification/submitOtp",
          aadharVerReq?.result?.data?.client_id,
          aadharOtp
        );
        if (result?.result?.message_code === "success") {
          setAadharSumbitOtpLoader(false);
          toast.success("Verified Successfully");
          dispatch(setIsAadharVerified(true));
          setAadharVerifiedData(result);
          setAadharOtpModal(false);
          formik.setFieldValue("fullName", result?.result?.data?.full_name);
          formik.setFieldValue(
            "address",
            `${result?.result?.data?.address?.house} ${result?.result?.data?.address?.street}`.trim()
          );
          formik.setFieldValue("city", result?.result?.data?.address?.dist);
          formik.setFieldValue("state", result?.result?.data?.address?.state);
          formik.setFieldValue("pincode", result?.result?.data?.zip);
          formik.setFieldValue("birthDate", result?.result?.data?.dob);
          dispatch(
            setAddressFlag(
              !!result?.result?.data?.address?.house ||
                !!result?.result?.data?.address?.street
            )
          );
        } else {
          setAadharSumbitOtpLoader(false);
          toast.error(result?.message);
        }
      }
    } catch (err: any) {
      console.log("Aadhar Sumbit Otp :", err);
      setAadharSumbitOtpLoader(false);
      toast.error("Invalid OTP");
    }
  };

  const handleVerifyOtpMobile = () => {
    if (parseInt(otpMobile) === randomNumber) {
      setOtpVerifiedMobile(true);
      setOtpErrorMobile("null");
      setShowModalMobile(false);
    } else {
      setOtpVerifiedMobile(false);
      setOtpErrorMobile("Invalid OTP. Please try again.");
    }
  };

  const handleMaritalStatusSelect = (option: any) => {
    dispatch(updatePersonalInfo({ maritalStatus: option }));
    formik.setFieldValue("maritalStatus", option);
  };

  const handleGenderSelect = (option: any) => {
    dispatch(updatePersonalInfo({ gender: option }));
    formik.setFieldValue("gender", option);
    // formik.setFieldTouched("gender", true);
  };

  const handleLoanSelect = (option: string) => {
    if (option === "Personal Loan") {
      dispatch(updatePersonalInfo({ loanTypeId: 1 }));
      formik.setFieldValue("loanTypeId", 1);
    } else if (option === "Other") {
      dispatch(updatePersonalInfo({ loanTypeId: 2 }));
      formik.setFieldValue("loanTypeId", 2);
    }
  };

  const handleTenureSelect = (event: any) => {
    const option = event.target.value;
    formik.setFieldValue("tenure", option);
    setSelectedTenure(option);
    if (option === "100 days") {
      dispatch(updatePersonalInfo({ loanTenureId: 1 }));
    } else if (option === "200 days") {
      dispatch(updatePersonalInfo({ loanTenureId: 2 }));
    } else if (option === "300 days") {
      dispatch(updatePersonalInfo({ loanTenureId: 3 }));
    } else if (option === "52 weeks") {
      dispatch(updatePersonalInfo({ loanTenureId: 4 }));
    } else if (option === "104 weeks") {
      dispatch(updatePersonalInfo({ loanTenureId: 5 }));
    }
  };

  const handleCheckboxOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    dispatch(handleCheckboxChange(checked));
    dispatch(setTermsError(""));
  };

  const handleLoanAmount = () => {
    const loanAmount = formik?.values?.loanAmount;
    if (roleType === "AGENT") {
      if (loanAmount <= 25000) {
        setTenureOptions(["100 days"]);
        if (selectedTenure !== "100 days") {
          handleTenureSelect({ target: { value: "100 days" } });
        }
      } else {
        setTenureOptions([]);
      }
    } else {
      if (loanAmount <= 25000) {
        setTenureOptions(["100 days"]);
        if (selectedTenure !== "100 days") {
          handleTenureSelect({ target: { value: "100 days" } });
        }
      } else if (loanAmount > 25000 && loanAmount <= 75000) {
        setTenureOptions(["200 days", "300 days"]);
        if (!tenureOptions.includes(selectedTenure)) {
          handleTenureSelect({ target: { value: "200 days" } });
        }
      } else if (loanAmount > 75000 && loanAmount <= 150000) {
        setTenureOptions(["52 weeks", "104 weeks"]);
        if (!tenureOptions.includes(selectedTenure)) {
          handleTenureSelect({ target: { value: "52 weeks" } });
        }
      } else if (loanAmount > 150000 && loanAmount <= 250000) {
        setTenureOptions(["104 weeks"]);
        if (selectedTenure !== "104 weeks") {
          handleTenureSelect({ target: { value: "104 weeks" } });
        }
      } else {
        setTenureOptions([]);
      }
    }
  };

  const openCustModal = (e: any) => {
    e.preventDefault();
    setIsCustModalOpenTermsConditions(true);
  };
  const openAadharOtpModal = () => {
    setAadharOtpModal(true);
  };

  const closeCustModalTermsConditions = () =>
    setIsCustModalOpenTermsConditions(false);

  const openCustModalPrivacyPolicy = () => {
    setIsCustModalOpenPrivacyPolicy(true);
  };

  const closeCustModalPrivacyPolicy = () =>
    setIsCustModalOpenPrivacyPolicy(false);

  const openCustModalCalculator = () => {
    setIsCustModalOpenCalculator(true);
  };

  const closeCustModalCalculator = () => setIsCustModalOpenCalculator(false);

  const closeAadharOtpModal = () => setAadharOtpModal(false);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      console.log("Enter key pressed, modal not triggered.");
    }
  };

  const errorStyle: React.CSSProperties = {
    color: "red",
    fontSize: "10px",
    marginTop: "0",
    marginLeft: "4px",
    position: "absolute",
    left: 0,
    bottom: "-38px",
    right: 0,
    lineHeight: "1.2",
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
    minHeight: "35px",
    top: "auto",
  };

  const handleVerifyOtp = () => {};
  const handleCorrespondenceAddress = () => {
    console.log("isSameAddress :", isSameAddress);
    if (isSameAddress) {
      formik.setFieldValue(
        "correspondenceAddress",
        formik?.values?.correspondenceAddress
      );
      formik.setFieldValue("corrCity", formik?.values?.corrCity);
      formik.setFieldValue("corrPincode", formik?.values?.corrPincode);
      formik.setFieldValue("corrState", formik?.values?.corrState);
    } else {
      formik.setFieldValue("correspondenceAddress", formik?.values?.address);
      formik.setFieldValue("corrCity", formik?.values?.city);
      formik.setFieldValue("corrPincode", formik?.values?.pincode);
      formik.setFieldValue("corrState", formik?.values?.state);
    }
  };

  return (
    <>
      <div
        className="personalInfo space-y-4 min-h-[545px]"
        onKeyDown={handleKeyDown}
      >
        <h4 className="text-lg text-[#00a068] font-semibold capitalize text-center underline underline-offset-2 mb-2">
          Personal Loan Application
        </h4>
        <form>
          <div className="flex flex-col md:flex-row gap-2 md:gap-2 lg:gap-3 w-full justify-between md:flex-wrap lg:flex-nowrap">
            <div className="form-group w-full md:w-[49%] lg:w-2/6">
              <label>
                Aadhar Card <span className="text-red-700">*</span>
              </label>
              <div className="relative mb-1">
                <input
                  type="text"
                  name="aadhaarNumber"
                  value={
                    formik?.values.aadhaarNumber || personalInfo?.aadhaarNumber
                  }
                  disabled={isAadharVerified}
                  onChange={(e) => {
                    let { value } = e.target;
                    if (/^\d*$/.test(value) && value.length <= 12) {
                      handnleAllInputsChange(e);
                      setAadharForVerify(e.target.value);
                    }
                  }}
                  onBlur={handnleAllInputsBlur}
                  required
                  className="!pr-20"
                />
                {formik?.touched?.aadhaarNumber &&
                  formik?.errors.aadhaarNumber && (
                    <span style={errorStyle}>
                      {formik?.errors.aadhaarNumber}
                    </span>
                  )}

                <div className="verifyBtnBox flex justify-center lg:block absolute right-[3px] left-auto top-1">
                  {isAadharVerified ? (
                    <FiCheckCircle className="text-green-600 text-[1.2rem] mx-[6px] my-[6px]" />
                  ) : (
                    <button
                      type="button"
                      className="customBtn btn-dark !w-16"
                      onClick={() => {
                        handleConfirmationForResendOtp();
                      }}
                      disabled={formik?.values.aadhaarNumber.length <= 11}
                    >
                      {aadharVerificationLoader ? <Loader /> : "Verify"}
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group w-full md:w-[49%] lg:w-2/6">
              <label>
                PAN Card <span className="text-red-700">*</span>
              </label>
              <div className="relative mb-1">
                <input
                  type="text"
                  name="panCardNumber"
                  className="uppercase"
                  disabled={isPanVerified}
                  value={
                    formik?.values?.panCardNumber?.trim() ||
                    personalInfo?.panCardNumber?.trim()
                  }
                  onChange={(e) => {
                    let { value } = e.target;
                    if (value.length <= 10) {
                      handnleAllInputsChange(e);
                      setPanForVerify(e.target.value?.toUpperCase());
                    }
                  }}
                  onBlur={handnleAllInputsBlur}
                  required
                />
                {formik?.errors.panCardNumber &&
                  formik?.touched?.panCardNumber && (
                    <span style={errorStyle}>
                      {formik?.errors.panCardNumber}
                    </span>
                  )}
                <div className="verifyBtnBox flex justify-center lg:block absolute right-[3px] left-auto top-1">
                  {isPanVerified ? (
                    <FiCheckCircle className="text-green-600 text-[1.2rem] mx-[6px] my-[6px]" />
                  ) : (
                    <button
                      type="button"
                      className="customBtn btn-dark !w-16"
                      onClick={handleVerifyPan}
                      disabled={formik?.values.panCardNumber.length <= 9}
                    >
                      {panVerificationLoader ? <Loader /> : "Verify"}
                      {/* {true ? <Loader /> : "Verify"} */}
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group w-full md:w-[49%] lg:w-2/6">
              <label>
                Mobile No.<span className="text-red-700">*</span>
              </label>
              <div className="relative mb-1">
                <input
                  type="number"
                  name="mobileNumber"
                  value={
                    formik?.values.mobileNumber || personalInfo?.mobileNumber
                  }
                  onChange={handnleAllInputsChange}
                  onBlur={handnleAllInputsBlur}
                  required
                  className="!pr-20 no-spinner"
                />
                {otpErrorMobile && (
                  <span className="error absolute left-0 top-8 w-full">
                    {otpErrorMobile}
                  </span>
                )}
                {formik?.errors.mobileNumber &&
                  formik?.touched?.mobileNumber && (
                    <span style={errorStyle}>
                      {formik?.errors.mobileNumber}
                    </span>
                  )}
                {otpVerifiedMobile && <span>OTP Verified</span>}
              </div>
              {showModal && (
                <div className="modal">
                  <div className="modal-content">
                    <span className="close" onClick={() => setShowModal(false)}>
                      &times;
                    </span>
                    <h2>Enter OTP</h2>
                    <input
                      type="text"
                      placeholder="Enter OTP"
                      value={otp}
                      required
                    />
                    <button type="button" onClick={handleVerifyOtp}>
                      Verify OTP
                    </button>
                    <button type="button" onClick={() => setShowModal(false)}>
                      Close
                    </button>
                    {otpError && <span style={errorStyle}>{otpError}</span>}
                  </div>
                </div>
              )}
              {showModalMobile && (
                <div className="modal">
                  <div className="modal-content">
                    <span
                      className="close"
                      onClick={() => setShowModalMobile(false)}
                    >
                      &times;
                    </span>
                    <h2>Enter OTP</h2>
                    <input
                      type="text"
                      placeholder="Enter OTP"
                      value={otpMobile}
                      onChange={(e) => setOtpMobile(e.target.value)}
                      required
                    />
                    <button type="button" onClick={handleVerifyOtpMobile}>
                      Verify OTP
                    </button>
                    <button
                      type="button"
                      onClick={() => setShowModalMobile(false)}
                    >
                      Close
                    </button>
                    {otpErrorMobile && (
                      <span style={errorStyle}>{otpErrorMobile}</span>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="form-group w-full md:w-[49%] lg:w-2/6">
              <label>
                Email ID<span className="text-red-700">*</span>
              </label>
              <input
                type="email"
                name="email"
                value={formik?.values?.email || personalInfo?.email}
                onChange={handnleAllInputsChange}
                onBlur={handnleAllInputsBlur}
                required
              />
              {formik?.touched?.email && (
                <span style={errorStyle}>{formik?.errors?.email}</span>
              )}
            </div>
          </div>
          <div className="flex flex-col md:flex-row lg:flex-row gap-2 md:gap-2 lg:gap-4 w-full justify-between pt-6">
            <div className="form-group w-full md:w-[32%] lg:w-[32%]">
              <label>
                Full Name<span className="text-red-700">*</span>
              </label>
              <input
                type="text"
                name="fullName"
                value={formik?.values?.fullName || personalInfo?.fullName}
                onChange={handnleAllInputsChange}
                onBlur={handnleAllInputsBlur}
                required
                disabled
              />
              {formik?.touched?.fullName && (
                <span style={errorStyle}>{formik?.errors.fullName}</span>
              )}
            </div>
            <div className="form-group w-full md:w-[35%] lg:w-2/5">
              <label>
                Permanent Address<span className="text-red-700">*</span>
              </label>
              <input
                type="text"
                name="address"
                value={formik?.values?.address || personalInfo?.address}
                onChange={handnleAllInputsChange}
                onBlur={handnleAllInputsBlur}
                disabled={addressFlag}
                required
              />
              {formik?.touched?.address && (
                <span style={errorStyle}>{formik?.errors?.address}</span>
              )}
            </div>
            <div className="form-group w-full md:w-[16%] lg:w-[16%]">
              <label>
                City<span className="text-red-700">*</span>
              </label>
              <input
                type="text"
                name="city"
                value={formik?.values?.city || personalInfo?.city}
                onChange={handnleAllInputsChange}
                onBlur={handnleAllInputsBlur}
                disabled
                required
              />
              {formik?.touched?.city && (
                <span style={errorStyle}>{formik?.errors?.city}</span>
              )}
            </div>
            <div className="form-group w-full md:w-[22%] lg:w-[22%]">
              <label>
                State<span className="text-red-700">*</span>
              </label>
              <input
                type="text"
                name="state"
                value={formik?.values?.state || personalInfo?.state}
                onChange={handnleAllInputsChange}
                onBlur={handnleAllInputsBlur}
                disabled
                required
              />
              {formik?.touched?.state && (
                <span style={errorStyle}>{formik?.errors?.state}</span>
              )}
            </div>
            <div className="form-group w-full md:w-[15%] lg:w-[15%]">
              <label>
                Pin Code<span className="text-red-700">*</span>
              </label>
              <input
                type="number"
                name="pincode"
                value={formik?.values?.pincode || personalInfo?.pincode}
                onChange={handnleAllInputsChange}
                onBlur={handnleAllInputsBlur}
                disabled
                required
              />
              {formik?.touched?.pincode && (
                <span style={errorStyle}>{formik?.errors?.pincode}</span>
              )}
            </div>
          </div>
          <div className="flex flex-col md:flex-row lg:flex-row gap-2 md:gap-2 lg:gap-4 w-full justify-start pt-6">
            <div className="form-group w-full md:w-[40%] lg:w-[43%]">
              <label>
                Correspondence Address
                <span className="text-red-700">*</span>
                <div className="flex justify-center items-center md:ml-[87px]">
                  <span className="ml-2"> Same as Above </span>
                  <input
                    className="w-4 h-4 ml-2 cursor-pointer"
                    type="checkbox"
                    name="same"
                    onClick={() => {
                      handleCorrespondenceAddress();
                      dispatch(setIsSameAddress());
                    }}
                    checked={isSameAddress}
                    id=""
                  />
                </div>
              </label>
              <input
                type="text"
                name="correspondenceAddress"
                value={formik?.values?.correspondenceAddress}
                disabled={isSameAddress}
                onChange={handnleAllInputsChange}
                onBlur={handnleAllInputsBlur}
              />
              {formik?.touched?.correspondenceAddress && (
                <span style={errorStyle}>
                  {formik?.errors?.correspondenceAddress}
                </span>
              )}
            </div>
            <div className="form-group w-full md:w-[20%] lg:w-[18%]">
              <label>
                City <span className="text-red-700">*</span>
              </label>
              <input
                type="text"
                name="corrCity"
                value={formik?.values?.corrCity}
                disabled={isSameAddress}
                onChange={handnleAllInputsChange}
                onBlur={handnleAllInputsBlur}
              />
              {formik?.touched?.corrCity && (
                <span style={errorStyle}>{formik?.errors?.corrCity}</span>
              )}
            </div>
            <div className="form-group w-full md:w-[20%] lg:w-[18%]">
              <label>
                State <span className="text-red-700">*</span>
              </label>
              <input
                type="text"
                name="corrState"
                disabled={isSameAddress}
                onChange={handnleAllInputsChange}
                onBlur={handnleAllInputsBlur}
                value={formik?.values?.corrState}
              />
              {formik?.touched?.corrState && (
                <span style={errorStyle}>{formik?.errors?.corrState}</span>
              )}
            </div>
            <div className="form-group w-full md:w-[20%] lg:w-[17%]">
              <label>
                Pin Code <span className="text-red-700">*</span>
              </label>
              <input
                type="number"
                name="corrPincode"
                disabled={isSameAddress}
                value={formik?.values?.corrPincode}
                onChange={handnleAllInputsChange}
                onBlur={handnleAllInputsBlur}
                required
              />
              {formik?.touched?.corrPincode && (
                <span style={errorStyle}>{formik?.errors?.corrPincode}</span>
              )}
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-2 md:gap-2 lg:gap-4 w-full justify-between pt-6">
            <div className="form-group w-full md:w-[24%]">
              <label>
                Date of Birth <span className="text-red-700">*</span>
              </label>
              <input
                type="text"
                name="birthDate"
                value={formik?.values?.birthDate || personalInfo?.birthDate}
                onChange={handnleAllInputsChange}
                onBlur={handnleAllInputsBlur}
                required
                disabled
              />
              {formik?.touched?.birthDate && (
                <span style={errorStyle}>{formik?.errors?.birthDate}</span>
              )}
            </div>
            <div className="w-full md:w-full lg:w-[76%]">
              <div className="flex flex-col md:flex-row gap-4 md:gap-2 lg:gap-4">
                <div className="w-full md:w-2/4 flex items-center">
                  <div className="flex flex-col md:flex-row w-full gap-4 md:gap-2 lg:gap-4">
                    <div className="form-group w-full md:w-2/4">
                      <label htmlFor="gender" className="mb-1 text-gray-700">
                        Gender
                        <span className="text-red-700">*</span>
                      </label>
                      <CustomDropdown
                        isSelected={personalInfo?.gender}
                        options={genderOption}
                        onSelect={handleGenderSelect}
                        className="!w-full"
                      />
                      {formik?.touched?.gender && (
                        <span style={errorStyle}>{formik?.errors?.gender}</span>
                      )}
                    </div>

                    <div className="form-group w-full md:w-2/4">
                      <label
                        htmlFor="maritalStatus"
                        className="mb-1 text-gray-700"
                      >
                        Marital Status
                        <span className="text-red-700">*</span>
                      </label>
                      <CustomDropdown
                        isSelected={personalInfo?.maritalStatus}
                        options={maritalStatusoption}
                        onSelect={handleMaritalStatusSelect}
                        className="!w-full"
                      />
                      {formik?.touched?.maritalStatus && (
                        <span style={errorStyle}>
                          {formik?.errors?.maritalStatus}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-2/4 flex items-center">
                  <div className="flex flex-col md:flex-row w-full gap-4 md:gap-2 lg:gap-4">
                    <div className="form-group w-full md:w-1/2 relative">
                      <label>
                        Set Password <span className="text-red-700">*</span>
                      </label>
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={formik?.values?.password}
                        onChange={handnleAllInputsChange}
                        onBlur={handnleAllInputsBlur}
                        required
                        className="!mb-0"
                      />
                      <span
                        className="absolute right-3 top-[39px] cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </span>
                      {formik?.touched?.password && (
                        <span style={errorStyle}>
                          {formik?.errors?.password}
                        </span>
                      )}
                    </div>
                    <div className="form-group w-full md:w-1/2 relative">
                      <label>
                        Confirm Password <span className="text-red-700">*</span>
                      </label>
                      <input
                        type={confirmPass ? "text" : "password"}
                        name="confirmPassword"
                        value={formik?.values?.confirmPassword}
                        onChange={handnleAllInputsChange}
                        onBlur={handnleAllInputsBlur}
                        required
                        className="!mb-0"
                      />
                      <span
                        className="absolute right-3 top-[39px] cursor-pointer"
                        onClick={() =>
                          dispatch(handleHideAndShowPassword(!confirmPass))
                        }
                      >
                        {confirmPass ? <FaEye /> : <FaEyeSlash />}
                      </span>
                      {formik?.touched?.confirmPassword && (
                        <span style={errorStyle}>
                          {formik?.errors?.confirmPassword}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row lg:flex-row gap-2 md:gap-2 lg:gap-4 w-full justify-start pt-6">
            <div className="form-group w-full md:w-[19%] lg:w-[24%]">
              <label className="!mb-2">
                Loan Type :<span className="text-red-700">*</span>
              </label>
              <CustomDropdown
                isSelected={selectedOptionLoantype}
                options={loanoptions}
                onSelect={handleLoanSelect}
                className="!w-full"
              />
              {formik?.touched?.loanTypeId && (
                <span style={errorStyle}>{formik?.errors?.loanTypeId}</span>
              )}
            </div>
            <div
              className="form-group w-full md:w-[19%] lg:w-[17%]"
              hidden={!selectedOptionLoantype}
            >
              <label>
                Loan Amount<span className="text-red-700">*</span>
              </label>
              <div className="flex relative">
                <span className="rupees absolute left-0  border-gray-300 bg-white !mb-0  rounded-l-md  py-2 flex inset-y-0 p-1  items-center text-gray-500 ">
                  ₹
                </span>
                <input
                  type="number"
                  name="loanAmount"
                  placeholder="Enter amount"
                  value={formik?.values?.loanAmount || personalInfo?.loanAmount}
                  onChange={(e) => {
                    if (roleType === "AGENT") {
                      Number(e.target.value) > 25000
                        ? setTenureMessage("Max limit is 25000")
                        : setTenureMessage("");
                    }
                    handnleAllInputsChange(e);
                  }}
                  onBlur={(e) => {
                    handnleAllInputsBlur(e);
                    handleLoanAmount();
                  }}
                  className={`border border-gray-300 rounded-r-md pl-14 py-2 w-full ml-3 ${
                    !selectedOptionLoantype
                      ? "bg-gray-200 cursor-not-allowed"
                      : ""
                  }`}
                />
              </div>
              {formik?.touched?.loanAmount && formik?.errors?.loanAmount && (
                <span style={errorStyle}>{formik?.errors?.loanAmount}</span>
              )}

              <span style={errorStyle}>{tenureMessage} </span>
            </div>

            <div
              className="form-group w-full md:w-[19%] lg:w-[18%]"
              hidden={!selectedOptionLoantype}
            >
              <label htmlFor="tenure" className="mb-1 text-gray-700">
                Tenure
                <span className="text-red-700">*</span>
              </label>
              <select
                className={`border border-gray-300 rounded-md py-2 w-full !mb-0 ${
                  !selectedOptionLoantype
                    ? "bg-gray-200 cursor-not-allowed"
                    : ""
                }`}
                style={{ fontSize: "0.85rem" }}
                value={selectedTenure || ""}
                onChange={(e) => {
                  handleTenureSelect(e);
                }}
                onBlur={handnleAllInputsBlur}
              >
                {tenureOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {formik?.touched?.tenure && formik?.errors?.tenure && (
                <span style={errorStyle}>{formik?.errors?.tenure}</span>
              )}
            </div>

            <div className="" hidden={!selectedOptionLoantype}>
              <label>&nbsp;</label>
              <button
                type="button"
                onClick={openCustModalCalculator}
                className="px-4 btn btn-dark text-white lg:!px-7 rounded-md !py-2 shadow-shadow_3 !font-medium tracking-widest text-sm"
              >
                Calculate EMI
              </button>
            </div>
          </div>

          <div className="flex mt-4 relative">
            <label className="inline-block pl-8 md:flex flex-wrap items-center !text-xs md:pl-0 cursor-pointer mt-4">
              <input
                type="checkbox"
                name="checkbox"
                checked={hasChecked}
                onChange={handleCheckboxOnChange}
                className="!mb-0 w-4 h-4 mr-3 absolute left-0 md:relative cursor-pointer"
              />
              By Continuing, you agree to Ashapurti Loans
              <button className="text-blue-700" onClick={openCustModal}>
                &nbsp;Terms of Use&nbsp;
              </button>
              and
              <button
                className="text-blue-700"
                onClick={openCustModalPrivacyPolicy}
              >
                &nbsp;Privacy Policy
              </button>
            </label>
          </div>
        </form>
      </div>
      <div className="button-container">
        <button
          type="button"
          onClick={handleBack}
          disabled={activeTab === 0}
          className="backBtn"
        >
          Back
        </button>
        {activeTab < 2 ? (
          <button type="submit" className="nextBtn">
            Next
          </button>
        ) : (
          <button type="submit" className="submitBtn" onClick={handleSubmit}>
            Submit
          </button>
        )}
      </div>
      <CustomModal
        isOpenCustModal={isCustModalOpenTermsConditions}
        onClose={closeCustModalTermsConditions}
      >
        {TermsModalData &&
          TermsModalData.map((item, index) => (
            <div key={index}>
              <h3>{item.title}</h3>
              <p>{item.Paragraph}</p>
              <div className="mt-4 border-t-[1px] border-solid border-gray-300 pt-4 pl-4">
                <label className="inline-block pl-8 md:flex flex-wrap items-center !text-xs md:pl-0">
                  <input
                    type="checkbox"
                    name="checkbox"
                    checked={hasChecked}
                    onChange={handleCheckboxOnChange}
                    className="!mb-0 w-4 h-4 mr-3 absolute left-0 md:relative"
                  />
                  I Agree all conditions.
                </label>
              </div>
            </div>
          ))}
      </CustomModal>

      <CustomModal
        isOpenCustModal={isCustModalOpenPrivacyPolicy}
        onClose={closeCustModalPrivacyPolicy}
      >
        {PrivacyPolicyModalData &&
          PrivacyPolicyModalData.map((item, index) => (
            <div key={index}>
              <h3>{item.title}</h3>
              <p>{item.Paragraph}</p>
              <div className="mt-4 border-t-[1px] border-solid border-gray-300 pt-4 pl-4">
                <label className="inline-block pl-8 md:flex flex-wrap items-center !text-xs md:pl-0">
                  <input
                    type="checkbox"
                    name="checkbox"
                    checked={hasChecked}
                    onChange={handleCheckboxOnChange}
                    className="!mb-0 w-4 h-4 mr-3 absolute left-0 md:relative"
                  />
                  I Agree all conditions.
                </label>
              </div>
            </div>
          ))}
      </CustomModal>

      <div className="loanEMICalculator">
        <CustomModal
          isOpenCustModal={isCustModalOpenCalculator}
          onClose={closeCustModalCalculator}
        >
          <LoanEMICalculator />
        </CustomModal>
      </div>

      <div className="loanEMICalculator">
        <CustomModal
          isOpenCustModal={AadharOtpModal}
          onClose={closeCustModalCalculator}
          modalName="aadharVerificationModal"
        >
          <LoanEMICalculator />
        </CustomModal>
        <CustomModal
          isOpenCustModal={AadharOtpModal}
          onClose={closeAadharOtpModal}
          modalName="aadharVerificationModal"
        >
          <OTPVerificationInput
            setAadhaOtp={setAadhaOtp}
            handleSubmitAadharOtp={handleSubmitAadharOtp}
            handleVerify={handleConfirmationForResendOtp}
            isLoading={aadharSumbitOtpLoader}
            onClose={closeAadharOtpModal}
          />
        </CustomModal>
      </div>
    </>
  );
};

export default PersonalInfo;
