import './styles/App.css'
import { BrowserRouter, MemoryRouter, Route, Routes } from 'react-router-dom'
import ForgetPassword from './screens/Users/ForgetPassword'
import LoanCategories from './screens/Loans/crm/LoanCategories'
import LoanForm from './screens/Loans/crm/LoanForm'
import RegisterScreen from './screens/Users/RegisterScreen'
import NotificationScreen from './screens/Notifications/NotificationScreen'
import ResetPassword from './screens/Users/ResetPassword'
import Dashboard from './screens/Dashboard/Dashboard'
import LoginScreen from './screens/Users/LoginScreen'
import ProfileScreen from './screens/Users/ProfileScreen'
import Disbursement from './screens/Documents/Disbursement'
import BorrowerPaymentDetails from './screens/Documents/BorrowerPaymentDetails'
import Demo from './components/Demo'
import LoanDetails from './screens/Loans/user/LoanDetails'
import LoanDisbursement from './screens/Loans/user/LoanDisbursement'
import DocumentsUploadForm from './screens/Users/DocumentsUploadForm'
import DocumentReviewStatusPage from './screens/DRS/DocumentReviewStatus'
import UserProfileScreen from './screens/Users/UserProfileScreen'
import AadharOcr from './components/uploadDocumentForm/AadharOcr'
import Repayment from './screens/Repayment/Repayment'
import PayEmi from './screens/Repayment/PayEmi'
import Success from './screens/Repayment/Success'
import DemoPage from './screens/DemoPage'
import KnowledgeCenter from './screens/Landing/KnowledgeCenter'
import ContactUs from './screens/Landing/ContactUs'
import PrivacyPolicy from './screens/Landing/PrivacyPolicy'
import Terms from './screens/Landing/Terms'
import Refund from './screens/Landing/Refund'
import Faq from './screens/Landing/Faq'
import AboutUs from './screens/Landing/About'
import LoanLandingDetails from './screens/Landing/LoanLandingDetails'
import Home from './screens/Landing/Home'
import ReduxProvider from './redux/Landing/ReduxProvider'
import HomeScreen from './screens/GlobalScreen/HomeScreen'
import AgentSignUp from './screens/Users/Agent/AgentSignUp'
import AddBorrower from './screens/Users/Agent/AddBorrower'
import EditBorrower from './screens/Users/Agent/EditBorrower'
import ViewBorrower from './screens/Users/Agent/ViewBorrower'
import BorrowerList from './screens/Users/Agent/BorrowerList'
import OTPVerificationInput from './components/OTPVerificationInput'
import { useState } from 'react'
import ApprovedBorrowers from './screens/Users/Agent/ApprovedBorrowers'
import AgentProfile from './screens/Users/Agent/AgentProfile'
import AgentDashboard from './screens/Users/Agent/AgentDashboard'
import PendingBorrowers from './screens/Users/Agent/PendingBorrowers'
import { removeUserFromLocalStorage } from './utils/localStorageUtil'
import InterestRate from './screens/Users/Agent/InterestRate'
import AgentList from './screens/Users/Agent/AgentList'

function App() {
	const location = window.location.pathname
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [aadhaOtp, setAadhaOtp] = useState('')
	window.addEventListener('storage', (event) => {
		if (event.key === 'user') {
			console.warn('LocalStorage modified externally! Logging out...')
			removeUserFromLocalStorage()
			window.location.href = '/' // Redirect to login page
		}
	})

	return (
		<>
			{location.startsWith('/success') ||
			location.startsWith('/privacyPolicy') ? (
				<BrowserRouter>
					<Routes>
						<Route path="/success" element={<Success />} />
					</Routes>
					<ReduxProvider>
						<Routes>
							<Route path="/privacyPolicy" element={<PrivacyPolicy />} />
						</Routes>
					</ReduxProvider>
				</BrowserRouter>
			) : (
				<MemoryRouter>
					<ReduxProvider>
						<Routes>
							<Route path="/knowledge-center" element={<KnowledgeCenter />} />
							<Route path="/contactUs" element={<ContactUs />} />
							<Route path="/privacyPolicy" element={<PrivacyPolicy />} />
							<Route path="/terms" element={<Terms />} />
							<Route path="/refund" element={<Refund />} />
							<Route path="/faq" element={<Faq />} />
							<Route path="/aboutUs" element={<AboutUs />} />
							<Route path="/:loanType" element={<LoanLandingDetails />} />
							<Route path="/" element={<Home />} />
						</Routes>
					</ReduxProvider>
					<Routes>
						<Route path="/dashboard" element={<Dashboard />} />
						<Route path="/HomeScreen" element={<HomeScreen />} />
						<Route path="/login" element={<LoginScreen />} />
						<Route path="/profile" element={<ProfileScreen />} />
						<Route path="/register" element={<RegisterScreen />} />
						<Route path="/forgot-password" element={<ForgetPassword />} />
						<Route path="/reset-password" element={<ResetPassword />} />
						<Route path="/loan-categories" element={<LoanCategories />} />
						<Route path="/loan-categories/:slug" element={<LoanForm />} />
						<Route path="/notifications" element={<NotificationScreen />} />
						<Route path="/upload-documents" element={<DocumentsUploadForm />} />
						<Route path="/disbursement" element={<Disbursement />} />
						<Route path="/agent-signup" element={<AgentSignUp />} />
						<Route path="/add-borrower" element={<AddBorrower />} />
						<Route path="/edit-borrower" element={<EditBorrower />} />
						<Route path="/view-borrower" element={<ViewBorrower />} />
						<Route
							path="/otp-verification"
							element={
								<OTPVerificationInput
									setAadhaOtp={setAadhaOtp}
									handleSubmitAadharOtp={() => {}}
									handleVerify={() => {}}
								/>
							}
						/>
						<Route
							path="/borrowerpayment"
							element={<BorrowerPaymentDetails />}
						/>
						<Route path="/demo" element={<Demo />} />
						<Route path="/aadhar-ocr" element={<AadharOcr />} />
						<Route path="/loandetails" element={<LoanDetails />} />
						<Route path="/disbursementforloan" element={<LoanDisbursement />} />
						<Route
							path="/document-review-status-page"
							element={<DocumentReviewStatusPage />}
						/>
						<Route path="/user-profile" element={<UserProfileScreen />} />
						<Route path="/repayment" element={<Repayment />} />
						<Route path="/payEmi" element={<PayEmi />} />
						<Route path="/demo-page" element={<DemoPage />} />
						<Route path="/borrowerList" element={<BorrowerList />} />
						<Route path="/agent-profile" element={<AgentProfile />} />
						<Route path="/agent-dashboard" element={<AgentDashboard />} />
						<Route path="/pendingBorrowers" element={<PendingBorrowers />} />
						<Route path="/approvedBorrowers" element={<ApprovedBorrowers />} />
						<Route path="/interestRate" element={<InterestRate />} />
						<Route path="/agentList" element={<AgentList />} />
					</Routes>
				</MemoryRouter>
			)}
		</>
	)
}

export default App
