import React, { useEffect, useState } from "react";
import Banner from "../../../assets/Ashapurti-Gold-Loans_Form_Updated_BG.jpg";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../assets/Logo_circle.png";
import { ReactComponent as BackIcon } from "../../../assets/icons/back-arrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../utils/store";
import { handleAgentSignUp } from "../../../redux/Agent/agentSignUpSlice";
import { AgentSignup } from "../../../utils/types";
import { toast, ToastContainer } from "react-toastify";
import { FaHome } from "react-icons/fa";
import CustomModal from "../../../components/CustomModal";
import OTPVerificationInput from "../../../components/OTPVerificationInput";
import {
  getOtpCount,
  submitOtpForVerifyAadhaar,
  verifyAadhaarRequest,
  verifyPan,
} from "../../../utils/verificationApis";
import {
  setIsAgentAadharVerified,
  setPanVerifiedData,
} from "../../../redux/registration/registrationSlice";
import { FiCheckCircle } from "react-icons/fi";
import Loader from "../../../utils/Loader";
import { useFormik } from "formik";
import agentSignupValidation from "./agentSignupValidation";
import "../../../styles/usersStyles/agentSignUpForm.css";
// import AgentSignUp from "../../screens/Users/"

const AgentSignUp = () => {
  const [AadharOtpModal, setAadharOtpModal] = useState(false);
  const [aadharSumbitOtpLoader, setAadharSumbitOtpLoader] = useState(false);
  const [AadharVerifyLoader, setAadharVerifyLoader] = useState(false);
  const [panVerifyLoader, setPanVerifyLoader] = useState(false);
  const [addressFlag, setAddressFlag] = useState(true);
  const [villageOrTownFlag, setVillageOrTownFlag] = useState(true);
  const [talukaFlag, setTalukaFlag] = useState(true);
  const [aadharVerifiedData, setAadharVerifiedData] = useState<any>();
  const [aadharVerReq, setResponseOfSubmitAddharOtp] = useState<any>();
  const [aadharOtp, setAadhaOtp] = useState("");
  const [agentData, setAgentData] = useState<AgentSignup>({
    firstName: "",
    lastName: "",
    birthDate: "",
    gender: "",
    maritalStatus: "",
    aadharCard: "",
    panCard: "",
    mobileNumber: "",
    email: "",
    whatsappNumber: "",
    address: "",
    villageOrTown: "",
    taluka: "",
    district: "",
    state: "",
    domicile: "",
    pincode: "",
    panVerifyStatus: "",
  });
  const [autoPopulateData, setAutoPopulateData] = useState<any>({
    firstName: "",
    lastName: "",
    birthDate: "1998-05-20",
    gender: "female",
    maritalStatus: "Single",
    aadharCard: "",
    panCard: "BNRPT2011U",
    mobileNumber: "7775832277",
    email: "ashirvad.o@gllfl.com",
    whatsappNumber: "9876543210",
    address: "123, Main Street",
    villageOrTown: "Sample Town",
    taluka: "Sample Taluka",
    district: "Sample District",
    state: "Sample State",
    domicile: "Sample Domicile",
    pincode: "123456",
  });

  const formik = useFormik({
    initialValues: agentData,
    validationSchema: agentSignupValidation,
    onSubmit: (values) => {
      console.log("onSubmit :", values);
      handleSubmit(values);
    },
  });
  console.log("error :", formik.errors);
  const getErrorMessage = (fieldName: string) => {
    return (
      formik.touched[fieldName as keyof typeof formik.values] &&
      formik.errors[fieldName as keyof typeof formik.errors] && (
        <span className="error  left-0 top-8 w-full text-red-500 text-sm">
          {formik.errors[fieldName as keyof typeof formik.errors]}
        </span>
      )
    );
  };

  const navigate = useNavigate();
  const { isAgentAadharVerified, isAgentPanVerified } = useSelector(
    (state: RootState) => state.registrationSlice
  );
  const person: AgentSignup = {
    firstName: "",
    lastName: "",
    birthDate: "1998-05-20",
    gender: "female",
    maritalStatus: "Single",
    aadharCard: "",
    panCard: "BNRPT2011U",
    mobileNumber: "7775832277",
    email: "ashirvad.o@gllfl.com",
    whatsappNumber: "9876543210",
    address: "123, Main Street",
    villageOrTown: "Sample Town",
    taluka: "Sample Taluka",
    district: "Sample District",
    state: "Sample State",
    domicile: "Sample Domicile",
    pincode: "123456",
    panVerifyStatus: "verified",
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const handleSubmit = async (e: any) => {
    try {
      const result = await dispatch(handleAgentSignUp(e));
      if (result?.payload?.status?.toLowerCase() === "success") {
        setIsModalOpen(true);
        setTimeout(() => {
          navigate("/login");
        }, 2000);
        dispatch(setIsAgentAadharVerified(false));
        dispatch(setPanVerifiedData(false));
      } else {
        toast.error(result?.payload?.message);
      }
    } catch (error) {
      console.log("Agent Sign Up Error", error);
    }
  };
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    console.log(name, value);

    if (name === "aadharCard") {
      // Allow only numeric values and restrict to 12 digits
      if (/^\d*$/.test(value) && value.length <= 12) {
        formik.handleChange(e);
        setAgentData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        console.log("aaadhar:", value);
      }
    } else if (
      name === "panCard" ||
      name === "mobileNumber" ||
      name === "whatsappNumber"
    ) {
      if (value.length <= 10) {
        e.target.value = e.target.value.toUpperCase();
        formik.handleChange(e);
        setAgentData((prevData) => ({
          ...prevData,
          [name]: value?.toUpperCase(),
        }));
      }
    } else {
      formik.handleChange(e);
      setAgentData((prevData: any) => ({
        ...prevData,
        [name]: value, // Dynamically update the field that matches the input's `name`
      }));
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    navigate("/login");
  };
  const openAadharOtpModal = () => {
    setAadharOtpModal(true);
  };
  const closeAadharOtpModal = () => setAadharOtpModal(false);
  const handleSendOtpReqToVerifyAadhar = async () => {
    try {
      if (agentData.aadharCard) {
        setAadharVerifyLoader(true);
        const result = await verifyAadhaarRequest(
          "aadhaarVerification/requestOtp",
          agentData.aadharCard
        );
        console.log("send otp", result);
        if (result?.result?.message_code === "success") {
          setResponseOfSubmitAddharOtp(result);
          setAadharVerifyLoader(false);
          toast.success("OTP send successfully !");
          openAadharOtpModal();
        } else {
          toast.error(result?.message);
        }
      }
    } catch (err: any) {
      toast.error("Something went wrong.");
      setAadharVerifyLoader(false);
    }
  };
  const handleGetOtpCount = async () => {
    try {
      if (formik?.values?.aadharCard) {
        setAadharVerifyLoader(true);
        let res = await getOtpCount(formik?.values?.aadharCard);
        if (
          res?.statusCode === 200 &&
          res?.status?.toLowerCase() === "success"
        ) {
          setAadharVerifyLoader(false);
          return res?.otpCount;
        } else {
          setAadharVerifyLoader(false);
          toast.error(res?.message);
          setTimeout(() => closeAadharOtpModal(), 2000);
          return null;
        }
      } else {
        setAadharVerifyLoader(false);
        toast.error("please enter Aadhar number");
      }
    } catch (error) {
      setAadharVerifyLoader(false);
      toast.error("server error !");
    }
  };
  const handleConfirmationForResendOtp = async () => {
    let countStatus = await handleGetOtpCount();
    if (countStatus) {
      Number(countStatus) < 4 && handleSendOtpReqToVerifyAadhar();
      // Number(countStatus) < 4 && alert(countStatus);
    }
  };

  const handleSubmitAadharOtp = async () => {
    try {
      setAadharSumbitOtpLoader(true);
      if ((aadharVerReq?.result?.data?.client_id, aadharOtp)) {
        const result = await submitOtpForVerifyAadhaar(
          "aadhaarVerification/submitOtp",
          aadharVerReq?.result?.data?.client_id,
          aadharOtp
        );
        console.log("handleSubmitAadharOtp", result);
        if (result?.result?.message_code === "success") {
          setAadharSumbitOtpLoader(false);
          toast.success("Verified Successfully");
          dispatch(setIsAgentAadharVerified(true));
          setAadharVerifiedData(result);
          setAadharOtpModal(false);
          setAgentData({
            ...agentData,
            birthDate: result?.result?.data?.dob,
            address: `${result?.result?.data?.address?.house} ${result?.result?.data?.address?.street}`,
            villageOrTown: result?.result?.data?.address?.vtc,
            taluka: result?.result?.data?.address?.subdist,
            district: result?.result?.data?.address?.dist,
            state: result?.result?.data?.address?.state,
            pincode: result?.result?.data?.zip,
            domicile: result?.result?.data?.address?.country,
            gender:
              result?.result?.data?.gender?.toUpperCase() === "M"
                ? "Male"
                : result?.result?.data?.gender?.toUpperCase() === "F"
                ? "Female"
                : "Other",
          });
          formik?.setFieldValue("birthDate", result?.result?.data?.dob);
          formik?.setFieldValue(
            "address",
            `${result?.result?.data?.address?.house} ${result?.result?.data?.address?.street}`
          );
          formik?.setFieldValue(
            "villageOrTown",
            result?.result?.data?.address?.vtc
          );
          formik?.setFieldValue(
            "taluka",
            result?.result?.data?.address?.subdist
          );
          formik?.setFieldValue(
            "district",
            result?.result?.data?.address?.dist
          );
          formik?.setFieldValue("state", result?.result?.data?.address?.state);
          formik?.setFieldValue("pincode", result?.result?.data?.zip);
          formik?.setFieldValue(
            "domicile",
            result?.result?.data?.address?.country
          );
          formik?.setFieldValue(
            "gender",
            result?.result?.data?.gender?.toUpperCase() === "M"
              ? "Male"
              : result?.result?.data?.gender?.toUpperCase() === "F"
              ? "Female"
              : "Other"
          );
          setAddressFlag(
            !!result?.result?.data?.address?.house ||
              !!result?.result?.data?.address?.street
          );
          setVillageOrTownFlag(!!result?.result?.data?.address?.vtc);
          setTalukaFlag(!!result?.result?.data?.address?.subdist);
        } else {
          toast.error(result?.result?.message);
          setAadharSumbitOtpLoader(false);
        }
      }
    } catch (err: any) {
      setAadharSumbitOtpLoader(false);
      console.log("Aadhar Sumbit Otp :", err);
      toast.error("Invalid OTP");
    }
  };
  console.log("agentData.panCard :", agentData.panCard);

  const handlePanVerification = async () => {
    try {
      if (agentData.panCard) {
        setPanVerifyLoader(true);
        const result = await verifyPan("panAllInOne", agentData.panCard);
        console.log("send otp", result);
        if (result?.code === 200) {
          toast.success("Verified Successully");
          setPanVerifyLoader(false);
          dispatch(setPanVerifiedData(true));
          setAgentData({ ...agentData, panVerifyStatus: "verified" });
        } else {
          setPanVerifyLoader(false);
          toast.error(result?.message);
          setAgentData({ ...agentData, panVerifyStatus: "" });
        }
      }
    } catch (err: any) {
      // setError(err.message);
      setPanVerifyLoader(false);
      console.log("handleVerifyPan :", err);
      toast.error("Something went wrong.");
    }
  };

  return (
    <section
      style={{
        backgroundImage: `url(${Banner})`,
      }}
      className="h-full w-full bg-no-repeat bg-cover min-h-screen"
    >
      <form action="" onSubmit={formik.handleSubmit}>
        <div className="md:py-24 h-full md:h-dvh flex md:items-center justify-center registerSection">
          <div className="max-w-[1160px] w-full">
            <div className="tab-contentWrapper">
              <ul className="tab-header flex !justify-start mb-6 gap-8">
                <li className="tab-itemHome">
                  <img src={Logo} alt="logo" className="pr-6 w-40" />
                </li>
                <li>
                  <div className="flex items-center gap-1 w-20 h-8">
                    <Link
                      to="/"
                      onClick={() => {
                        dispatch(setIsAgentAadharVerified(false));
                        dispatch(setPanVerifiedData(false));
                      }}
                      className="hover:border-b-[1px] text-xl hover:text-2xl hover:border-b-green-900 hover:border-solid hover:pb-1 flex items-center flex-col transition-all duration-300 ease-in-out"
                    >
                      <FaHome className="fill-green-700  hover:fill-green-900  " />
                      <p className="text-xs text-slate-400">Home Page</p>
                    </Link>
                  </div>
                </li>
                <li className="w-full">
                  <h4 className="text-lg text-[#00a068] font-semibold capitalize text-center underline underline-offset-2 mb-2">
                    Agent Sign Up
                  </h4>
                </li>
              </ul>

              <div className="tab-content">
                <div className="space-y-4 min-h-[545px]">
                  <form onSubmit={formik.handleSubmit}>
                    {/* Repeat similar blocks for all other inputs */}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                      <div>
                        <label className="block text-black font-medium mb-1 text-sm">
                          First Name<span className="text-red-500">*</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstName || agentData.firstName}
                          name="firstName"
                          type="text"
                          className="w-full p-2 border border-gray-300 rounded-none"
                          placeholder="First Name"
                        />
                        <p className="h-2">{getErrorMessage("firstName")} </p>
                      </div>

                      <div>
                        <label className="block text-black font-medium mb-1 text-[.875rem]">
                          Last Name<span className="text-red-500">*</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lastName || agentData.lastName}
                          name="lastName"
                          type="text"
                          className="w-full p-2 border border-gray-300 rounded-none"
                          placeholder="Last Name"
                        />
                        <p className="h-2">{getErrorMessage("lastName")} </p>
                      </div>

                      <div>
                        <label className="block text-black font-medium mb-1 text-[.875rem]">
                          Aadhar Card<span className="text-red-500">*</span>
                        </label>
                        <div className="flex items-center relative border-gray-300 verifyBtnBox">
                          <input
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.aadharCard}
                            name="aadharCard"
                            type="number"
                            maxLength={12}
                            className="w-full p-2 rounded-none"
                            placeholder="Aadhar Card"
                          />
                          {isAgentAadharVerified ? (
                            <FiCheckCircle className="text-green-600 text-[1.2rem] mx-[6px] my-[6px] absolute right-0" />
                          ) : (
                            <>
                              {AadharVerifyLoader ? (
                                <span className="absolute right-2">
                                  <Loader />
                                </span>
                              ) : (
                                <button
                                  type="button"
                                  disabled={agentData?.aadharCard?.length <= 11}
                                  onClick={handleConfirmationForResendOtp}
                                  className="bg-[#17a44f] text-white px-4 py-2 "
                                >
                                  Verify
                                </button>
                              )}
                            </>
                          )}
                        </div>
                        <p className="h-2">{getErrorMessage("aadharCard")} </p>
                      </div>

                      <div>
                        <label className="block text-black font-medium mb-1 text-[.875rem]">
                          Pan Card<span className="text-red-500">*</span>
                        </label>
                        <div className="flex items-center relative border-gray-300">
                          <input
                            onChange={handleChange}
                            onBlur={formik.handleBlur}
                            value={
                              formik.values.panCard.trim() ||
                              agentData.panCard.trim()
                            }
                            name="panCard"
                            type="text"
                            className="w-full p-2 rounded-none"
                            placeholder="Pan Card"
                          />
                          {isAgentPanVerified ? (
                            <FiCheckCircle className="text-green-600 text-[1.2rem] mx-[6px] my-[6px] absolute right-0" />
                          ) : (
                            <>
                              {panVerifyLoader ? (
                                <span className="absolute right-2">
                                  <Loader />
                                </span>
                              ) : (
                                <button
                                  type="button"
                                  disabled={agentData?.panCard?.length <= 9}
                                  className="bg-[#17a44f] text-white px-4 py-2"
                                  onClick={handlePanVerification}
                                >
                                  Verify
                                </button>
                              )}
                            </>
                          )}
                        </div>
                        <p className="h-2">{getErrorMessage("panCard")} </p>
                      </div>

                      <div>
                        <label className="block text-black font-medium mb-1 text-[.875rem]">
                          Mobile Number<span className="text-red-500">*</span>
                        </label>
                        <div className="flex  border-gray-300">
                          <span className="bg-gray-300 text-black px-1.5 flex items-center">
                            +91
                          </span>
                          <input
                            onChange={handleChange}
                            onBlur={formik.handleBlur}
                            value={
                              formik.values.mobileNumber ||
                              agentData.mobileNumber
                            }
                            name="mobileNumber"
                            type="number"
                            className="w-full p-2 rounded-none cursor-pointer "
                            placeholder="Mobile Number"
                            // disabled
                          />
                          {/* <button className="bg-[#17a44f] text-white px-4">
                      Verify
                    </button> */}
                        </div>
                        <p className="h-2">
                          {getErrorMessage("mobileNumber")}{" "}
                        </p>
                      </div>

                      <div>
                        <label className="block text-black font-medium mb-1 text-[.875rem]">
                          Whatsapp Number<span className="text-red-500">*</span>
                        </label>
                        <div className="flex  border-gray-300">
                          <span className="bg-gray-300 text-black px-1.5 flex items-center">
                            +91
                          </span>
                          <input
                            onChange={handleChange}
                            onBlur={formik.handleBlur}
                            value={
                              formik.values.whatsappNumber ||
                              agentData.whatsappNumber
                            }
                            name="whatsappNumber"
                            type="number"
                            className="w-full p-2 rounded-none  "
                            placeholder=" Whatsapp Number"
                          />
                        </div>
                        <p className="h-2">{getErrorMessage("birthDate")} </p>
                      </div>
                      <div>
                        <label className="block text-black font-medium mb-1 text-[.875rem]">
                          Date of Birth<span className="text-red-500">*</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled
                          value={formik.values.birthDate || agentData.birthDate}
                          name="birthDate"
                          type="date"
                          className="w-full outline-none rounded-none border border-gray-300 p-2 bg-[#f3f3f3]"
                          placeholder="YYYY-MM-DD"
                          pattern="\d{4}-\d{2}-\d{2}" // Optional, for additional validation
                        />
                        <p className="h-2">{getErrorMessage("birthDate")} </p>
                      </div>
                      <div>
                        <label className="block text-black font-medium mb-1 text-[.875rem]">
                          Address<span className="text-red-500">*</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={addressFlag}
                          value={formik.values.address || agentData.address}
                          name="address"
                          type="text"
                          className={`w-full p-2 border border-gray-300 rounded-none ${
                            addressFlag && "bg-[#f3f3f3]"
                          }`}
                          placeholder="Address"
                        />
                        <p className="h-2">{getErrorMessage("address")} </p>
                      </div>

                      <div>
                        <label className="block text-black font-medium mb-1 text-[.875rem]">
                          Village/Town<span className="text-red-500">*</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={villageOrTownFlag}
                          value={
                            formik.values.villageOrTown ||
                            agentData.villageOrTown
                          }
                          name="villageOrTown"
                          type="text"
                          className={`w-full p-2 border border-gray-300 rounded-none ${
                            villageOrTownFlag && "bg-[#f3f3f3]"
                          }`}
                          placeholder="Village/Town"
                        />
                        <p className="h-2">
                          {getErrorMessage("villageOrTown")}{" "}
                        </p>
                      </div>
                      <div>
                        <label className="block text-black font-medium mb-1 text-[.875rem]">
                          Taluka<span className="text-red-500">*</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={talukaFlag}
                          value={formik.values.taluka || agentData.taluka}
                          name="taluka"
                          type="text"
                          className={`w-full p-2 border border-gray-300 rounded-none ${
                            talukaFlag && "bg-[#f3f3f3]"
                          }`}
                          placeholder="Taluka"
                        />
                        <p className="h-2">{getErrorMessage("taluka")} </p>
                      </div>

                      <div>
                        <label className="block text-black font-medium mb-1 text-[.875rem] ">
                          District<span className="text-red-500">*</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled
                          value={formik.values.district || agentData.district}
                          name="district"
                          type="text"
                          className="w-full p-2 border border-gray-300 rounded-none bg-[#f3f3f3]"
                          placeholder="District"
                        />
                        <p className="h-2">{getErrorMessage("district")} </p>
                      </div>

                      <div>
                        <label className="block text-black font-medium mb-1 text-[.875rem]">
                          State<span className="text-red-500">*</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled
                          value={formik.values.state || agentData.state}
                          name="state"
                          type="text"
                          className="w-full p-2 border border-gray-300 rounded-none bg-[#f3f3f3]"
                          placeholder="State"
                        />
                        <p className="h-2">{getErrorMessage("state")} </p>
                      </div>

                      <div>
                        <label className="block text-black font-medium mb-1 text-[.875rem]">
                          Pin Code<span className="text-red-500">*</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled
                          value={formik.values.pincode || agentData.pincode}
                          name="pincode"
                          type="number"
                          className="w-full p-2 border border-gray-300 rounded-none bg-[#f3f3f3]"
                          placeholder="Pin Code"
                        />
                        <p className="h-2">{getErrorMessage("pincode")} </p>
                      </div>
                      <div>
                        <label className="block text-black font-medium mb-1 text-[.875rem]">
                          Domicile<span className="text-red-500">*</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled
                          value={formik.values.domicile || agentData.domicile}
                          name="domicile"
                          type="text"
                          className="w-full p-2 border border-gray-300 rounded-none bg-[#f3f3f3]"
                          placeholder="Domicile"
                        />
                        <p className="h-2">{getErrorMessage("domicile")} </p>
                      </div>

                      <div className="grid grid-cols-2 gap-4">
                        {/* Gender Dropdown */}
                        <div>
                          <label className="block text-black font-medium mb-1 text-[.875rem]">
                            Gender<span className="text-red-500">*</span>
                          </label>
                          <select
                            disabled
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.gender || agentData.gender}
                            name="gender"
                            className="w-full p-2 border border-gray-300 rounded-none bg-[#f3f3f3]"
                          >
                            <option value="">Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                          </select>
                          <p className="h-2">{getErrorMessage("gender")} </p>
                        </div>

                        {/* Marital Status Dropdown */}
                        <div>
                          <label className="block text-black font-medium mb-1 text-[.875rem]">
                            Marital Status
                            <span className="text-red-500">*</span>
                          </label>
                          <select
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={
                              formik.values.maritalStatus ||
                              agentData?.maritalStatus
                            }
                            name="maritalStatus"
                            className="w-full p-2 border border-gray-300 rounded-none"
                          >
                            <option value="">Select</option>
                            <option value="Single">Single</option>
                            <option value="Married">Married</option>
                            <option value="Divorced">Divorced</option>
                            <option value="Widowed">Widowed</option>
                          </select>
                          <p className="h-2">
                            {getErrorMessage("maritalStatus")}{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <label className="block text-black font-medium mb-1 text-[.875rem]">
                          Email ID
                          {/* <span className="text-red-500">*</span> */}
                        </label>
                        <input
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email || agentData.email}
                          name="email"
                          type="email"
                          className="w-full p-2 border border-gray-300 rounded-none"
                          placeholder="Email ID"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="flex items-center justify-between mt-1 relative">
                  <div className="absolute top-1/2 -translate-y-1/2 left-0">
                    <Link
                      to="/login"
                      className="text-[#3c8f47] font-semibold text-center block hover:text-green-900 transition-all duration-300 ease-in-out text-sm "
                      onClick={() => {
                        dispatch(setIsAgentAadharVerified(false));
                        dispatch(setPanVerifiedData(false));
                      }}
                    >
                      Back to Login
                    </Link>
                  </div>

                  <div className="button-container">
                    <button type="submit" className="submitBtn">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              <CustomModal
                isOpenCustModal={isModalOpen}
                onClose={closeModal}
                title="Success!"
                modalName="registration"
              >
                <h3>Success!!</h3>
                <p>
                  Your UserId is Pan Card Number and Password is Mobile Number.
                  Please Login to continue.
                </p>
              </CustomModal>
            </div>
          </div>
          <ToastContainer autoClose={2000} />
        </div>
      </form>
      <CustomModal
        isOpenCustModal={AadharOtpModal}
        onClose={closeAadharOtpModal}
        modalName="aadharVerificationModal"
      >
        <OTPVerificationInput
          setAadhaOtp={setAadhaOtp}
          handleSubmitAadharOtp={handleSubmitAadharOtp}
          handleVerify={handleConfirmationForResendOtp}
          onClose={closeAadharOtpModal}
          isLoading={aadharSumbitOtpLoader}
        />
      </CustomModal>
    </section>
  );
};

export default AgentSignUp;
