import { useEffect, useState } from 'react'
import { FaBars, FaTimes, FaUserCircle } from 'react-icons/fa'
import Sidebar from './Sidebar'
import styles from '../styles/globalstyles/Header.module.css'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import LogoutPopup from './LogoutPopUp'
import { useDispatch, useSelector } from 'react-redux'
import { logoutAdmin } from '../redux/authSlice'
import {
	getUserFromLocalStorage,
	isUserLoggedIn,
	removeUserFromLocalStorage,
} from '../utils/localStorageUtil'
import { AppDispatch, RootState } from '../utils/store'
import { getAllLoanDetails } from '../redux/user/LoanDetailsSlice'
import logo from '../assets/logoNew.png'

function Header({ name }: any) {
	const dispatch = useDispatch<AppDispatch>()
	const { data } = useSelector((state: RootState) => state.loan)
	const [logout, setLogout] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const navigate = useNavigate()
	const [isMenuOpen, setIsMenuOpen] = useState({
		notifications: false,
		user: false,
	})
	const roleType = getUserFromLocalStorage()?.role

	const location = useLocation()
	const isDashboardPage = location.pathname === '/'

	const toggleSidebar = () => {
		setIsOpen(!isOpen)
	}

	const toggleDropdown = (menu: 'notifications' | 'user') => {
		if (menu === 'user') {
			setIsMenuOpen((prevState) => ({
				...prevState,
				[menu]: !prevState[menu],
			}))
		} else {
			navigate('/notifications')
		}
	}

	const callLogin = () => {
		navigate('/login')
	}

	function logoutUser() {
		setLogout(true)
	}

	function onConfirm() {
		removeUserFromLocalStorage()
		dispatch(logoutAdmin())
		setLogout(false)
		navigate('/login')
	}

	function onCancel() {
		setLogout(false)
	}

	function profileUser() {
		roleType === 'AGENT'
			? navigate('/agent-profile')
			: navigate('/user-profile')
	}

	useEffect(() => {
		const borrowerId = getUserFromLocalStorage()?.borrowerId
		if (borrowerId && !data?.LoanDetails?.fullName) {
			dispatch(getAllLoanDetails({ borrowerId }))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} title={name} />
			<div
				className={`${styles.main_content} ${
					isOpen || isDashboardPage ? 'full-width' : ''
				}`}
			>
				<div className={styles.navbar}>
					<div className="flex items-center justify-between gap-6">
						<Link to="/" className="!border-r-0">
							<img src={logo} alt="Logo" className="w-[50px]" />
						</Link>
						<div className={styles.menu_icon} onClick={toggleSidebar}>
							{isOpen ? <FaTimes /> : <FaBars />}{' '}
						</div>
					</div>
					<div className="flex items-center">
						<h3 className={styles.navbar_title}>{name}</h3>
					</div>
					{isUserLoggedIn() ? (
						<div className={`gap-4 ${styles.header_icons}`}>
							{roleType === 'BORROWER' && (
								<>
									<div className="flex items-center gap-3 w-full">
										<label className="mb-0">Loan Account No:</label>
										<span className="text-sm font-semibold tracking-wider text-yellow-400">
											{data?.LoanDetails?.loanID}
										</span>
									</div>
								</>
							)}
							<div
								className={styles.icon_circle}
								onClick={() => toggleDropdown('user')}
							>
								<FaUserCircle />
								{isMenuOpen.user && (
									<div className={styles.dropdown_menu}>
										{roleType !== 'ADMIN' && (
											<h5 onClick={profileUser}>Profile</h5>
										)}
										<div
											style={{
												width: '100%',
												height: 1,
												backgroundColor: 'white',
											}}
										></div>
										<h5 onClick={logoutUser}>Logout</h5>
									</div>
								)}
							</div>
						</div>
					) : (
						<button
							type="submit"
							className={styles.buttons}
							onClick={callLogin}
						>
							Login
						</button>
					)}
				</div>
				{logout ? (
					<LogoutPopup
						onConfirm={onConfirm}
						onCancel={onCancel}
						text={'Log Out'}
					/>
				) : null}
			</div>
		</>
	)
}

export default Header
