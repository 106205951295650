import { ContainerProps } from "../../utils/types";

const Container: React.FC<ContainerProps> = ({
  className = "",
  children,
  ...props
}) => {
  return (
    <div
      className={`max-w-7xl mx-auto px-4 cust-container ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

export default Container;
