import * as Yup from "yup";
import * as yup from "yup";

type OccupationType =
  | "Govt_Employee"
  | "Pvt_Employee"
  | "PSU_Employee"
  | "Bank_Employee"
  | "Doctor"
  | "CA"
  | "Lawyer"
  | "Freelancer"
  | "Business";

const getValidationSchema = (occupationType: OccupationType) => {
  const commonSchema = {
    yearsInCurrentDesignation: yup
      .number()
      .min(0, "Years must be positive")
      .required("Required"),
    occupationAddress: yup.string().required("Required"),
    offCity: yup.string().required("Required"),
    offPincode: yup
      .string()
      .matches(/^\d{6}$/, "Pincode must be 6 digits")
      .required("Required"),
    offStateOrCentral: yup.string().required("Required"),
    officePhoneNumber: yup
      .string()
      .matches(/^\d{10}$/, "Office phone number must be 10 digits")
      .required("Required"),
    annualIncome: yup
      .string()
      .min(0, "Annual Income must be positive")
      .required("Required"),
    lastMonthTakeHomeSalary: yup
      .string()
      .min(0, "Take-home salary must be positive")
      .required("Required"),
  };

  const DoctorLowyerCaValidationSchema = {
    registeredNumber: Yup.string().required("Required"),
    specialisation: Yup.string().required("Required"),
    previousYearITReturn: Yup.string().required("Required"),
    amountOfTaxPaid: Yup.number()
      .typeError("Amount of Tax Paid must be a number")
      .positive("Amount of Tax Paid must be positive")
      .required("Required"),
    bankAccountNumber: Yup.string()
      .matches(/^\d+$/, "Bank Account Number must contain only digits")
      .required("Required"),
    bankIfsccode: Yup.string()
      .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC code format")
      .required("Required"),
    occupationAddress: Yup.string().required("Required"),
    offCity: Yup.string().required("Required"),
    offStateOrCentral: Yup.string().required("Required"),
    offPincode: Yup.string()
      .matches(/^\d{6}$/, "Pincode must be exactly 6 digits")
      .required("Required"),
    yearsInPractise: Yup.number()
      .typeError("Years in Practice must be a number")
      .positive("Years in Practice must be positive")
      .integer("Years in Practice must be an integer")
      .required("Required"),
  };

  const occupationSchemas: Record<OccupationType, yup.ObjectSchema<any>> = {
    Govt_Employee: yup.object().shape({
      ...commonSchema,
      nameOfOrganisation: yup.string().required("Required"),
      designation: yup.string().required("Required"),
      department: yup.string().required("Required"),
    }),
    Pvt_Employee: yup.object().shape({
      ...commonSchema,
      nameOfCompany: yup.string().required("Required"),
      designation: yup.string().required("Required"),
      department: yup.string().required("Required"),
    }),
    PSU_Employee: yup.object().shape({
      ...commonSchema,
      nameOfOrganisation: yup.string().required("Required"),
      designation: yup.string().required("Required"),
      department: yup.string().required("Required"),
    }),
    Bank_Employee: yup.object().shape({
      ...commonSchema,
      nameOfBank: yup.string().required("Required"),
      designation: yup.string().required("Required"),
      department: yup.string().required("Required"),
    }),
    Doctor: yup.object().shape({
      ...DoctorLowyerCaValidationSchema,
    }),
    CA: yup.object().shape({
      ...DoctorLowyerCaValidationSchema,
    }),
    Lawyer: yup.object().shape({
      ...DoctorLowyerCaValidationSchema,
    }),
    Freelancer: Yup.object().shape({
      registeredNumber: Yup.string().required("Required"),
      natureOfWork: Yup.string().required("Required"),
      yearsOfExperience: Yup.number()
        .typeError("Years of Experience must be a number")
        .positive("Years of Experience must be positive")
        .integer("Years of Experience must be an integer")
        .required("Required"),
      other: Yup.string().nullable(), // Optional field
    }),
    Business: Yup.object().shape({
      gstNumber: Yup.string()
        .matches(/^\d{15}$/, "GST Number must be exactly 15 characters")
        .required("Required"),
      businessName: Yup.string().required("Required"),
      occupationAddress: Yup.string().required("Required"),
      offCity: Yup.string().required("Required"),
      offStateOrCentral: Yup.string().required("Required"),
      offPincode: Yup.string()
        .matches(/^\d{6}$/, "Pincode must be exactly 6 digits")
        .required("Required"),
      typeOfBusiness: Yup.string().required("Required"),
      natureOfBusiness: Yup.string().required("Required"),
      amountOfTaxPaid: Yup.number()
        .typeError("Amount of Tax Paid must be a number")
        .positive("Amount of Tax Paid must be positive")
        .required("Required"),
      previousYearITReturn: Yup.number()
        .typeError("Previous Year IT Return must be a number")
        .positive("Previous Year IT Return must be positive")
        .required("Required"),
    }),
  };

  return yup.object().shape({
    Occupation: yup.object().shape({
      type: yup.string().required("Required"),
      subType: yup.string().required("Required"),
    }),

    [occupationType]: occupationSchemas[occupationType] || yup.object(),
  });
};

export default getValidationSchema;

const occupationInitialValue = {
  Occupation: {
    type: "",
    subType: "",
  },
  Govt_Employee: {
    type: "",
    subType: "",
    nameOfOrganisation: "",
    designation: "",
    department: "",
    yearsInCurrentDesignation: "",
    occupationAddress: "",
    offCity: "",
    offPincode: "",
    offStateOrCentral: "",
    officePhoneNumber: "",
    annualIncome: null,
    lastMonthTakeHomeSalary: null,
  },
  Pvt_Employee: {
    type: "",
    subType: "",
    nameOfCompany: "",
    designation: "",
    department: "",
    yearsInCurrentDesignation: "",
    occupationAddress: "",
    offCity: "",
    offPincode: "",
    offStateOrCentral: "",
    officePhoneNumber: "",
    annualIncome: null,
    lastMonthTakeHomeSalary: null,
  },
  PSU_Employee: {
    type: "",
    subType: "",
    nameOfOrganisation: "",
    designation: "",
    department: "",
    yearsInCurrentDesignation: "",
    occupationAddress: "",
    offCity: "",
    offPincode: "",
    offStateOrCentral: "",
    officePhoneNumber: "",
    annualIncome: null,
    lastMonthTakeHomeSalary: null,
  },
  Bank_Employee: {
    type: "",
    subType: "",
    nameOfBank: "",
    designation: "",
    department: "",
    yearsInCurrentDesignation: "",
    occupationAddress: "",
    offCity: "",
    offPincode: "",
    offStateOrCentral: "",
    officePhoneNumber: "",
    annualIncome: null,
    lastMonthTakeHomeSalary: null,
  },
  Freelancer: {
    type: "",
    subType: "",
    registeredNumber: "",
    natureOfWork: "",
    yearsOfExperience: "",
    other: "",
  },
  Doctor: {
    type: "",
    subType: "",
    registeredNumber: "",
    specialisation: "",
    previousYearITReturn: "",
    amountOfTaxPaid: "",
    bankAccountNumber: "",
    bankIfsccode: "",
    occupationAddress: "",
    offCity: "",
    offStateOrCentral: "",
    offPincode: "",
    yearsInPractise: "",
  },
  Business: {
    type: "",
    subType: "",
    gstNumber: "",
    businessName: "",
    occupationAddress: "",
    offCity: "",
    offStateOrCentral: "",
    offPincode: "",
    typeOfBusiness: "",
    natureOfBusiness: "",
    amountOfTaxPaid: "",
    previousYearITReturn: "",
  },
  CA: {
    type: "",
    subType: "",
    registeredNumber: "",
    specialisation: "",
    previousYearITReturn: "",
    yearsInPractise: "",
    amountOfTaxPaid: "",
    bankAccountNumber: "",
    bankIfsccode: "",
    occupationAddress: "",
    offCity: "",
    offStateOrCentral: "",
    offPincode: "",
  },
  Lawyer: {
    type: "",
    subType: "",
    registeredNumber: "",
    specialisation: "",
    previousYearITReturn: "",
    yearsInPractise: "",
    amountOfTaxPaid: "",
    bankAccountNumber: "",
    bankIfsccode: "",
    occupationAddress: "",
    offCity: "",
    offStateOrCentral: "",
    offPincode: "",
  },
};
const demoData = {
  personalInfo: {
    fullName: "",
    aadhaarNumber: "",
    panCardNumber: "",
    mobileNumber: "",
    email: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    password: "",
    confirmPassword: "",
    gender: "",
    maritalStatus: "",
    loanAmount: "",
    birthDate: "",
    tenure: "",
    loanTypeId: "",
  },
};

export {
  // occupationFormsValidationSchema,
  getValidationSchema,
  occupationInitialValue,
};
