import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../redux/authSlice'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import Banner from '../../assets/Ashapurti-Gold-Loans_Form_Updated_BG.jpg'
import LOGO from '../../assets/Logo_circle.png'
import { AppDispatch, RootState } from '../../utils/store'
import { saveUserToLocalStorage } from '../../utils/localStorageUtil'
import Loader from '../../utils/Loader'
import { FaHome } from 'react-icons/fa'

export default function LoginScreen() {
	const [email, setEmail] = useState<string>('')
	const [password, setPassword] = useState<string>('')
	const [errorMessageEmail, setErrorMessageEmail] = useState<string>('')
	const [errorMessagePassword, setErrorMessagePassword] = useState<string>('')
	const [showPassword, setShowPassword] = useState<boolean>(false)

	const navigate = useNavigate()
	const dispatch = useDispatch<AppDispatch>()

	const { loading, data } = useSelector((state: RootState) => state.auth)

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault()
		if (!email) {
			setErrorMessageEmail('Email are required.')
			return
		} else if (!password) {
			setErrorMessagePassword('Password are required.')
			return
		}
		setErrorMessageEmail('')
		setErrorMessagePassword('')
		callLogin()
	}

	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value)
		if (e.target.value) {
			setErrorMessageEmail('')
		}
	}

	const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(e.target.value)
		if (e.target.value) {
			setErrorMessagePassword('')
		}
	}

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword)
	}

	function callForgetPassword() {
		navigate('/forgot-password')
	}

	function callLogin() {
		dispatch(
			login({
				email,
				password,
			})
		)
	}

	useEffect(() => {
		console.log('Status', data)
		if (data?.status === 'Success') {
			if (data?.role === 'SUPER_ADMIN') {
				navigate('/HomeScreen')
				const user = {
					token: data?.token,
					borrowerId: data?.userId,
				}
				saveUserToLocalStorage(user)
			} else if (data?.role === 'BORROWER') {
				navigate('/HomeScreen')
				const user = {
					token: data?.token,
					borrowerId: data?.userId,
					role: data?.role,
				}
				saveUserToLocalStorage(user)
			} else if (data?.role === 'AGENT') {
				navigate('/agent-dashboard')
				const user = {
					token: data?.token,
					borrowerId: data?.userId,
					role: data?.role,
				}
				saveUserToLocalStorage(user)
			} else if (data?.role === 'ADMIN') {
				navigate('/agent-dashboard')
				const user = {
					token: data?.token,
					borrowerId: data?.userId,
					role: data?.role,
				}
				saveUserToLocalStorage(user)
			}
		} else if (data?.status === 'Error') {
			setErrorMessageEmail(data.message || 'Login failed.')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, navigate])

	return (
		<section
			style={{
				backgroundImage: `url(${Banner})`,
			}}
			className="w-full bg-no-repeat bg-cover py-16 sm:py-24 h-screen flex items-center justify-center px-4 loginScreen"
		>
			<div className="flex flex-col justify-center items-center space-y-8 w-full max-w-md sm:max-w-lg">
				<div className="w-[80%] bg-[#f1f5f9] p-4 sm:p-6 rounded-lg shadow-lg relative">
					<div className="flex items-center gap-1 h-8">
						<Link
							to="/"
							className="hover:border-b-[1px] text-xl hover:text-2xl hover:border-b-green-900 hover:border-solid hover:pb-1 flex items-center flex-col transition-all duration-300 ease-in-out"
						>
							<FaHome className="fill-green-700  hover:fill-green-900  " />
							<p className="text-xs text-slate-400">Home Page</p>
						</Link>
					</div>

					<div className="text-center mb-8 w-auto flex items-center justify-center">
						<img src={LOGO} alt="Logo" className="w-32" />
					</div>

					<h1 className="text-[#3c8f47] font-semibold text-center text-xl sm:text-2xl capitalize">
						Welcome
					</h1>
					<form className="w-full" onSubmit={handleSubmit}>
						<span className="error"> {data?.message} </span>
						<div className="mb-4">
							{errorMessageEmail && (
								<div className="text-red-500 text-xs mb-2">
									{errorMessageEmail}
								</div>
							)}
							<input
								placeholder="Username"
								type="text"
								id="email"
								value={email}
								onChange={handleEmailChange}
								className="w-full p-3 rounded-md border border-gray-300  focus:outline-none"
							/>
						</div>
						<div className="relative">
							{errorMessagePassword && (
								<div className="text-red-500 text-sm mb-2">
									{errorMessagePassword}
								</div>
							)}
							<div className="relative">
								<input
									placeholder="Password"
									type={showPassword ? 'text' : 'password'}
									id="password"
									value={password}
									onChange={handlePasswordChange}
									className="w-full p-3 rounded-md border border-gray-300 focus:outline-none"
								/>
								<button
									type="button"
									onClick={togglePasswordVisibility}
									className="absolute right-3  top-[calc(50%)] transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
								>
									{showPassword ? <FaEye /> : <FaEyeSlash />}
								</button>
							</div>
						</div>
						<div className="flex items-center justify-between mb-4">
							<div className="flex items-center">
								<input
									type="checkbox"
									id="rememberMe"
									name="rememberMe"
									className="w-4 h-4 "
								/>
								<label
									htmlFor="rememberMe"
									className="ml-2 mt-2 md:text-sm sm:text-sm text-[10px] text-gray-700 cursor-pointer"
								>
									Remember Me
								</label>
							</div>
							<div
								className="text-green-700 md:text-sm sm:text-sm text-[10px] cursor-pointer hover:underline"
								onClick={callForgetPassword}
							>
								Forgot Password?
							</div>
						</div>

						<div className="flex justify-center gap-4 w-full mb-4">
							{!loading ? (
								<button
									type="submit"
									className="w-60 py-2 bg-[#3c8f47] text-white rounded-lg font-medium hover:bg-green-700 transition shadow-shadow_1 custButton"
								>
									Login
								</button>
							) : (
								<button
									type="submit"
									className="w-60 py-2 bg-[#3c8f47] text-white rounded-lg font-medium hover:bg-green-700 transition"
								>
									<Loader />
								</button>
							)}
						</div>
					</form>
				</div>
			</div>
		</section>
	)
}
