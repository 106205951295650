import logo from '../assets/Logo_circle.png'
import { ReactComponent as DashboardIcon } from '../assets/dashboard-icons/dashboard.svg'
import { ReactComponent as FranchiseProgram } from '../assets/dashboard-icons/franchise-program.svg'
import styles from '../styles/globalstyles/Sidebar.module.css'
import { Link } from 'react-router-dom'
import { getUserFromLocalStorage } from '../utils/localStorageUtil'

export default function Sidebar({ isOpen, toggleSidebar }: any) {
	const roleType = getUserFromLocalStorage()?.role
	console.log(roleType)
	return (
		<div className={`${styles.sidebar} ${isOpen ? styles.sidebar_open : ''}`}>
			<div className={styles.sidebar_header}>
				<img src={logo} alt="Logo" className={styles.logo} />
				<span className={styles.back_arrow} onClick={toggleSidebar}>
					&larr;
				</span>
			</div>
			<ul className={styles.sidebar_menu}>
				{roleType === 'AGENT' || roleType === 'ADMIN' ? (
					<>
						<li className={styles.sidebar_item}>
							<Link to="/agent-dashboard">
								<FranchiseProgram className={styles.icons} />
								<span>Dashboard</span>
							</Link>
						</li>
						<li className={styles.sidebar_item}>
							<Link to="/borrowerList">
								<FranchiseProgram className={styles.icons} />
								<span>Borrower List</span>
							</Link>
						</li>
						<li className={styles.sidebar_item}>
							<Link to="/pendingBorrowers">
								<FranchiseProgram className={styles.icons} />
								<span>Pending Borrowers</span>
							</Link>
						</li>
						<li className={styles.sidebar_item}>
							<Link to="/approvedBorrowers">
								<FranchiseProgram className={styles.icons} />
								<span>Approved Borrowers</span>
							</Link>
						</li>
						{roleType === 'ADMIN' && (
							<>
								<li className={styles.sidebar_item}>
									<Link to="/interestRate">
										<FranchiseProgram className={styles.icons} />
										<span>Interest Rates</span>
									</Link>
								</li>
								<li className={styles.sidebar_item}>
									<Link to="/agentList">
										<FranchiseProgram className={styles.icons} />
										<span>Agent List</span>
									</Link>
								</li>
							</>
						)}
					</>
				) : (
					<>
						<li className={styles.sidebar_item}>
							<Link to="/HomeScreen">
								<DashboardIcon className={styles.icons} />
								<span>Dashboard</span>
							</Link>
						</li>
						<li className={styles.sidebar_item}>
							<Link to="/repayment">
								<FranchiseProgram className={styles.icons} />
								<span>Repayment</span>
							</Link>
						</li>
					</>
				)}
			</ul>
		</div>
	)
}
