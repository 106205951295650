import { DataObject } from "../../../utils/types";
const handleOccupationPayloadFormate = (
  empType: string,
  empSubType: string,
  data: any
) => {
  let payload = {};
  if (empType === "Self-Employed") {
    if (
      empSubType === "Doctor" ||
      empSubType === "CA" ||
      empSubType === "Lawyer"
    ) {
      const occupation = {
        type: empType,
        subType: empSubType,
        registeredNumber: data?.registeredNumber,
        specialisation: data?.specialisation,
        yearsInPractise: data?.yearsInPractise,
        previousYearITReturn: data?.previousYearITReturn,
        amountOfTaxPaid: Number(data?.amountOfTaxPaid),
        bankAccountNumber: data?.bankAccountNumber,
        bankIfsccode: data?.bankIfsccode,
        occupationAddress: data?.occupationAddress,
        offCity: data?.offCity,
        offPincode: data?.offPincode,
        offStateOrCentral: data?.offStateOrCentral,
      };
      payload = occupation;
    } else if (empSubType === "Freelancer") {
      const occupation = {
        type: empType,
        subType: empSubType,
        registeredNumber: data?.registeredNumber,
        natureOfWork: data?.natureOfWork,
        yearsOfExperience: data?.yearsOfExperience,
        other: data?.other,
      };
      payload = occupation;
    } else if (empSubType === "Business") {
      const occupation = {
        type: empType,
        subType: empSubType,
        gstNumber: data?.gstNumber,
        businessName: data?.businessName,
        occupationAddress: data?.occupationAddress,
        offCity: data?.offCity,
        offPincode: data?.offPincode,
        offStateOrCentral: data?.offStateOrCentral,
        typeOfBusiness: data?.typeOfBusiness,
        natureOfBusiness: data?.natureOfBusiness,
        amountOfTaxPaid: Number(data?.amountOfTaxPaid),
        previousYearITReturn: data?.previousYearITReturn,
      };
      payload = occupation;
    }
  }
  if (empType === "Employed") {
    if (empSubType === "Govt. Employee" || empSubType === "PSU Employee") {
      const occupation = {
        type: empType,
        subType: empSubType,
        nameOfOrganisation: data?.nameOfOrganisation,
        designation: data?.designation,
        department: data?.department,
        // stateOrCentral: data?.stateOrCentral,
        yearsInCurrentDesignation: data?.yearsInCurrentDesignation,
        occupationAddress: data?.occupationAddress,
        offCity: data?.offCity,
        offPincode: data?.offPincode,
        offStateOrCentral: data?.offStateOrCentral,
        officePhoneNumber: data?.officePhoneNumber,
        annualIncome: Number(data?.annualIncome),
        lastMonthTakeHomeSalary: Number(data?.lastMonthTakeHomeSalary),
      };
      payload = occupation;
    } else if (empSubType === "Pvt. Employee") {
      const occupation = {
        type: empType,
        subType: empSubType,
        nameOfCompany: data?.nameOfCompany,
        designation: data?.designation,
        department: data?.department,
        yearsInCurrentDesignation: data?.yearsInCurrentDesignation,
        occupationAddress: data?.occupationAddress,
        offCity: data?.offCity,
        offPincode: data?.offPincode,
        offStateOrCentral: data?.offStateOrCentral,
        officePhoneNumber: data?.officePhoneNumber,
        annualIncome: Number(data?.annualIncome),
        lastMonthTakeHomeSalary: Number(data?.lastMonthTakeHomeSalary),
      };
      payload = occupation;
    } else if (empSubType === "Bank Employee") {
      const occupation = {
        type: empType,
        subType: empSubType,
        nameOfBank: data?.nameOfBank,
        designation: data?.designation,
        department: data?.department,
        // stateOrCentral: data?.stateOrCentral,
        yearsInCurrentDesignation: data?.yearsInCurrentDesignation,
        occupationAddress: data?.occupationAddress,
        offCity: data?.offCity,
        offPincode: data?.offPincode,
        offStateOrCentral: data?.offStateOrCentral,
        officePhoneNumber: data?.officePhoneNumber,
        annualIncome: Number(data?.annualIncome),
        lastMonthTakeHomeSalary: Number(data?.lastMonthTakeHomeSalary),
      };
      payload = occupation;
    }
  }
  return payload;
};

function removePrefixFromKeys(obj: DataObject): DataObject {
  const result: DataObject = {};

  if (obj) {
    Object.keys(obj).forEach((key) => {
      // Remove the part before the first dot (.)
      const newKey = key.split(".").slice(1).join("."); // Split by '.' and take the second part onwards
      result[newKey] = obj[key];
    });
  }

  return result;
}
function transformArray(arr: string[]): string[] {
  return arr.map(
    (item) => item.split(/[. ]+/).join("_") // Split by either period or space, then join with "_"
  );
}
function transformString(input: string): string {
  return input.split(/[. ]+/).join("_");
}

function hasAtLeastOneValue(obj: Record<string, any>): boolean {
  return Object.values(obj).some(
    (value) => value !== null && value !== undefined && value.trim() !== "" // Checks for non-empty values
  );
}

const personlaInfoInitialValues = {
  fullName: "",
  aadhaarNumber: "",
  panCardNumber: "",
  mobileNumber: "",
  email: "",
  address: "",
  city: "",
  state: "",
  pincode: "",
  password: "",
  confirmPassword: "",
  gender: "",
  maritalStatus: "",
  loanAmount: "",
  birthDate: "",
  tenure: "",
  loanTypeId: "",
  correspondenceAddress: "",
  corrCity: "",
  corrState: "",
  corrPincode: "",
};

const subTypeOptions = {
  Employed: [
    "Govt. Employee",
    "Pvt. Employee",
    "PSU Employee",
    "Bank Employee",
  ],
  "Self-Employed: Business": ["Proprietor", "Pvt. Ltd."],
  "Self-Employed": ["Freelancer", "Doctor", "Business", "CA", "Lawyer"],
  Retired: ["Pensioner", "VRS"],
};

const occupationAllFields = {
  type: "",
  subType: "",
  nameOfOrganisation: "",
  designation: "",
  department: "",
  yearsInCurrentDesignation: "",
  occupationAddress: "",
  offCity: "",
  officePhoneNumber: "",
  annualIncome: 0,
  lastMonthTakeHomeSalary: 0,
  nameOfCompany: "",
  offPincode: "",
  offStateOrCentral: "",
  nameOfBank: "",
  registeredNumber: "",
  natureOfWork: "",
  yearsOfExperience: "",
  other: "",
  specialisation: "",
  yearsInPractise: "",
  previousYearITReturn: "",
  amountOfTaxPaid: 3245,
  bankAccountNumber: "",
  bankIfsccode: "",
  gstNumber: "",
  businessName: "",
  typeOfBusiness: "",
  natureOfBusiness: "",
};

export {
  handleOccupationPayloadFormate,
  subTypeOptions,
  personlaInfoInitialValues,
  occupationAllFields,
  removePrefixFromKeys,
  transformArray,
  transformString,
  hasAtLeastOneValue,
};
