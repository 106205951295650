import { useState } from "react";
import { FileUploadProps } from "../../utils/types";
import {
  extractAadhaarNumber,
  extractPanNumber,
  processImage,
  removeSpaces,
} from "../../utils/ocrUtils";
import Loader from "../../utils/Loader";
import { useDispatch } from "react-redux";
import {
  storeAadharOcrNum,
  storePanOcrNum,
} from "../../redux/DocumentUpload/ocrSlice";

export const FileUpload: React.FC<FileUploadProps> = ({
  handleImageChange,
  inputLabel,
  title,
}) => {
  const [fileName, setFileName] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [aadharNum, setAadharNum] = useState<string | null | number>(null);
  const [panNum, setPanNum] = useState<string | null>("");
  const [inProcess, setInprocess] = useState(false);
  const [progressBar, setProgressBar] = useState(0);
  const dispatch = useDispatch();

  const onFileChange = async (e: any) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      console.log("selectedFile:", selectedFile);

      if (selectedFile) {
        const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
        if (!allowedExtensions.test(selectedFile.name)) {
          e.target.value = "";
          return;
        }
      }
      setImageUrl(URL.createObjectURL(selectedFile));
      setAadharNum("");
      setPanNum("");

      if (inputLabel === "aadharCard" || inputLabel === "panCard") {
        processImage(
          selectedFile,
          "eng",
          (progress) => {
            setInprocess(true);
            setProgressBar(Math.round(progress * 100));
          },
          (text) => {
            if (text) {
              console.log("OCR Result:", text);
              const removeSpace = removeSpaces(text);
              if (inputLabel === "aadharCard") {
                let aadharNo = removeSpace && extractAadhaarNumber(removeSpace);
                setAadharNum(aadharNo);
                dispatch(storeAadharOcrNum(aadharNo));
                setInprocess(false);
              } else {
                let panNo = removeSpace && extractPanNumber(removeSpace);
                setPanNum(panNo);
                dispatch(storePanOcrNum(panNo));
                setInprocess(false);
              }
            } else {
              console.log("No text recognized or error occurred.");
            }
          },
          (error) => {
            console.error("Error during OCR:", error);
          }
        );
      }
    }
  };

  return (
    <div className="flex flex-col md:flex-row items-center justify-between w-full rounded-lg border">
      <div
        className={`${
          fileName ? "text-green-500" : "text-blue-700"
        } font-semibold ml-2 md:text-[16px] lg:text-[16px] text-[10px]`}
      >
        {fileName || title}
      </div>
      <button></button>
      <label
        htmlFor={inputLabel}
        className="btn btn-dark !py-2 shadow-shadow_4 !font-medium tracking-widest text-sm mt-2 mr-2"
      >
        {inProcess ? (
          <div className="flex gap-2 w-full  ">
            <div className="w-5 h-5">
              <Loader customClass="" />
            </div>
            <span className="w-12 text-end"> {progressBar} % </span>
          </div>
        ) : (
          `Upload Image`
        )}
      </label>
      <input
        id={inputLabel}
        type="file"
        className="hidden"
        accept={
          inputLabel === "aadharCard" ||
          inputLabel === "panCard" ||
          inputLabel === "profilePhoto" ||
          inputLabel === "cancelledCheque"
            ? ".png, .jpeg, .jpg"
            : " .pdf, .doc"
        }
        name={inputLabel}
        onChange={(e) => {
          handleImageChange(e, inputLabel);
          setFileName(e.target.files?.[0]?.name || null);
          onFileChange(e);
        }}
      />
    </div>
  );
};
