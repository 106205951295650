import { useEffect, useState } from "react";
import CustomDropdown from "./Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  handleCreate,
  handleCreateOccupation,
  updateOccupation,
} from "../../redux/registration/registrationFormDataSlice";
import {
  setEmpSubType,
  setEmpType,
  setIsOccupationSchemaType,
} from "../../redux/registration/registrationSlice";

import Logo from "../../assets/logoNew-light.png";
import {
  hasAtLeastOneValue,
  occupationAllFields,
  removePrefixFromKeys,
  subTypeOptions,
  transformArray,
  transformString,
} from "../../redux/registration/occupation/constant";
import { useFormik } from "formik";
import {
  getValidationSchema,
  occupationInitialValue,
} from "./occupationFormsValidation";

const Occupation = ({ handleNext, handleBack, activeTab, setActiveTab }) => {
  const { occupation } = useSelector((state) => state?.registrationFormData);
  const { empSubType, empType, isOccupationSchemaType } = useSelector(
    (state) => state?.registrationSlice
  );
  const { registrationFormData } = useSelector((state) => state);

  const [inputFieldsForMap, setInputFieldsForMap] = useState([]);
  const [validationSchema, setValidationSchema] = useState();
  // console.log("isOccupationSchemaType:", isOccupationSchemaType);
  const handleReset = () => {
    formik.resetForm();
  };
  useEffect(() => {
    let isOccupationSchema = "";
    isOccupationSchema = transformString(isOccupationSchemaType);
    setValidationSchema(getValidationSchema(isOccupationSchema));
  }, [isOccupationSchemaType]);
  const formik = useFormik({
    initialValues: occupationInitialValue,
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: (values) => {
      handleSumbit();
    },
  });
  useEffect(() => {
    formik.setFieldValue("Occupation.type", empType);
    formik.setFieldTouched("Occupation.type", true);
    formik.setFieldValue("Occupation.subType", empSubType);
    formik.setFieldTouched("Occupation.subType", true);
    console.log("empType", empType);
    console.log("empSubType", empSubType);
  }, [empType, empSubType]);
  useEffect(() => {
    setCommonFields(transformString(empSubType));
  }, []);

  const getErrorMessage = (type, fieldName) => {
    let error =
      formik?.touched?.[type]?.[fieldName] &&
      formik?.errors?.[type]?.[fieldName] ? (
        <span className="error">{formik?.errors?.[type]?.[fieldName]}</span>
      ) : (
        ""
      );
    return error;
  };

  const handleSumbit = () => {
    setActiveTab(activeTab + 1);
  };

  const govtEmployeeInputs = [
    {
      inputType: "text",
      label: "Name of Organisation",
      inputName: "nameOfOrganisation",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Govt_Employee.nameOfOrganisation,
    },
    {
      inputType: "text",
      label: "Designation",
      inputName: "designation",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Govt_Employee.designation,
    },
    {
      inputType: "text",
      label: "Department",
      inputName: "department",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Govt_Employee.department,
    },

    {
      inputType: "text",
      label: "Office address",
      inputName: "occupationAddress",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Govt_Employee.occupationAddress,
    },
    {
      inputType: "text",
      label: "City",
      inputName: "offCity",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Govt_Employee.offCity,
    },
    {
      inputType: "text",
      label: "State/Central",
      inputName: "offStateOrCentral",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Govt_Employee.offStateOrCentral,
    },
    {
      inputType: "number",
      label: "Pin Code",
      inputName: "offPincode",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Govt_Employee.offPincode,
    },
    {
      inputType: "number",
      label: "Year in the current designation",
      inputName: "yearsInCurrentDesignation",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Govt_Employee.yearsInCurrentDesignation,
    },
    {
      inputType: "number",
      label: "Office phone number",
      inputName: "officePhoneNumber",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Govt_Employee.officePhoneNumber,
    },
    {
      inputType: "number",
      label: "Annual Income",
      inputName: "annualIncome",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Govt_Employee.annualIncome,
    },
    {
      inputType: "number",
      label: "Last month take home salary",
      inputName: "lastMonthTakeHomeSalary",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Govt_Employee.lastMonthTakeHomeSalary,
    },
  ];

  const pvtEmployeeInputs = [
    {
      inputType: "text",
      label: "Name of Company",
      inputName: "nameOfCompany",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Pvt_Employee.nameOfCompany,
    },
    {
      inputType: "text",
      label: "Designation",
      inputName: "designation",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Pvt_Employee.designation,
    },
    {
      inputType: "text",
      label: "Department",
      inputName: "department",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Pvt_Employee.department,
    },

    {
      inputType: "text",
      label: "Office address",
      inputName: "occupationAddress",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Pvt_Employee.occupationAddress,
    },
    {
      inputType: "text",
      label: "City",
      inputName: "offCity",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Pvt_Employee.offCity,
    },
    {
      inputType: "text",
      label: "State/Central",
      inputName: "offStateOrCentral",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Pvt_Employee.offStateOrCentral,
    },
    {
      inputType: "number",
      label: "Pin Code",
      inputName: "offPincode",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Pvt_Employee.offPincode,
    },
    {
      inputType: "number",
      label: "Year in the current designation",
      inputName: "yearsInCurrentDesignation",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Pvt_Employee.yearsInCurrentDesignation,
    },
    {
      inputType: "number",
      label: "Office phone number",
      inputName: "officePhoneNumber",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Pvt_Employee.officePhoneNumber,
    },
    {
      inputType: "number",
      label: "Annual Income",
      inputName: "annualIncome",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Pvt_Employee.annualIncome,
    },
    {
      inputType: "number",
      label: "Last month take home salary",
      inputName: "lastMonthTakeHomeSalary",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Pvt_Employee.lastMonthTakeHomeSalary,
    },
  ];

  const psuEmployeeInputs = [
    {
      inputType: "text",
      label: "Name of Organisation",
      inputName: "nameOfOrganisation",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values?.PSU_Employee.nameOfOrganisation,
    },
    {
      inputType: "text",
      label: "Designation",
      inputName: "designation",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values?.PSU_Employee.designation,
    },
    {
      inputType: "text",
      label: "Department",
      inputName: "department",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values?.PSU_Employee.department,
    },

    {
      inputType: "text",
      label: "Office address",
      inputName: "occupationAddress",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values?.PSU_Employee.occupationAddress,
    },
    {
      inputType: "text",
      label: "City",
      inputName: "offCity",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values?.PSU_Employee.offCity,
    },
    {
      inputType: "text",
      label: "State/Central",
      inputName: "offStateOrCentral",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values?.PSU_Employee.offStateOrCentral,
    },
    {
      inputType: "number",
      label: "Pin Code",
      inputName: "offPincode",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values?.PSU_Employee.offPincode,
    },
    {
      inputType: "select",
      label: "Year in the current designation",
      inputName: "yearsInCurrentDesignation",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values?.PSU_Employee.yearsInCurrentDesignation,
    },
    {
      inputType: "number",
      label: "Office phone number",
      inputName: "officePhoneNumber",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values?.PSU_Employee.officePhoneNumber,
    },
    {
      inputType: "number",
      label: "Annual Income",
      inputName: "annualIncome",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values?.PSU_Employee.annualIncome,
    },
    {
      inputType: "number",
      label: "Last month take home salary",
      inputName: "lastMonthTakeHomeSalary",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values?.PSU_Employee.lastMonthTakeHomeSalary,
    },
  ];
  const bankEmployeeInputs = [
    {
      inputType: "text",
      label: "Name of Bank",
      inputName: "nameOfBank",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Bank_Employee.nameOfBank,
    },
    {
      inputType: "text",
      label: "Designation",
      inputName: "designation",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Bank_Employee.designation,
    },
    {
      inputType: "text",
      label: "Department",
      inputName: "department",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Bank_Employee.department,
    },
    {
      inputType: "text",
      label: "Office address",
      inputName: "occupationAddress",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Bank_Employee.occupationAddress,
    },
    {
      inputType: "text",
      label: "City",
      inputName: "offCity",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Bank_Employee.offCity,
    },
    {
      inputType: "text",
      label: "State/Central",
      inputName: "offStateOrCentral",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Bank_Employee.offStateOrCentral,
    },
    {
      inputType: "number",
      label: "Pin Code",
      inputName: "offPincode",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Bank_Employee.offPincode,
    },
    {
      inputType: "number",
      label: "Year in the current designation",
      inputName: "yearsInCurrentDesignation",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Bank_Employee.yearsInCurrentDesignation,
    },
    {
      inputType: "number",
      label: "Office phone number",
      inputName: "officePhoneNumber",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Bank_Employee.officePhoneNumber,
    },
    {
      inputType: "number",
      label: "Annual Income",
      inputName: "annualIncome",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Bank_Employee.annualIncome,
    },
    {
      inputType: "number",
      label: "Last month take home salary",
      inputName: "lastMonthTakeHomeSalary",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik.values.Bank_Employee.lastMonthTakeHomeSalary,
    },
  ];
  const DoctorLawyerCASelfEmpInputs = [
    {
      inputType: "text",
      label: "Registered number",
      inputName: "registeredNumber",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value:
        occupation?.subType === "Doctor"
          ? formik?.values?.Doctor?.registeredNumber
          : occupation?.subType === "CA"
          ? formik?.values?.CA?.registeredNumber
          : formik?.values?.Lawyer?.registeredNumber,
    },
    {
      inputType: "text",
      label: "Specialisation",
      inputName: "specialisation",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value:
        occupation?.subType === "Doctor"
          ? formik?.values?.Doctor?.specialisation
          : occupation?.subType === "CA"
          ? formik?.values?.CA?.specialisation
          : formik?.values?.Lawyer?.specialisation,
    },
    {
      inputType: "number",
      label: "Years in practise",
      inputName: "yearsInPractise",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value:
        occupation?.subType === "Doctor"
          ? formik?.values?.Doctor?.yearsInPractise
          : occupation?.subType === "CA"
          ? formik?.values?.CA?.yearsInPractise
          : formik?.values?.Lawyer?.yearsInPractise,
    },
    {
      inputType: "text",
      label: "Previous year IT return",
      inputName: "previousYearITReturn",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value:
        occupation?.subType === "Doctor"
          ? formik?.values?.Doctor?.previousYearITReturn
          : occupation?.subType === "CA"
          ? formik?.values?.CA?.previousYearITReturn
          : formik?.values?.Lawyer?.previousYearITReturn,
    },
    {
      inputType: "number",
      label: "Amount of tax paid",
      inputName: "amountOfTaxPaid",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value:
        occupation?.subType === "Doctor"
          ? formik?.values?.Doctor?.amountOfTaxPaid
          : occupation?.subType === "CA"
          ? formik?.values?.CA?.amountOfTaxPaid
          : formik?.values?.Lawyer?.amountOfTaxPaid,
    },
    {
      inputType: "number",
      label: "Bank account number",
      inputName: "bankAccountNumber",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value:
        occupation?.subType === "Doctor"
          ? formik?.values?.Doctor?.bankAccountNumber
          : occupation?.subType === "CA"
          ? formik?.values?.CA?.bankAccountNumber
          : formik?.values?.Lawyer?.bankAccountNumber,
    },

    {
      inputType: "text",
      label: "Bank IFSC code",
      inputName: "bankIfsccode",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value:
        occupation?.subType === "Doctor"
          ? formik?.values?.Doctor?.bankIfsccode
          : occupation?.subType === "CA"
          ? formik?.values?.CA?.bankIfsccode
          : formik?.values?.Lawyer?.bankIfsccode,
    },
    {
      inputType: "text",
      label: "Office address",
      inputName: "occupationAddress",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value:
        occupation?.subType === "Doctor"
          ? formik?.values?.Doctor?.occupationAddress
          : occupation?.subType === "CA"
          ? formik?.values?.CA?.occupationAddress
          : formik?.values?.Lawyer?.occupationAddress,
    },
    {
      inputType: "text",
      label: "City",
      inputName: "offCity",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value:
        occupation?.subType === "Doctor"
          ? formik?.values?.Doctor?.offCity
          : occupation?.subType === "CA"
          ? formik?.values?.CA?.offCity
          : formik?.values?.Lawyer?.offCity,
    },
    {
      inputType: "text",
      label: "State/Central",
      inputName: "offStateOrCentral",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value:
        occupation?.subType === "Doctor"
          ? formik?.values?.Doctor?.offStateOrCentral
          : occupation?.subType === "CA"
          ? formik?.values?.CA?.offStateOrCentral
          : formik?.values?.Lawyer?.offStateOrCentral,
    },
    {
      inputType: "number",
      label: "Pin Code",
      inputName: "offPincode",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value:
        occupation?.subType === "Doctor"
          ? formik?.values?.Doctor?.offPincode
          : occupation?.subType === "CA"
          ? formik?.values?.CA?.offPincode
          : formik?.values?.Lawyer?.offPincode,
    },
  ];
  const freelancerSelfEmpInputs = [
    {
      inputType: "text",
      label: "Registered number",
      inputName: "registeredNumber",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik?.values?.Freelancer?.registeredNumber,
    },
    {
      inputType: "text",
      label: "Nature of work",
      inputName: "natureOfWork",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik?.values?.Freelancer?.natureOfWork,
    },
    {
      inputType: "number",
      label: "Years of experience",
      inputName: "yearsOfExperience",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik?.values?.Freelancer?.yearsOfExperience,
    },
    {
      inputType: "text",
      label: "Other",
      inputName: "other",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik?.values?.Freelancer?.other,
    },
  ];
  const businessSelfEmpInputs = [
    {
      inputType: "text",
      label: "GST Number",
      inputName: "gstNumber",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik?.values?.Business?.gstNumber,
    },
    {
      inputType: "text",
      label: "Name of Business",
      inputName: "businessName",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik?.values?.Business?.businessName,
    },
    {
      inputType: "text",
      label: "Office address",
      inputName: "occupationAddress",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik?.values?.Business?.occupationAddress,
    },
    {
      inputType: "text",
      label: "City",
      inputName: "offCity",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik?.values?.Business?.offCity,
    },
    {
      inputType: "text",
      label: "State/Central",
      inputName: "offStateOrCentral",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik?.values?.Business?.offStateOrCentral,
    },
    {
      inputType: "number",
      label: "Pin Code",
      inputName: "offPincode",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik?.values?.Business?.offPincode,
    },
    {
      inputType: "text",
      label: "Type of business",
      inputName: "typeOfBusiness",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik?.values?.Business?.typeOfBusiness,
    },
    {
      inputType: "text",
      label: "Nature Of business",
      inputName: "natureOfBusiness",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik?.values?.Business?.natureOfBusiness,
    },
    {
      inputType: "number",
      label: "Amount of tax paid",
      inputName: "amountOfTaxPaid",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik?.values?.Business?.amountOfTaxPaid,
    },
    {
      inputType: "text",
      label: "Previous year IT return",
      inputName: "previousYearITReturn",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: formik?.values?.Business?.previousYearITReturn,
    },
  ];
  const handleConfirm = (warnMessage) => {
    return window.confirm(warnMessage);
  };
  const handleOccupationEmpType = (empType) => {
    let isDataExistInInputs = hasAtLeastOneValue(
      removePrefixFromKeys(occupationPlayload)
    );
    if (isDataExistInInputs) {
      if (
        handleConfirm("Your data will be cleared, are you sure to continue?")
      ) {
        dispatch(handleCreateOccupation());
        dispatch(setIsOccupationSchemaType(empType));
        handleSelect(empType); // Using _blank to open in a new tab
      }
    } else {
      dispatch(handleCreateOccupation());
      dispatch(setIsOccupationSchemaType(empType));
      handleSelect(empType);
    }
  };
  const handleOccupationEmpSubType = (empSubType) => {
    if (handleConfirm("Do you really want to leave?")) {
      dispatch(setEmpSubType(empSubType));
      dispatch(handleCreateOccupation());
      handleClearSubTypeInputs(empSubType);
    }
  };
  const dispatch = useDispatch();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let extractKey = name?.split(".")[1];

    const pincodePattern = /^[1-9][0-9]{5}$/;
    if (name === "loanAmount" || name === "incomePerMonth") {
      if (!/^\d*$/.test(value)) return;
    }
    if (name === "subType") {
      dispatch(setEmpSubType(value));
      dispatch(setIsOccupationSchemaType(value));
      formik.setFieldValue("Occupation.subType", value);
      formik.setFieldTouched("Occupation.subType", true);
    }
    if (name === "offPincode") {
      value?.length < 7 && dispatch(updateOccupation({ [name]: value }));
      // value?.length < 7 && dispatch(updateOccupation({ [extractKey]: value }));
      formik.handleChange(e);
      // formik.handleChange(e);
    } else if (name === "bankIfsccode") {
      value?.length < 12 && dispatch(updateOccupation({ [name]: value }));
      // value?.length < 12 && dispatch(updateOccupation({ [extractKey]: value }));
      formik.handleChange(e);
    } else {
      dispatch(updateOccupation({ [name]: value }));
      // dispatch(updateOccupation({ [extractKey]: value }));
      formik.handleChange(e);
    }
  };

  const options = ["Employed", "Self-Employed"];

  useEffect(() => {
    // setSelectedType("");
    dispatch(setEmpType(empType));
  }, [empType]);

  const handleSelect = (option) => {
    dispatch(updateOccupation({ subType: "" }));
    // setSelectedType(option);
    dispatch(updateOccupation({ type: option }));
    formik.setFieldValue("Occupation.type", option);
    formik.setFieldValue("Occupation.subType", "");
    formik.setFieldTouched("Occupation.type", true);
    dispatch(setEmpType(option));
  };

  const occupationPlayload = {
    ...registrationFormData.occupation,
    type: empType,
    subType: empSubType,
  };
  const setCommonFields = (employeeObjKey) => {
    console.log("EmployeeObjKey", employeeObjKey);
    const prefixObject = removePrefixFromKeys(occupationPlayload);
    console.log("prefixObject :", prefixObject);
    for (const property in prefixObject) {
      formik.setFieldValue(
        `${employeeObjKey}.${property}`,
        `${prefixObject[property]}`
      );
    }
    console.log("formik", formik.values);
  };

  const handleClearSubTypeInputs = (empSubType) => {
    const subTypes = [
      "Govt. Employee",
      "Pvt. Employee",
      "PSU Employee",
      "Bank Employee",
      "Freelancer",
      "Doctor",
      "Business",
      "CA",
      "Lawyer",
    ];

    const filteredOptions = subTypes?.filter(
      (option, index) => option !== empSubType
    );
    // const setoptions= filteredOptions.map((item)=> )
    const getEmpKey = transformArray(filteredOptions);
    const prefixObject = removePrefixFromKeys(occupationPlayload);
    getEmpKey.forEach((keys, index) => {
      for (const property in prefixObject) {
        formik.setFieldValue(`${keys}.${property}`, ``);
      }
    });
  };

  return (
    <>
      <form action="" onSubmit={formik.handleSubmit}>
        <div className="occupationInfo space-y-6 min-h-[545px]">
          <h4 className="text-lg text-[#00a068] font-semibold capitalize text-center underline underline-offset-2 mb-2">
            Personal Loan Application
          </h4>
          <form>
            <div className="form-group w-full flex-col md:flex-row flex items-center gap-4 justify-start bg-gray-50 rounded-xl shadowLight border-r-2 border-l-2 p-4 border-green-800">
              <label className="!text-base !mb-0 font-bold pr-8">Type :</label>
              <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row gap-6 flex-wrap">
                {options.map((subType) => (
                  <button
                    type="button"
                    onClick={() => {
                      handleOccupationEmpType(subType);
                    }}
                    key={subType}
                    className={` rounded-md px-4 py-2 flex items-center !mb-0 cursor-pointer  ${
                      empType === subType
                        ? "bg-green-300 boxShadow border border-solid border-green-400"
                        : "bg-white shadowLight border border-solid border-gray-300"
                    }`}
                  >
                    <input
                      type="radio"
                      name="Occupation.subType"
                      value={subType}
                      checked={empType === subType}
                      onChange={handleInputChange}
                      onBlur={formik.handleBlur}
                      required
                      className="radio-input hidden"
                    />
                    <span
                      className={`radio-custom ${
                        empType === subType
                          ? "border-green-500 bg-green-500"
                          : "border-gray-400"
                      }`}
                    ></span>
                    {subType}
                  </button>
                ))}
              </div>

              {formik?.touched?.Occupation && (
                <p className="h-2 error">{formik?.errors?.Occupation?.type} </p>
              )}
            </div>

            <div className="occu_content h-full min-h-[400px] flex justify-center items-center flex-col">
              {!empType && (
                <div className="bgImg w-full flex items-center justify-center">
                  <p className="text-red-500 font-normal text-base text-center">
                    Please choose your Occupation
                  </p>
                </div>
              )}

              {empType && (
                <div className="w-full flex">
                  {empType && (
                    <div className="occu_LeftSide w-[22%] mt-6 border-r-[1px] border-solid border-green-500 pr-6">
                      <div className="">
                        {empType === "Home Maker" ? (
                          <>
                            <div className="form-group w-full md:w-1/3">
                              <label>Source of income</label>
                              <input
                                type="text"
                                name="details"
                                placeholder=""
                                value={occupation?.details || ""}
                                onChange={handleInputChange}
                                onBlur={formik.handleBlur}
                                className="border border-gray-300 rounded-md px-4 py-2 w-full"
                              />
                            </div>
                            <div className="form-group w-full md:w-1/3">
                              <label>Net Income Per Month</label>
                              <input
                                type="number"
                                name="incomePerMonth"
                                placeholder=""
                                value={occupation?.incomePerMonth || ""}
                                onChange={handleInputChange}
                                onBlur={formik.handleBlur}
                                className="border border-gray-300 rounded-md px-4 py-2 w-full"
                              />
                            </div>
                          </>
                        ) : (
                          empType &&
                          subTypeOptions[empType] && (
                            <div className="form-group w-full customRadioBtn ">
                              <label className="!text-sm !mb-4 font-bold underline underline-offset-2 !text-green-800">
                                Sub Type
                              </label>
                              <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row flex-wrap">
                                {subTypeOptions[empType].map((subType) => (
                                  <label
                                    onClick={() => {
                                      dispatch(setEmpSubType(subType));
                                      dispatch(handleCreateOccupation());
                                      handleClearSubTypeInputs(subType);
                                    }}
                                    key={subType}
                                    className={`border border-[#E6ECF4] border-solid px-4 py-3 flex items-center !mb-0 w-full cursor-pointer hover:bg-green-700 hover:!text-white transition-transform ${
                                      occupation?.subType === subType
                                        ? "bg-green-300 boxShadow customArrow"
                                        : "bg-transparent border-0 border-b-[1px]"
                                    }`}
                                  >
                                    <input
                                      type="radio"
                                      name="subType"
                                      value={subType}
                                      checked={occupation?.subType === subType}
                                      onChange={handleInputChange}
                                      onBlur={formik.handleBlur}
                                      required
                                      className="radio-input hidden"
                                    />
                                    <span
                                      className={`${
                                        occupation?.subType === subType
                                          ? "border-green-500 bg-green-500"
                                          : "border-gray-400"
                                      }`}
                                    ></span>
                                    {subType}
                                  </label>
                                ))}
                              </div>
                              <p className="h-2">
                                {getErrorMessage("Occupation", "subType")}{" "}
                              </p>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}

                  {(empType == "Employed" || empType == "Self-Employed") && (
                    <div className="occu_RightSide w-[78%] pl-6 mt-6 min-h-[340px]">
                      <div className="flex flex-wrap gap-4">
                        {empType && !occupation?.subType && (
                          <>
                            <p className="text-red-500 font-normal text-base occu_RightSide-content">
                              Please choose your Field
                            </p>
                          </>
                        )}
                        {empType &&
                        empType == "Employed" &&
                        occupation?.subType === "Govt. Employee"
                          ? govtEmployeeInputs?.map((input, index) => {
                              return (
                                <div className={input?.inputClass}>
                                  <label>
                                    {input.label}{" "}
                                    <span className="text-red-700">*</span>
                                  </label>
                                  <input
                                    type={input.inputType}
                                    name={`Govt_Employee.${input.inputName}`}
                                    placeholder={input.placeholder}
                                    value={input.value || ""}
                                    onChange={handleInputChange}
                                    onBlur={formik.handleBlur}
                                    className="border border-gray-300 rounded-md px-4 py-2 w-full"
                                  />
                                  <p className="h-2">
                                    {getErrorMessage(
                                      "Govt_Employee",
                                      input.inputName
                                    )}{" "}
                                  </p>
                                </div>
                              );
                            })
                          : empType &&
                            empType == "Employed" &&
                            occupation?.subType === "Pvt. Employee"
                          ? pvtEmployeeInputs?.map((input, index) => {
                              return (
                                <div className={input.inputClass}>
                                  <label>
                                    {input.label}{" "}
                                    <span className="text-red-700">*</span>
                                  </label>
                                  <input
                                    type={input.inputType}
                                    name={`Pvt_Employee.${input.inputName}`}
                                    placeholder={input.placeholder}
                                    value={input.value || ""}
                                    onChange={handleInputChange}
                                    onBlur={formik.handleBlur}
                                  />
                                  <p className="h-2">
                                    {getErrorMessage(
                                      "Pvt_Employee",
                                      input.inputName
                                    )}{" "}
                                  </p>
                                </div>
                              );
                            })
                          : empType &&
                            empType == "Employed" &&
                            occupation?.subType === "PSU Employee"
                          ? psuEmployeeInputs?.map((input, index) => {
                              return (
                                <div className={input.inputClass}>
                                  <label>
                                    {input.label}{" "}
                                    <span className="text-red-700">*</span>
                                  </label>
                                  <input
                                    type={input.inputType}
                                    name={`PSU_Employee.${input.inputName}`}
                                    placeholder={input.placeholder}
                                    value={input.value || ""}
                                    onChange={handleInputChange}
                                    onBlur={formik.handleBlur}
                                  />
                                  <p className="h-2">
                                    {getErrorMessage(
                                      "PSU_Employee",
                                      input.inputName
                                    )}{" "}
                                  </p>
                                </div>
                              );
                            })
                          : empType &&
                            empType == "Employed" &&
                            occupation?.subType === "Bank Employee"
                          ? bankEmployeeInputs?.map((input, index) => {
                              return (
                                <div key={index} className={input.inputClass}>
                                  <label>
                                    {input.label}{" "}
                                    <span className="text-red-700">*</span>
                                  </label>
                                  <input
                                    type={input.inputType}
                                    name={`Bank_Employee.${input.inputName}`}
                                    placeholder={input.placeholder}
                                    value={input.value || ""}
                                    onChange={handleInputChange}
                                    onBlur={formik.handleBlur}
                                  />
                                  <p className="h-2">
                                    {getErrorMessage(
                                      "Bank_Employee",
                                      input.inputName
                                    )}{" "}
                                  </p>
                                </div>
                              );
                            })
                          : (empType &&
                              empType == "Self-Employed" &&
                              occupation?.subType === "Doctor") ||
                            (empType && occupation?.subType === "CA") ||
                            (empType && occupation?.subType === "Lawyer")
                          ? DoctorLawyerCASelfEmpInputs?.map((input, index) => {
                              return (
                                <div className={input.inputClass}>
                                  <label>
                                    {input.label}{" "}
                                    <span className="text-red-700">*</span>
                                  </label>
                                  <input
                                    type={input.inputType}
                                    // name={input.inputName}
                                    name={`${occupation?.subType}.${input.inputName}`}
                                    placeholder={input.placeholder}
                                    value={input.value || ""}
                                    onChange={handleInputChange}
                                    onBlur={formik.handleBlur}
                                  />
                                  <p className="h-2">
                                    {/* {getErrorMessage(formik, input.inputName)}{" "} */}
                                    {getErrorMessage(
                                      occupation?.subType,
                                      input.inputName
                                    )}
                                  </p>
                                </div>
                              );
                            })
                          : empType &&
                            empType == "Self-Employed" &&
                            occupation?.subType === "Freelancer"
                          ? freelancerSelfEmpInputs?.map((input, index) => {
                              return (
                                <div className={input.inputClass}>
                                  <label>
                                    {input.label}{" "}
                                    <span className="text-red-700">*</span>
                                  </label>
                                  <input
                                    type={input.inputType}
                                    // name={input.inputName}
                                    name={`Freelancer.${input.inputName}`}
                                    placeholder={input.placeholder}
                                    value={input.value || ""}
                                    onChange={handleInputChange}
                                    onBlur={formik.handleBlur}
                                  />
                                  <p className="h-2">
                                    {getErrorMessage(
                                      occupation?.subType,
                                      input.inputName
                                    )}
                                  </p>
                                </div>
                              );
                            })
                          : empType &&
                            empType == "Self-Employed" &&
                            occupation?.subType === "Business"
                          ? businessSelfEmpInputs?.map((input, index) => {
                              return (
                                <div className={input.inputClass}>
                                  <label>
                                    {input.label}{" "}
                                    <span className="text-red-700">*</span>
                                  </label>
                                  <input
                                    type={input.inputType}
                                    // name={input.inputName}
                                    name={`Business.${input.inputName}`}
                                    placeholder={input.placeholder}
                                    value={input.value || ""}
                                    onChange={handleInputChange}
                                    onBlur={formik.handleBlur}
                                  />
                                  <p className="h-2">
                                    {getErrorMessage(
                                      occupation?.subType,
                                      input.inputName
                                    )}
                                  </p>
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </form>
        </div>
        <div className="button-container">
          <button
            type="button"
            onClick={handleBack}
            disabled={activeTab === 0}
            className="backBtn"
          >
            Back
          </button>

          <button
            type={formik.errors?.Occupation?.subType ? "button" : "submit"}
            className="nextBtn"
          >
            Next
          </button>
        </div>
      </form>
    </>
  );
};

export default Occupation;
