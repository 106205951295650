import { useNavigate } from 'react-router-dom'
import Header from '../../../components/Header'
import { useEffect, useState } from 'react'
import {
	getAllAdminBorrowerList,
	getAllBorrowerByAgentId,
} from '../../../utils/apis'
import { getUserFromLocalStorage } from '../../../utils/localStorageUtil'

const BorrowerList = () => {
	const [borrowerList, setBorrowerList] = useState<any[]>([])
	const [filteredBorrowerList, setFilteredBorrowerList] = useState<any[]>([])
	const [searchTerm, setSearchTerm] = useState('')
	const [page, setPage] = useState(1)
	const [pageCount, setPageCount] = useState<number[]>([])

	let constant = 10

	const navigate = useNavigate()
	const agentId = getUserFromLocalStorage()?.borrowerId
	const role = getUserFromLocalStorage()?.role

	const handleCreateBorrower = () => {
		navigate('/register', { state: 'Agent' })
	}

	useEffect(() => {
		const fetchBorrowers = async () => {
			try {
				let response
				if (role === 'ADMIN') {
					response = await getAllAdminBorrowerList(agentId)
				} else {
					response = await getAllBorrowerByAgentId(agentId)
				}

				const borrowers = response?.borrowers || []
				setBorrowerList(borrowers)
				setFilteredBorrowerList(borrowers)

				// Calculate page count based on borrowers length
				const pageCountArray = []
				const totalPages = Math.ceil(borrowers.length / constant)
				for (let i = 1; i <= totalPages; i++) {
					pageCountArray.push(i)
				}
				setPageCount(pageCountArray)
			} catch (error) {
				console.error('Error fetching borrowers:', error)
			}
		}

		fetchBorrowers()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value
		setSearchTerm(value)

		if (value) {
			setFilteredBorrowerList(
				borrowerList.filter((borrower) =>
					borrower.fullName.toLowerCase().includes(value.toLowerCase())
				)
			)
		} else {
			setFilteredBorrowerList(borrowerList)
		}
	}

	const handleView = (borrowerID: any) => {
		navigate('/user-profile', {
			state: { borrowerID: borrowerID, origin: 'borrowerList' },
		})
	}

	return (
		<div className="min-h-screen flex flex-col">
			<Header name="Borrower List" />
			<div className="w-[1480px] mx-auto">
				<div className="flex flex-1 mt-10">
					<main className="flex-1 bg-gray-100 p-6">
						<div className="flex justify-between items-center">
							<div className="flex items-center py-4">
								<input
									type="text"
									value={searchTerm}
									onChange={handleSearchChange}
									placeholder="Enter Borrower Name..."
									className="w-full p-2 border border-gray-300 rounded-none"
								/>
								<button
									onClick={() => setSearchTerm('')}
									className="bg-[#17a44f] text-white px-4 py-2"
								>
									Clear
								</button>
							</div>
							<button
								onClick={handleCreateBorrower}
								className="bg-[#17a44f] text-white px-4 py-2 h-10 flex items-center justify-center rounded-xl shadow-shadow_4"
							>
								Create Borrower
							</button>
						</div>
						<div className="overflow-x-auto bg-white rounded shadow-md">
							<table className="w-full text-left border-collapse">
								<thead>
									<tr className="bg-[#17a44f] text-white text-center">
										<th className="px-4 py-2 border">Borrower Name</th>
										<th className="px-4 py-2 border">Aadhar Card</th>
										<th className="px-4 py-2 border">Pan Card</th>
										<th className="px-4 py-2 border">Loan Amount</th>
										<th className="px-4 py-2 border">Actions</th>
									</tr>
								</thead>
								<tbody>
									{filteredBorrowerList && filteredBorrowerList?.length > 0 ? (
										filteredBorrowerList?.map((data: any) => (
											<tr
												key={data.BorrowerId}
												className="hover:bg-gray-100 text-center"
											>
												<td className="px-4 py-2 border">{data.fullName}</td>
												<td className="px-4 py-2 border">
													{data.aadhaarNumber}
												</td>
												<td className="px-4 py-2 border">
													{data.panCardNumber}
												</td>
												<td className="px-4 py-2 border">
													{data.loanAmount}/-
												</td>
												<td className="px-4 py-2 border flex gap-2 justify-center">
													<button
														onClick={() => handleView(data.BorrowerId)}
														className="bg-blue-500 text-white px-4 py-2 rounded shadow"
													>
														View
													</button>
												</td>
											</tr>
										))
									) : (
										<tr>
											<td
												colSpan={5}
												className="px-4 py-6 text-center text-gray-500"
											>
												No borrowers available
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
						<div className="flex justify-between items-center mt-4">
							<span>
								Showing 1 to{' '}
								{borrowerList.length < constant
									? borrowerList.length
									: constant}{' '}
								of {borrowerList.length} entries
							</span>
							<div className="flex items-center gap-2 border-[#17a44f]">
								<button
									className="px-3 py-1 border border-[#17a44f] text-[#17a44f] rounded hover:bg-[#17a44f] hover:text-white"
									onClick={() => setPage(1)}
								>
									First
								</button>
								<button
									className="px-3 py-1 border border-gray-500 bg-[#F5F5F5] rounded hover:bg-[#17a44f] hover:text-white"
									onClick={() => setPage(page - 1)}
									disabled={page === 1}
								>
									Previous
								</button>
								{pageCount.map((data) => (
									<button
										className={`px-3 py-1 border border-[#17a44f] text-[#17a44f] rounded hover:bg-[#17a44f] hover:text-white ${
											page === data ? 'bg-[#17a44f] text-white' : ''
										}`}
										onClick={() => setPage(data)}
									>
										{data}
									</button>
								))}
								<button
									className="px-3 py-1 border border-gray-500 bg-[#F5F5F5] rounded hover:bg-[#17a44f] hover:text-white"
									onClick={() => setPage(page + 1)}
									disabled={page === pageCount.length}
								>
									Next
								</button>
								<button
									className="px-3 py-1 border border-[#17a44f] text-[#17a44f] rounded hover:bg-[#17a44f] hover:text-white"
									onClick={() => setPage(pageCount.length)}
								>
									Last
								</button>
							</div>
						</div>
					</main>
				</div>
			</div>
		</div>
	)
}

export default BorrowerList
