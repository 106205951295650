import { useState, useEffect } from "react";
import LOGO from "../assets/Logo_circle.png";
import { OtpModal } from "../utils/types";
import Loader from "../utils/Loader";

const OTPVerificationInput: React.FC<OtpModal> = ({
  setAadhaOtp,
  handleSubmitAadharOtp,
  handleVerify,
  isLoading,
  onClose,
}) => {
  const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));
  const [timer, setTimer] = useState<number>(60);
  const [isResendDisabled, setIsResendDisabled] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  // Countdown Timer
  useEffect(() => {
    if (isResendDisabled && timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else if (timer === 0) {
      setIsResendDisabled(false);
    }
  }, [isResendDisabled, timer]);

  const handleChange = (value: string, index: number) => {
    if (!/^\d*$/.test(value)) return; // Allow only numbers
    const updatedOtp = [...otp];
    updatedOtp[index] = value.slice(-1); // Limit to one character
    setOtp(updatedOtp);
    setAadhaOtp(updatedOtp.join(""));
    setError("");

    // Move to the next input box if a digit is entered
    if (value && index < otp.length - 1) {
      const nextInput = document.getElementById(`otp-${index + 1}`);
      nextInput?.focus();
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      const prevInput = document.getElementById(`otp-${index - 1}`);
      prevInput?.focus();
    }
  };

  const handleResend = () => {
    setIsResendDisabled(true);
    setTimer(60);
    handleVerify();
  };

  return (
    <div className="flex justify-center">
      <button
        disabled={isResendDisabled}
        onClick={onClose}
        type="button"
        className="close-button !bg-transparent w-1/12 "
      >
        &times;
      </button>
      <div className="bg-white rounded-lg p-8 max-w-sm w-full relative">
        {/* Logo */}
        <div className="flex justify-center mb-4">
          <img src={LOGO} alt="Logo" />
        </div>
        <h1 className="text-xl font-bold text-[#17a44f] text-center mb-4">
          OTP Verification
        </h1>
        <p className="text-gray-600 text-center mb-6">
          Enter the 6-digit code sent to your email or phone.
        </p>
        {/* <form onSubmit={handleSubmit} className="flex flex-col items-center"> */}
        <div className="flex justify-center gap-2 mb-2">
          {otp.map((digit, index) => (
            <input
              key={index}
              id={`otp-${index}`}
              type="text"
              inputMode="numeric"
              maxLength={1}
              value={digit}
              onChange={(e) => handleChange(e.target.value, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              className="w-12 h-12 border border-gray-300 rounded-lg text-center text-xl font-medium focus:outline-none focus:ring-2 focus:ring-[#17a44f] focus:border-transparent"
            />
          ))}
        </div>
        {/* Error Message */}
        {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
        <button
          type="button"
          className="w-full bg-[#17a44f] text-white py-2 rounded-lg font-semibold hover:bg-[#239b53] transition mt-4"
          onClick={handleSubmitAadharOtp}
        >
          {isLoading ? <Loader /> : "Verify OTP"}
        </button>
        {/* </form> */}
        {/* Resend OTP Section */}
        <div className="flex justify-center items-center mt-4">
          <button
            type="button"
            onClick={handleResend}
            disabled={isResendDisabled}
            className={`${
              isResendDisabled
                ? "text-gray-400 cursor-not-allowed"
                : "text-blue-600 hover:underline"
            } font-semibold`}
          >
            Resend OTP
          </button>
          {isResendDisabled && (
            <span className="ml-2 text-gray-500 text-sm">in {timer}s</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default OTPVerificationInput;
