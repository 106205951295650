import * as Yup from "yup";
import { getUserFromLocalStorage } from "../../utils/localStorageUtil";
const empType = getUserFromLocalStorage()?.empType;
const validationSchema = Yup.object().shape({
  upiId: Yup.string()
    .required("UPI ID is required")
    .matches(
      /^[\w.-]+@[a-zA-Z]+$/,
      "UPI ID format is invalid (e.g., example@upi)"
    ),
  bankIfscCode: Yup.string()
    .required("IFSC Code is required")
    .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC Code format"),
  bankAccountNumber: Yup.string()
    .required("Bank Account Number is required")
    .matches(/^\d+$/, "Must be a valid account number")
    .min(10, "Bank Account Number must be at least 10 digits")
    .max(16, "Bank Account Number must not exceed 16 digits"),
  bankAccountName: Yup.string().required("Bank Account Name is required"),
  // storedAadharNum: Yup.string().required("stored Aadhar Num is required"),
  // storedPanNum: Yup.string().required("stored Pan Num is required"),
  OcrPanNum: Yup.string()
    .required("")
    .matches(
      /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
      "Unable to read Pan details or Invalid Pan, Please upload a clear image."
    ),
  // .test(
  //   "matches-stored-pan",
  //   "Varification failed",
  //   function (value) {
  //     const { storedPanNum } = this.parent; // Access other fields
  //     return value === storedPanNum;
  //   }
  // ),
  OcrAadharNum: Yup.string()
    .required("")
    .matches(
      /^\d{12}$/,
      "Unable to read Aadhaar details. Please upload a clear image."
    ),
  // .test(
  //   "matches-stored-aadhar",
  //   "Varification failed",
  //   function (value) {
  //     const { storedAadharNum } = this.parent; // Access other fields
  //     return value === storedAadharNum;
  //   }
  // )
  aadharCard: Yup.mixed()
    .required("Aadhar Card is required")
    .test(
      "fileSize",
      "File size exceeds 2 MB",
      (value) => value && value.size <= 2 * 1024 * 1024
    )
    .test(
      "fileType",
      "Only image files (JPG, JPEG, PNG) are allowed",
      (value) =>
        value && ["image/jpeg", "image/jpg", "image/png"].includes(value.type) // File type validation
    ),
  panCard: Yup.mixed()
    .required("PAN Card is required")
    .test(
      "fileSize",
      "File size exceeds 2 MB",
      (value) => value && value.size <= 2 * 1024 * 1024
    )
    .test(
      "fileType",
      "Only image files (JPG, JPEG, PNG) are allowed",
      (value) =>
        value && ["image/jpeg", "image/jpg", "image/png"].includes(value.type) // File type validation
    ),
  profilePhoto: Yup.mixed()
    .required("Profile Photo is required")
    .test(
      "fileSize",
      "File size exceeds 2 MB",
      (value) => value && value.size <= 2 * 1024 * 1024
    )
    .test(
      "fileType",
      "Only image files (JPG, JPEG, PNG) are allowed",
      (value) =>
        value && ["image/jpeg", "image/jpg", "image/png"].includes(value.type) // File type validation
    ),
  cancelledCheque: Yup.mixed()
    .required("Cancelled Cheque is required")
    .test(
      "fileSize",
      "File size exceeds 2 MB",
      (value) => value && value.size <= 2 * 1024 * 1024
    )
    .test(
      "fileType",
      "Only image files (JPG, JPEG, PNG) are allowed",
      (value) =>
        value && ["image/jpeg", "image/jpg", "image/png"].includes(value.type) // File type validation
    ),
  bankStatement: Yup.mixed()
    .required("Bank statement is required")
    .test(
      "fileSize",
      "File size exceeds 2 MB",
      (value) => value && value.size <= 2 * 1024 * 1024
    )
    .test(
      "fileType",
      "Only .pdf or .doc files are allowed",
      (value) =>
        value && ["application/pdf", "application/msword"].includes(value.type) // MIME type validation
    ),
  gstCertificate:
    empType === "Self-Employed" &&
    Yup.mixed()
      .required("Gst Certificate is required")
      .test(
        "fileSize",
        "File size exceeds 2 MB",
        (value) => value && value.size <= 2 * 1024 * 1024
      ),
  // form16:
  //   empType !== "Self-Employed" &&
  //   Yup.mixed()
  //     .required("Form 16 is required")
  //     .test(
  //       "fileSize",
  //       "File size exceeds 2 MB",
  //       (value) => value && value.size <= 2 * 1024 * 1024
  //     )
  //     .test(
  //       "fileType",
  //       "Only .pdf or .doc files are allowed",
  //       (value) =>
  //         value &&
  //         ["application/pdf", "application/msword"].includes(value.type) // MIME type validation
  //     ),

  ItReturn:
    empType === "Self-Employed" &&
    Yup.mixed()
      .required("IT Return is required")
      .test(
        "fileSize",
        "File size exceeds 2 MB",
        (value) => value && value.size <= 2 * 1024 * 1024
      ),
});

export { validationSchema };
