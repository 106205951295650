import { FC, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Header from '../../components/Header'
import { handleGetDocReviewStatusDetails } from '../../redux/DocumentUpload/DocumentUploadReviewSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../utils/store'
import { getUserFromLocalStorage } from '../../utils/localStorageUtil'
import { handleBeforeUnload } from '../../utils/getIdFromUrl'
import CustomModal from '../../components/CustomModal'
import successIcon from '../../assets/icons/success_icon.png'
import failedIcon from '../../assets/icons/failed_icon.png'
import AadharIcon from '../../assets/drs/aadhar_icon.png'
import CancelledCheckIcon from '../../assets/drs/cancelled_check_icon.png'
import GstCertificateIcon from '../../assets/drs/gst_certificate_icon.png'
import ItReturnIcon from '../../assets/drs/it_return_icon.png'
import Form16Icon from '../../assets/drs/form16_icon.png'
import BankStatementIcon from '../../assets/drs/bank_statement_icon.png'
import PanIcon from '../../assets/drs/pan_card_icon.png'
import ProfileIcon from '../../assets/drs/profile.png'
import { approveBorrower } from '../../utils/apis'

const documentIcons: any = {
	Aadhar: AadharIcon,
	Pan: PanIcon,
	CancelledCheck: CancelledCheckIcon,
	GstCertificate: GstCertificateIcon,
	ItReturn: ItReturnIcon,
	Form16: Form16Icon,
	BankStatement: BankStatementIcon,
	Profile: ProfileIcon,
}

const DocumentReviewStatusPage: FC = () => {
	const [isChecked, setIsChecked] = useState(false)
	const [isChecked2, setIsChecked2] = useState(false)
	const [isTermsModal, setTermsModal] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [remarks, setRemarks] = useState('')

	const dispatch = useDispatch<AppDispatch>()

	const { data, loading } = useSelector(
		(state: RootState) => state.documentReviewStatus
	)

	const navigate = useNavigate()
	const status = useLocation().state
	const borrowerID = useLocation()?.state?.borrowerID
	const origin = useLocation()?.state?.origin
	const role = getUserFromLocalStorage()?.role
	const borrowerIdFromStorage = getUserFromLocalStorage()?.borrowerId
	const personalInfo = useSelector(
		(state: RootState) => state?.profile?.data?.borrowerData?.personalInfo
	)

	useEffect(() => {
		window.addEventListener('beforeunload', handleBeforeUnload)
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleRoute = () => {
		if (status === 1) {
			setIsModalOpen(true)
		} else {
			navigate('/loandetails')
		}
	}

	const closeModal = () => {
		setIsModalOpen(false)
	}

	const handleBack = () => {
		navigate('/profile')
	}

	let payload: any

	if (borrowerID) {
		payload = {
			borrowerId: borrowerID,
			loanTypeId: personalInfo?.loanTypeId?.loanTypeId,
		}
	} else {
		payload = {
			borrowerId: borrowerIdFromStorage,
			loanTypeId: personalInfo?.loanTypeId?.loanTypeId,
		}
	}

	useEffect(() => {
		dispatch(handleGetDocReviewStatusDetails(payload))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleClickInput = (id: string) => {
		document.getElementById(id)?.click()
	}

	const handleTermsModal = (e: any) => {
		e.preventDefault()
		setTermsModal(true)
	}

	const closeCustModal = () => {
		setTermsModal(false)
	}

	const handleApprove = () => {
		approveBorrower(borrowerIdFromStorage, borrowerID, remarks).then(
			(response) => {
				if (response.statusCode === 200) {
					navigate('/pendingBorrowers')
				}
			}
		)
	}

	return (
		<>
			<Header name="Uploaded Document List" />
			<div className="flex justify-center items-center min-h-screen bg-gray-100 mt-16 md:mt-0 relative">
				<div className="m-auto max-w-screen-xl absolute left-0 right-0 top-1/2 -translate-y-1/2 px-8">
					{data?.Data?.length > 0 ? (
						<div className="bg-green-600 hidden md:flex md:items-center md:justify-between md:px-4 md:py-4 gap-4">
							<div className="md:w-8/12 lg:w-9/12">
								<h1 className="text-white text-base font-semibold">
									Particulars
								</h1>
							</div>
							<div className="md:w-4/12 lg:w-3/12">
								<h1 className="text-white text-base font-semibold">
									Documents
								</h1>
							</div>
						</div>
					) : (
						<div className="py-4 px-4 bg-white rounded-md">
							<h1 className="text-center">Data is not found</h1>
						</div>
					)}
					<div className="max-h-[800px] md:max-h-[600px] overflow-y-auto">
						{data?.Data?.length > 0 &&
							data?.Data?.map((item: any, index: number) => {
								const isDocumentUploaded = item?.documentPath // Check if document is uploaded
								return (
									<div
										key={index}
										className="bg-white md:flex md:items-center md:justify-between md:px-4 md:py-4 gap-4 rounded-md md:rounded-none mb-4 md:mb-0 border-b-2"
									>
										<div className="w-full md:w-8/12 lg:w-9/12">
											<div className="bg-green-600 px-4 flex md:hidden py-2 shadow-shadow1 rounded-tl-md rounded-tr-md border-b-[1px] border-white border-solid">
												<h1 className="text-white text-base font-semibold">
													Particulars
												</h1>
											</div>
											<div className="py-4 px-4 md:px-0 flex items-center gap-2">
												{item?.loanDocumentName === 'Aadhar Card' ? (
													<img
														src={documentIcons.Aadhar}
														alt="Document Icon"
														className="w-8 h-8 mr-2"
													/>
												) : item?.loanDocumentName === 'Pan Card' ? (
													<img
														src={documentIcons.Pan}
														alt="Document Icon"
														className="w-8 h-8 mr-2"
													/>
												) : item?.loanDocumentName === 'Bank Statement' ? (
													<img
														src={documentIcons.BankStatement}
														alt="Document Icon"
														className="w-8 h-8 mr-2"
													/>
												) : item?.loanDocumentName === 'Cancelled Cheque' ? (
													<img
														src={documentIcons.CancelledCheck}
														alt="Document Icon"
														className="w-8 h-8 mr-2"
													/>
												) : item?.loanDocumentName === 'Gst Certificate' ? (
													<img
														src={documentIcons.GstCertificate}
														alt="Document Icon"
														className="w-8 h-8 mr-2"
													/>
												) : item?.loanDocumentName === 'Form16' ? (
													<img
														src={documentIcons.Form16}
														alt="Document Icon"
														className="w-8 h-8 mr-2"
													/>
												) : item?.loanDocumentName === 'ItReturn' ? (
													<img
														src={documentIcons.ItReturn}
														alt="Document Icon"
														className="w-8 h-8 mr-2"
													/>
												) : (
													<img
														src={documentIcons.Profile}
														alt="Document Icon"
														className="w-7 h-7 mr-2"
													/>
												)}

												<p className="text-gray-500 text-md text-center md:text-left font-semibold">
													{item?.loanDocumentName}
												</p>
											</div>
										</div>
										<div className="w-full md:w-4/12 lg:w-3/12">
											<div className="flex justify-center md:justify-start gap-6 md:gap-4 py-4 px-4 md:px-0">
												<a
													href={item?.documentPath}
													target="_blank"
													rel="noopener noreferrer"
												>
													<button
														disabled={!item?.documentPath || loading}
														className="px-4 py-1 text-gray-500 border-solid border-[1px] border-gray-400 rounded-lg hover:bg-green-600 hover:text-white
                            hover:border-green-600 shadow-shadow1"
													>
														Preview
													</button>
												</a>
												{/* Show success or failure icon */}
												<img
													src={isDocumentUploaded ? successIcon : failedIcon}
													alt={isDocumentUploaded ? 'Success' : 'Failure'}
													className="w-6 h-6 ml-16"
												/>
											</div>
										</div>
									</div>
								)
							})}
					</div>
					<CustomModal
						isOpenCustModal={isModalOpen}
						onClose={closeModal}
						title=""
						modalName=""
					>
						<p>Application on hold for consultation</p>
					</CustomModal>
					{borrowerID !== undefined &&
					role === 'ADMIN' &&
					origin === 'pendingBorrowers' ? (
						<>
							<div className="flex mt-4 gap-5 remarkBox items-center justify-center">
								<div className="w-1/2 flex items-center gap-4 justify-center">
									<label>Remarks</label>
									<textarea
										maxLength={500}
										value={remarks}
										onChange={(e) => {
											setRemarks(e.target.value)
										}}
										className="w-full"
									/>
								</div>
								<div className="">
									<button
										type="button"
										className="submitBtn"
										onClick={handleApprove}
									>
										Approve
									</button>
								</div>
							</div>
						</>
					) : role !== 'AGENT' && role !== 'ADMIN' ? (
						<>
							<div className="px-5 space-y-2 mt-4">
								<div className="flex items-center">
									<input
										type="checkbox"
										id="terms_checkbox1"
										className="w-4 h-4 text-green-600 border-gray-300 rounded focus:ring-green-500 cursor-pointer"
										onChange={() => setIsChecked2(!isChecked2)}
									/>
									<p className="ml-2 text-sm text-grey-600 cursor-pointer">
										<span onClick={() => handleClickInput('terms_checkbox1')}>
											All information provided is true to my knowledge and any
											incorrect information found will be my sole
											responsibility.
										</span>
									</p>
								</div>
								<div className="flex items-center">
									<input
										type="checkbox"
										id="terms_checkbox"
										className="w-4 h-4 text-green-600 border-gray-300 rounded focus:ring-green-500 cursor-pointer"
										onChange={() => setIsChecked(!isChecked)}
									/>
									<p className="ml-2 text-sm text-grey-600 cursor-pointer">
										<span onClick={() => handleClickInput('terms_checkbox')}>
											I agree to Ashapurti Loans{' '}
										</span>
										<span
											className="text-blue-700 cursor-pointer pr-2"
											onClick={handleTermsModal}
										>
											Term Of Use
										</span>
										<span onClick={() => handleClickInput('terms_checkbox')}>
											and
										</span>
										<span
											className="text-blue-700 cursor-pointer pl-2"
											onClick={handleTermsModal}
										>
											Privacy Policy
										</span>
									</p>
								</div>
							</div>
							<CustomModal
								title="Terms and Conditions"
								isOpenCustModal={isTermsModal}
								onClose={closeCustModal}
								modalName="terms"
							>
								<div>
									<p>
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Dicta rem accusamus alias dolor facere at in, repellat
										molestiae similique vero.
									</p>
								</div>
							</CustomModal>
							<div className="flex justify-center mt-4 gap-2">
								<button type="button" className="backBtn " onClick={handleBack}>
									Back
								</button>
								{data?.Data?.length > 0 && (
									<button
										type="submit"
										className="submitBtn px-6"
										onClick={handleRoute}
										disabled={!isChecked || !isChecked2}
									>
										Submit
									</button>
								)}
							</div>
						</>
					) : (
						<></>
					)}
				</div>
			</div>
		</>
	)
}

export default DocumentReviewStatusPage
