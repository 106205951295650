import * as Yup from "yup";

const agentSignupValidation: Yup.Schema = Yup.object().shape({
  firstName: Yup.string().trim()
    .required("Required")
    .min(2, "First Name must be at least 2 characters")
    .max(50, "First Name must not exceed 50 characters")
    .matches(/^[A-Za-z\s]+$/, "First Name should only contain letters"),

  lastName: Yup.string().trim()
    .required("Required")
    .min(2, "Last Name must be at least 2 characters")
    .max(50, "Last Name must not exceed 50 characters")
    .matches(/^[A-Za-z\s]+$/, "Last Name should only contain letters"),

  birthDate: Yup.date()
    .required("Required")
    .max(new Date(), "Birth Date cannot be in the future"),

  gender: Yup.string()
    .required("Required")
    .oneOf(["Male", "Female", "Other"], "Invalid gender selected"),

  maritalStatus: Yup.string()
    .required("Required")
    .oneOf(
      ["Single", "Married", "Divorced", "Widowed"],
      "Invalid marital status"
    ),

  aadharCard: Yup.string()
    .required("Required")
    .matches(/^\d{12}$/, "Aadhar Card must be a 12-digit number"),

  panCard: Yup.string()
    .required("Required").trim()
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN Card format"),

  mobileNumber: Yup.string()
    .required("Required")
    .matches(/^\d{10}$/, "Mobile Number must be a 10-digit number"),

  whatsappNumber: Yup.string()
    .required("Required")
    .matches(/^\d{10}$/, "WhatsApp Number must be a 10-digit number"),

  address: Yup.string().trim()
    .required("Required")
    .max(200, "Address must not exceed 200 characters"),

  villageOrTown: Yup.string().required("Required").trim(),

  taluka: Yup.string().required("Taluka is required").trim(),

  district: Yup.string().required("Required").trim(),

  state: Yup.string().required("Required").trim(),

  domicile: Yup.string().required("Required").trim(),

  pincode: Yup.string()
    .required("Required")
    .matches(/^\d{6}$/, "Pincode must be a 6-digit number"),
});

export default agentSignupValidation;
