import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { AppDispatch, RootState } from '../../utils/store'
import { getReceipt } from '../../redux/disbursement/documentReviewStatusSlice'
import { getPaymentStatus } from '../../utils/apis'
import axios from 'axios'
import { AES, enc, pad } from 'crypto-js'

const secretKey: any = enc.Latin1.parse(process.env.REACT_APP_SECRET_KEY || '')
const initVector: any = enc.Latin1.parse(
	process.env.REACT_APP_INIT_VECTOR || ''
)

function Success() {
	const [searchParams] = useSearchParams()
	const txnId = searchParams.get('merchantTransactionId')

	const decryptData = (ciphertext: any) => {
		var decrypted = AES.decrypt(ciphertext, secretKey, {
			iv: initVector,
			padding: pad.ZeroPadding,
		})
		return decrypted.toString(enc.Utf8)
	}
	const merchantTransactionId = decryptData(
		decodeURIComponent(txnId || '').replace(/ /g, '+')
	)
	// const dispatch = useDispatch<AppDispatch>();
	// const { data } = useSelector((state: RootState) => state.documentReviewStatus)

	const [txnID, setTxnID] = useState('')
	const [status, setStatus] = useState('')
	const [utrNo, setUtrNo] = useState('')

	useEffect(() => {
		// dispatch(getReceipt());
		axios
			.post(
				`https://ashapurtiloans.com:8443/restful/v1/phonePe/txnStatus/${merchantTransactionId}`
			)
			.then(async (response) => {
				console.log(response)
				setTxnID(response?.data?.data?.merchantTransactionId)
				setStatus(response?.data?.data?.responseCode)
				setUtrNo(response?.data?.data?.paymentInstrument?.utr)
			})
			.catch((error) => {
				console.error('Error fetching transaction status:', error)
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className="payEmi-wrapper">
			<div className="w-full h-full min-h-dvh flex justify-center items-center py-10 md:pr-30 relative">
				<div className="py-8 px-0 md:px-6 w-full md:w-[650px] bg-[#f1f5f9] bg-opacity-90 min-h-[300px] rounded-lg backdrop-blur-xl backdrop-filter flex items-center justify-center flex-col shadow-shadow_1">
					<p className="text-4xl font-bold pl-2 text-green-700 mb-4">
						PAYMENT {status}
					</p>
					<p className="font-semibold text-lg mb-2">
						Transaction ID:{' '}
						<span className="text-base font-medium pl-2 text-gray-700">
							{txnID}
						</span>
					</p>
					<p className="font-semibold text-lg mb-6">
						UTR No:{' '}
						<span className="text-base font-medium pl-2 text-gray-700">
							{utrNo}
						</span>
					</p>
					{/* <a href={data?.filePath} target="_blank" rel="noopener noreferrer">
            <button className="px-4 py-1 text-gray-500 border-solid border-[1px] border-gray-400 rounded-lg hover:bg-green-600 hover:text-white hover:border-green-600 shadow-shadow1">
              Get Receipt
            </button>
          </a> */}
					<p className="font-medium text-md mt-4">
						You can close this window now.
					</p>
				</div>
			</div>
		</div>
	)
}

export default Success
