import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postDocumentFile } from "../../utils/apis";
import { DocumentUploadState } from "../../utils/types";

const initialState: DocumentUploadState = {
  data: null,
  loading: false,
  error: null,
  status: 0,
  isIfscCodeVerified: false,
  isBankAccountVerified: false,
  isUpiVerified: false,
};

export const DocumentUpload = createAsyncThunk(
  "DocumentUpload",
  async (body: any) => {
    const response = await postDocumentFile(body);
    return response;
  }
);


const DocumentUploadSlice = createSlice({
  name: "DocumentUpload",
  initialState,
  reducers: {
    setIsIfscCodeVerified: (state, action) => {
      state.isIfscCodeVerified = action.payload;
    },
    setIsBankAccountVerified: (state, action) => {
      state.isBankAccountVerified = action.payload;
    },
    setIsUpiVerified: (state, action) => {
      state.isUpiVerified = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(DocumentUpload.pending, (state) => {
        state.loading = true;
        state.error = null;
        console.log("pending");
      })
      .addCase(DocumentUpload.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(DocumentUpload.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "DocumentUpload failed";
        console.log("rejected");
      });
  },
});

export default DocumentUploadSlice.reducer;
export const { setIsIfscCodeVerified, setIsUpiVerified ,setIsBankAccountVerified} =
  DocumentUploadSlice.actions;
