import { Provider } from 'react-redux'
import { motion, AnimatePresence } from 'framer-motion'
import { store } from './store'
import { useLocation } from 'react-router-dom'
import Header from '../../screens/Landing/Header'
import Footer from '../../screens/Landing/Footer'

const ReduxProvider = ({ children }: any) => {
	// const currentPath = window.location.pathname;

	const location = useLocation()
	const pathname = location.pathname // Get the current path

	const noLayoutPaths = [
		'/PayEmi',
		'/success',
		'/dashboard',
		'/login',
		'/profile',
		'/register',
		'/forgot-password',
		'/reset-password',
		'/loan-categories',
		'/loan-categories/:slug',
		'/notifications',
		'/upload-documents',
		'/disbursement',
		'/borrowerpayment',
		'/demo',
		'/aadhar-ocr',
		'/loandetails',
		'/disbursementforloan',
		'/document-review-status-page',
		'/user-profile',
		'/repayment',
		'/payEmi',
		'/demo-page',
		'/agent-signup',
		'/add-borrower',
		'/edit-borrower',
		'/view-borrower',
		'/HomeScreen',
		'/otp-verification',
		'/borrowerList',
		'/CollectLink',
		'/approvedBorrowers',
		'/agent-profile',
		'/agent-dashboard',
		'/pendingBorrowers',
		'/interestRate',
		'/agentList',
	]
	const shouldHideLayout = noLayoutPaths.some(
		(path) => path.toLowerCase() === pathname.toLowerCase()
	)

	return (
		<Provider store={store}>
			{shouldHideLayout ? (
				<>{children}</>
			) : (
				<>
					<AnimatePresence mode="wait">
						<Header />
						{children}
						<Footer />
						<motion.div
							className="slide-in"
							initial={{ scaleY: 0 }}
							animate={{ scaleY: 0 }}
							exit={{ scaleY: 1 }}
							transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
						></motion.div>
						<motion.div
							className="slide-out"
							initial={{ scaleY: 1 }}
							animate={{ scaleY: 0 }}
							exit={{ scaleY: 0 }}
							transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
						></motion.div>
					</AnimatePresence>
				</>
			)}
		</Provider>
	)
}
export default ReduxProvider
