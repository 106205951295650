import { useEffect, useState } from "react";
import Header from "../../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { getAllDisbursementDetails } from "../../../redux/user/LoanDetailsSlice";
import CustomModal from "../../../components/CustomModal";
import { getUserFromLocalStorage } from "../../../utils/localStorageUtil";
import { AppDispatch, RootState } from "../../../utils/store";
import { handleBeforeUnload } from "../../../utils/getIdFromUrl";
import { useLocation, useNavigate } from "react-router-dom";
import { createEmiIntent, postGeneratedisbursment } from "../../../utils/apis";

export default function LoanDisbursement() {
  const [disbursementData, setDisbursementData] = useState<any>([]);
  const [paymentsDetails, setPaymentsDetails] = useState<any>({});
  const [isCustModalOpen, setIsCustModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [disbursementMessage, setDisbursementMessage] = useState("");
  const [isTermsModal, setTermsModal] = useState(false);
  const [isPaymentDone, setisPaymentDone] = useState(false);
  const [message, setMessage] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const { data } = useSelector((state: RootState) => state.loan);
  const location = useLocation();
  const navigate = useNavigate();
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm: any = today.getMonth() + 1;
  let dd: any = today.getDate();
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  const formattedToday = dd + "-" + mm + "-" + yyyy;

  const handleTermsModal = (e: any) => {
    e.preventDefault();
    setTermsModal(true);
  };

  const closeCustModal = () => {
    setTermsModal(false);
    setisPaymentDone(false);
  };

  const generateDisbursment = async () => {
    const result = await postGeneratedisbursment(borrowerId);
    if (result?.Data?.Status.toLowerCase() === "received") {
      setisPaymentDone(true);
      setDisbursementMessage("Disbursement is done.");
    } else {
      setDisbursementMessage(result?.Data?.Description);
    }
  };

  const handleSubmit = () => {
    setIsCustModalOpen(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const handleCloseModal = () => {
    setIsCustModalOpen(false);
    setisPaymentDone(false);
    setSelectedValue("");
  };

  const handlePaymentSumbit = () => {
    generateDisbursment();
  };

  const handleGetDisbursement = async (borrowerId: any) => {
    await dispatch(getAllDisbursementDetails({ borrowerId })).then(
      (res: any) => {
        setDisbursementData(res.payload);
        setPaymentsDetails(res?.payload?.paymentsDetails[0] || null);
      }
    );
  };

  const handleClickInput = (id: string) => {
    document.getElementById(id)?.click();
  };

  const handleBack = () => {
    navigate("/loandetails");
  };

  const borrowerId = location?.state?.borrowerId
    ? location.state.borrowerId
    : getUserFromLocalStorage()?.borrowerId;

  useEffect(() => {
    handleGetDisbursement(borrowerId);

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const roleType = getUserFromLocalStorage()?.role;

  const handlePayEMI = (amount: any, breakdownId: any) => {
    roleType === "AGENT"
      ? handleAgent(breakdownId)
      : navigate("/payEmi", {
          state: {
            data: location.state,
            amount: amount,
            breakdownId: breakdownId,
          },
        });
  };

  const handleAgent = (breakdownId: any) => {
    createEmiIntent({ borrowerId, breakdownId }).then((response) => {
      setMessage(true);
      setTimeout(() => {
        setMessage(false);
        navigate("/agent-dashboard");
      }, 2000);
    });
  };

  function parseDate(dateStr: string) {
    const [dd, mm, yyyy] = dateStr.split("-").map(Number);
    return new Date(yyyy, mm - 1, dd);
  }

  const boxShadowStyle = {
    boxShadow: "none",
  };

  return (
    <div className="">
      <Header
        name={location.state === "disbursement" ? "Disbursement" : "Repayment"}
      />
      <CustomModal
        title="Payment"
        modalName="payment"
        isOpenCustModal={isCustModalOpen}
        onClose={handleCloseModal}
      >
        {data && (
          <div>
            {isPaymentDone ? (
              <>
                <h1 className="text-center">{disbursementMessage}</h1>
              </>
            ) : (
              <>
                <div
                  className="flex w-full items-center py-4 border-b-2 cursor-pointer"
                  onClick={() => handleClickInput("upi-radio")}
                >
                  <div className="text-start w-5/6 ">
                    <p className="font-semibold text-lg">UPI ID</p>
                    <p className="text-gray-500">
                      {paymentsDetails?.upiId || ""}
                    </p>
                  </div>
                  <div className="text-start w-1/6">
                    <input
                      type="radio"
                      value={1}
                      name="payment"
                      id="upi-radio"
                      onChange={handleChange}
                      className="w-5 h-5"
                      style={boxShadowStyle}
                    />
                  </div>
                </div>
                <div
                  className="flex w-full items-center border-b-2 cursor-pointer"
                  onClick={() => handleClickInput("account-radio")}
                >
                  <div className="text-start w-5/6 py-5">
                    <p className="font-semibold text-lg">Bank Account</p>
                    <span className="text-gray-500">
                      <p>IFSC Code: {paymentsDetails?.bankIfscCode || ""}</p>
                      <p>
                        Bank Account Number:
                        {paymentsDetails?.bankAccountNumber || ""}
                      </p>
                      <p>
                        Bank Account Name: {paymentsDetails?.bankAccountName}
                      </p>
                    </span>
                  </div>
                  <div className="text-start w-1/6">
                    <input
                      type="radio"
                      value={2}
                      name="payment"
                      id="account-radio"
                      onChange={handleChange}
                      className="w-5 h-5"
                      style={boxShadowStyle}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="pt-5 flex justify-center">
              <button
                onClick={isPaymentDone ? handleCloseModal : handlePaymentSumbit}
                className="bg-green-700 text-white border-none px-4 py-2 shadow-lg rounded-lg cursor-pointer hover:bg-green-800 "
                disabled={selectedValue === ""}
              >
                {isPaymentDone ? "OK" : "SUBMIT"}
              </button>
            </div>
          </div>
        )}
      </CustomModal>
      <CustomModal
        title="Terms and Conditions"
        isOpenCustModal={isTermsModal}
        onClose={closeCustModal}
        modalName="terms"
      >
        <div>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta rem
            accusamus alias dolor facere at in, repellat molestiae similique
            vero.
          </p>
        </div>
      </CustomModal>
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <div className="bg-white mx-auto p-6 rounded-lg shadow-lg max-w-screen-2xl w-full mt-24">
          <p className="text-lg  text-blue-600 border-b pb-2 mb-2">
            Total Disbursed
          </p>
          <p className="text-lg text-gray-700 flex items-center border-b pb-2 mx-2 mb-2">
            <span className="pr-4 text-left w-2/3">Loan Amount</span>
            <span className=" text-red-600 text-right w-1/3">
              ₹ {disbursementData?.loanAmount}
            </span>
          </p>
          <p className="text-lg text-gray-700 flex items-center border-b pb-2 mx-2 mb-2">
            <span className="pr-4 text-left w-2/3">Rate Of Interest</span>
            <span className=" text-red-600 text-right w-1/3">
              {disbursementData?.rateOfInterest &&
                disbursementData?.rateOfInterest + "%"}
            </span>
          </p>
          <p className="text-lg text-gray-700 flex items-center border-b pb-2 mx-2 mb-2">
            <span className="pr-4 text-left w-2/3">Tenure</span>
            <span className=" text-red-600 text-right w-1/3">
              {disbursementData?.tenure}
            </span>
          </p>
          <p className="text-lg  text-blue-600 border-b pb-2  mt-4">
            EMI Table
          </p>
          <div className="">
            <div
              className={`hidden sm:grid ${
                location.state !== "disbursement"
                  ? "sm:grid-cols-8"
                  : "sm:grid-cols-6"
              } text-lg text-gray-700 border-b`}
            >
              <div className="bg-green-700 text-white text-center border-r p-2">
                SL No
              </div>
              <div className="bg-green-700 text-white text-center border-r p-2">
                Date
              </div>
              <div className="bg-green-700 text-white text-center border-r p-2">
                Principal
              </div>
              <div className="bg-green-700 text-white text-center border-r p-2">
                Interest
              </div>
              <div className="bg-green-700 text-white text-center border-r p-2">
                EMI
              </div>
              <div className="bg-green-700 text-white text-center border-r p-2">
                Balance
              </div>
              {location.state !== "disbursement" && (
                <>
                  <div className="bg-green-700 text-white text-center border-r p-2">
                    Action
                  </div>
                  <div className="bg-green-700 text-white text-center p-2">
                    Receipt
                  </div>
                </>
              )}
            </div>
            {disbursementData?.emiTable?.map((loan: any, index: number) => (
              <>
                <div className="relative">
                  <div
                    key={index}
                    className={`border-b sm:grid ${
                      location.state !== "disbursement"
                        ? "sm:grid-cols-8"
                        : "sm:grid-cols-6"
                    } text-base text-gray-700 flex flex-col sm:flex-none`}
                  >
                    <div className="sm:hidden bg-green-700 text-white p-2 shadow-shadow1 rounded-md">
                      SL No
                    </div>
                    <div className="text-center border-r p-2">
                      {loan?.serialNo}
                    </div>
                    <div className="sm:hidden bg-green-700 text-white p-2 shadow-shadow1 rounded-md">
                      Date
                    </div>
                    <div className="text-center border-r p-2">
                      {loan?.emiDate}
                    </div>
                    <div className="sm:hidden bg-green-700 text-white p-2 shadow-shadow1 rounded-md">
                      Principal
                    </div>
                    <div className="text-center border-r p-2">
                      ₹ {loan?.principal}
                    </div>
                    <div className="sm:hidden bg-green-700 text-white p-2 shadow-shadow1 rounded-md">
                      Interest
                    </div>
                    <div className="text-center border-r p-2">
                      ₹ {loan?.interestAmount}
                    </div>
                    <div className="sm:hidden bg-green-700 text-white p-2 shadow-shadow1 rounded-md">
                      EMI
                    </div>
                    <div className="text-center border-r p-2">₹ {loan.emi}</div>

                    <div className="sm:hidden bg-green-700 text-white p-2 shadow-shadow1 rounded-md">
                      Balance
                    </div>
                    <div className="text-center border-r p-2">
                      ₹ {loan?.balance}
                    </div>

                    {location.state !== "disbursement" && (
                      <>
                        <div className="sm:hidden bg-green-700 text-white p-2 shadow-shadow1 rounded-md">
                          Action
                        </div>
                        <div className="text-center border-r flex items-center justify-center">
                          <button
                            className="bg-blue-700 text-white border-none rounded-lg cursor-pointer hover:bg-green-800 px-4 py-1 mx-8 my-[0.2rem]"
                            onClick={() =>
                              handlePayEMI(loan.emi, loan.breakdown_id)
                            }
                            disabled={
                              !(
                                loan?.status === "pending" &&
                                parseDate(loan?.emiDate) <=
                                  parseDate(formattedToday)
                              )
                              // loan?.emiDate !== formattedToday ||
                              // loan?.status === "success"
                            }
                          >
                            {location.state.borrowerId
                              ? loan.status === "success"
                                ? "Paid"
                                : "Collect"
                              : loan.status === "success"
                              ? "Paid"
                              : "Pay EMI"}
                          </button>
                        </div>
                        <div className="sm:hidden bg-green-700 text-white p-2 shadow-shadow1 rounded-md">
                          Receipt
                        </div>
                        <div className="text-center border-r">
                          {loan?.receiptPath !== null ? (
                            <a
                              className="bg-blue-700 text-white border-none rounded-lg cursor-pointer hover:bg-green-800 px-4 py-1
                              inline-block mx-8 my-[0.2rem]"
                              href={loan?.receiptPath}
                              target="_blank"
                              rel="noreferrer"
                            >
                              View
                            </a>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            ))}
          </div>
          {message && (
            <div className="modal-overlay">
              <div className="modal-content">Payment Link Sent</div>
            </div>
          )}
          {location.state === "disbursement" && (
            <>
              <p className="text-sm  text-red-600  pb-2 mt-2">
                Less then 1 lakhs rupees can accept in UPI ID
              </p>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="terms_checkbox"
                  className="w-4 h-4 text-green-600 border-gray-300 rounded focus:ring-green-500"
                  onChange={() => setIsChecked(!isChecked)}
                />
                <p className="ml-2 text-sm text-grey-600">
                  <span onClick={() => handleClickInput("terms_checkbox")}>
                    I agree to Ashapurti Loans{" "}
                  </span>
                  <span
                    className="text-blue-700 cursor-pointer pr-2"
                    onClick={handleTermsModal}
                  >
                    Term Of Use
                  </span>
                  <span onClick={() => handleClickInput("terms_checkbox")}>
                    and
                  </span>
                  <span
                    className="text-blue-700 cursor-pointer pl-2"
                    onClick={handleTermsModal}
                  >
                    Privacy Policy
                  </span>
                </p>
              </div>
              <div className="flex justify-center items-center mt-4 gap-2">
                <button
                  onClick={handleBack}
                  className="bg-green-700 text-white border-none px-4 py-2 rounded-lg cursor-pointer hover:bg-green-800 w-28"
                >
                  Back
                </button>
                <button
                  onClick={handleSubmit}
                  disabled={!isChecked}
                  className="bg-green-700 text-white border-none px-4 py-2 rounded-lg cursor-pointer hover:bg-green-800"
                >
                  Disburse
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
