import Container from "../../components/ui/Container";

function PrivacyPolicy() {
  return (
    <section className="my-8 inner-content legalContent">
      <Container>
        <h4 className="text-3xl mb-6 text-left">
          <span className="text-slate-900 font-bold">Privacy Policy</span>
          {/* <span className="font-bold">Policy</span> */}
        </h4>
        <div className="space-y-2">
          <p>
            <strong>Golden Legand Leasing and Finance Limited</strong> a
            Non-Banking Financial Company (NBFC) licensed by the Reserve Bank of
            India, is committed to providing loans and financial services to
            individuals across various categories. In pursuit of this objective,
            we have developed a digital lending platform,&nbsp;
            <a href="https://ashapurtiloans.com/">www.ashapurti.com</a>
            &nbsp;(<b>“GLLFL”</b> or <b>“Ashapurti”</b> or <b>“We”</b>), along
            with its associated mobile application, ____AshapurtiLoans_______
            (the <b>“Application”</b> or <b>“App”</b>) (collectively, the
            “Platform”). At GLLFL, we recognize the importance of privacy and
            are committed to safeguarding your personal information. This
            Privacy Policy explains how we collect, use, store, and share your
            information when you access or use our Platform. By using the
            Platform, you (“You” or “Customer” or “User”) acknowledge and
            consent to the practices described in this Privacy Policy. Our
            Privacy Policy is formulated in compliance with the following laws,
            regulations, and guidelines:
            <ul className="list-decimal">
              <li>
                <b>Master Directions on Prepaid Payment Instruments (PPIs)</b>{" "}
                issued by the Reserve Bank of India (RBI);
              </li>
              <li>
                <b>
                  Information Technology (Reasonable Security Practices and
                  Procedures and Sensitive Personal Data or Information) Rules,
                  2011;
                </b>
              </li>
              <li>
                <b>
                  Information Technology (Intermediary Guidelines and Digital
                  Media Ethics Code) Rules, 2021;
                </b>
              </li>
              <li>
                <b>Guidelines on Digital Lending</b> issued by the Reserve Bank
                of India (RBI), 2022;
              </li>
              <li>
                <b>Other applicable laws, regulations, and rules</b>&nbsp;that
                mandate the publication of a privacy policy for collecting,
                handling, and processing personal information, including
                sensitive personal data or information. This includes all
                applicable guidelines issued by regulatory authorities,
                including but not limited to the Reserve Bank of India (RBI).
              </li>
            </ul>
          </p>
        </div>

        <h3 className="mt-4 font-bold text-lg">CONSENT</h3>
        <div className="space-y-2">
          <p>
            By using our Platform, you expressly consent to provide the
            information necessary for availing the Services (as defined below)
            we offer . You acknowledge that we collect and process the
            information outlined in this Privacy Policy to facilitate lending
            and non-lending services through partnerships with various financial
            lenders, third parties, and service providers, based on your
            requirements (collectively, the <b>“Services”</b>).
          </p>
          <p>
            Ashapurti will use your information solely to provide these
            Services.
          </p>
          <p>
            To avail any of the Services offered by Ashapurti, whether
            independently or in collaboration with lenders or other third
            parties, it is <b>essential</b> that you{" "}
            <b>read, understand, acknowledge, and unconditionally agree</b> to
            be bound by the terms of this Privacy Policy.
          </p>
          <p>
            <b>
              If you do not agree to this privacy policy or any part thereof,
              please refrain from using, accessing, downloading, or installing
              the platform or any of its features.
            </b>
          </p>
          <p>
            For users who consent to continue accessing the Platform and
            availing the Services, this Privacy Policy details our policies and
            practices regarding the collection, use, and disclosure of your
            information.
          </p>
        </div>

        <h3 className="mt-4 font-bold text-lg">COLLECTION OF INFORMATION</h3>
        <div className="space-y-2">
          <p>
            The collection of information under this Privacy Policy is conducted
            for the following categories of services:
          </p>
          <ul className="list-item">
            <li>
              <b>Part A: Information for Digital Lending Services</b>
              <p>
                This includes information collected by the Platform to
                facilitate loans disbursed by financial lending partners, whose
                details are available on the Platform and who are registered
                with the Reserve Bank of India (“Lending Partners”).
              </p>
            </li>
            <li>
              <b>Part B: Information for Non-Lending Services</b>
              <p>
                This includes information collected by the Platform while
                registering a User or providing Prepaid Instrument Services,
                such as wallets, UPI, and other non-lending financial services.
              </p>
            </li>
          </ul>
        </div>

        <h3 className="mt-4 font-bold text-lg">A. Traffic Data Collected</h3>
        <div className="space-y-2">
          <p>
            When you visit our Platform, we automatically track and collect
            certain information, including IP addresses, domain servers, types
            of devices accessing the Platform, types of web browsers used to
            access the Platform, referring sources that directed you to the
            Platform, and other information related to your browser's
            interaction with the Platform (collectively, <b>“Traffic Data”</b>).
          </p>
        </div>

        <h3 className="mt-4 font-bold text-lg">
          B. Information We Collect About You
        </h3>
        <div className="space-y-2">
          <p>
            To facilitate Prepaid Payment Instruments (PPI) services, as well as
            lending and non-lending services, <b>Ashapurti</b> may need to
            access, collect, and share your <b>Personal Information</b> with its{" "}
            <b>Lending Partners</b>, including banks and NBFCs registered with
            the Reserve Bank of India (RBI), or other third parties providing
            value-added services in collaboration with Ashapurti.
          </p>
          <p>
            Ashapurti ensures that any shared information is securely
            transmitted and that all recipients adhere to strict
            confidentiality, fidelity, and secrecy obligations through legally
            binding agreements.
          </p>
          <p>
            Ashapurti may also disclose information to{" "}
            <b>
              financial and non-financial companies, government agencies,
              courts, and legal investigators
            </b>
            , and <b>other non-affiliated third parties</b>, as required or
            permitted by law, or as requested by you or your authorized
            representative.
          </p>
        </div>

        <h3 className="mt-4 font-bold text-lg">1. User Personal Information</h3>
        <div className="space-y-2">
          <p>
            To provide both lending and non-lending services, we collect the
            following personal data from you, including but not limited to full
            name, email ID, PAN, Aadhaar, GST Network user ID & password,
            address, mobile number and postal code.
          </p>
        </div>

        <h3 className="mt-4 font-bold text-lg">
          2. Social Account Information
        </h3>
        <div className="space-y-2">
          <p>
            Ashapurti may provide you with the option to register on the
            Platform using your social accounts (e.g., Google). If you choose
            this option, we will collect only the following information like
            registered email ID and Public profile information, such as name and
            email (as permitted by the respective platform).
            <ul className="list-disc">
              <b>How we use this information:</b>
              <li>
                We collect and store your email ID, name, and address associated
                with the linked account for verification purposes.
              </li>
              <li>
                This data helps us pre-populate relevant fields within the
                Platform for ease of access.
              </li>
              <li>We do not collect or store your social account passwords.</li>
            </ul>
          </p>
        </div>

        <h3 className="mt-4 font-bold text-lg">3. SMS Information</h3>
        <div className="space-y-2">
          <p>
            Ashapurti does not collect or store personal SMS messages from your
            inbox. We only collect, store, and monitor SMS messages sent by
            six-digit alphanumeric senders.
            <ul className="list-disc">
              <b>How we use this information:</b>
              <li>
                This data is used to provide you with updates, notifications, or
                confirmations of any actions taken on our Platform.
              </li>
              <li>
                We may collect SMS information to facilitate lending and
                non-lending services as required by our Lending Partners or as
                per applicable laws.
              </li>
              <li>
                This category of information is collected exclusively for
                providing non-lending services or value-added services.
              </li>
            </ul>
          </p>
        </div>
      </Container>
    </section>
  );
}

export default PrivacyPolicy;
