import { useSelector } from 'react-redux'
import { RootState } from '../../utils/store'
import { confirmProp } from '../../utils/types'
import { FC, useEffect, useState } from 'react'
import { removePrefixFromKeys } from '../../redux/registration/occupation/constant'

const Confirmation: FC<confirmProp> = ({ source }) => {
	const { personalInfo, occupation, document } = useSelector(
		(state: RootState) =>
			source === 'Register'
				? state?.registrationFormData
				: state?.profile.data?.borrowerData || {}
	)
	const { empSubType, empType } = useSelector(
		(state: RootState) => state.registrationSlice
	)

	const [occupationData, setOccupationData] = useState<any>()
	useEffect(() => {
		setOccupationData(removePrefixFromKeys(occupation))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className="confirmation space-y-4 min-h-[545px]">
			{!personalInfo ? (
				<div>Loading...</div>
			) : (
				<div className="confirmation_details ">
					<h4>Personal Information</h4>
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-0 border border-gray-300">
						<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
							Full Name:
							<span className="block lg:inline pl-0 lg:pl-3 font-medium capitalize">
								{personalInfo?.fullName}
							</span>
						</p>
						<p className="font-semibold text-sm border-b border-gray-300 p-1 pl-2">
							Mobile:
							<span className="pl-3 font-medium capitalize">
								{personalInfo?.mobileNumber}
							</span>
						</p>
						<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
							Date of Birth:
							<span className="pl-3 font-medium capitalize">
								{personalInfo?.birthDate}
							</span>
						</p>
						<p className="font-semibold text-sm border-b border-gray-300 p-1 pl-2">
							Email:
							<span className="pl-3 font-medium">{personalInfo?.email}</span>
						</p>
						<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
							PAN Card:
							<span className="pl-3 font-medium capitalize">
								{personalInfo?.panCardNumber}
							</span>
						</p>
						<p className="font-semibold text-sm border-b border-gray-300 p-1 pl-2">
							Aadhar Card:
							<span className="pl-3 font-medium capitalize">
								{personalInfo?.aadhaarNumber}
							</span>
						</p>
						<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
							Address:
							<span className="pl-3 font-medium capitalize">
								{personalInfo?.address}
							</span>
						</p>
						<p className="font-semibold text-sm border-b border-gray-300 p-1 pl-2">
							City:
							<span className="pl-3 font-medium capitalize">
								{personalInfo?.city}
							</span>
						</p>
						<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
							State:
							<span className="pl-3 font-medium capitalize">
								{personalInfo?.state}
							</span>
						</p>
						<p className="font-semibold text-sm border-b border-gray-300 p-1 pl-2">
							Pin Code:
							<span className="pl-3 font-medium capitalize">
								{personalInfo?.pincode}
							</span>
						</p>
						<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
							Gender:
							<span className="pl-3 font-medium capitalize">
								{personalInfo?.gender || 'Female'}
							</span>
						</p>
						<p className="font-semibold text-sm border-b border-gray-300 p-1 pl-2">
							Marital Status:
							<span className="pl-3 font-medium capitalize">
								{personalInfo?.maritalStatus || 'Married'}
							</span>
						</p>
						<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
							Loan Type:
							<span className="pl-3 font-medium capitalize">
								{personalInfo?.loanTypeId === 1 ||
								personalInfo?.loanTypeId.loanTypeId === 1
									? 'Personal Loan'
									: personalInfo?.loanTypeId === 2 ||
									  personalInfo?.loanTypeId.loanTypeId === 2
									? 'Gold Loan'
									: ''}
							</span>
						</p>
						<p className="font-semibold text-sm border-b border-gray-300 p-1 pl-2">
							Loan Amount:
							<span className="pl-3 font-medium capitalize">
								{personalInfo?.loanAmount}
							</span>
						</p>
						<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
							Tenure:
							<span className="pl-3 font-medium capitalize">
								{personalInfo?.loanTenureId === 1 ||
								personalInfo?.loanTenureId.loanTenureId === 1
									? '100 days'
									: personalInfo?.loanTenureId === 2 ||
									  personalInfo?.loanTenureId.loanTenureId === 2
									? '200 days'
									: personalInfo?.loanTenureId === 3 ||
									  personalInfo?.loanTenureId.loanTenureId === 3
									? '300 days'
									: personalInfo?.loanTenureId === 4 ||
									  personalInfo?.loanTenureId.loanTenureId === 4
									? '52 weeks'
									: personalInfo?.loanTenureId === 5 ||
									  personalInfo?.loanTenureId.loanTenureId === 5
									? '104 weeks'
									: ''}
							</span>
						</p>
						<p className="font-semibold text-sm border-b border-gray-300 p-1 pl-2">
							UPI ID:
							<span className="pl-3 font-medium capitalize">
								{document?.upiId}
							</span>
						</p>
						<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
							Bank Account Number:
							<span className="pl-3 font-medium capitalize">
								{document?.bankAccountNumber}
							</span>
						</p>
						<p className="font-semibold text-sm border-b border-gray-300 p-1 pl-2">
							Bank Name:
							<span className="pl-3 font-medium capitalize">
								{document?.bankAccountName}
							</span>
						</p>
						<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
							IFSC Code:
							<span className="pl-3 font-medium capitalize">
								{document?.bankIfscCode}
							</span>
						</p>
					</div>
					<div className="confirm-OccInfo mt-4">
						<h4>Occupation Information</h4>
						<div className="grid grid-cols-1 lg:grid-cols-2 gap-0 border border-gray-300 pl-4">
							{empType && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Type:
									<span className="pl-3 font-medium capitalize">
										{empType || 'Not Specified'}
									</span>
								</p>
							)}
							{empSubType && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Sub-Type:
									<span className="pl-3 font-medium capitalize">
										{empSubType || 'Not Specified'}
									</span>
								</p>
							)}
							{/* Employed Occupation Information Section */}
							{occupationData?.nameOfOrganisation && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Name Of Organisation:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.nameOfOrganisation || 'Not Provided'}
									</span>
								</p>
							)}
							{occupationData?.nameOfCompany && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Name Of Company:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.nameOfCompany || 'Not Specified'}
									</span>
								</p>
							)}
							{occupationData?.nameOfBank && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Name Of Bank:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.nameOfBank || 'Not Provided'}
									</span>
								</p>
							)}
							{occupationData?.designation && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Designation:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.designation || 'Not Provided'}
									</span>
								</p>
							)}
							{occupationData?.department && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Department:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.department || 'Not Available'}
									</span>
								</p>
							)}

							{occupationData?.yearsInCurrentDesignation && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Years In Current Designation:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.yearsInCurrentDesignation || '0'}
									</span>
								</p>
							)}

							{occupationData?.officePhoneNumber && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Office Phone Number:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.officePhoneNumber || 'Unavailable'}
									</span>
								</p>
							)}
							{occupationData?.annualIncome && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Annual Income:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.annualIncome || 'Not Disclosed'}
									</span>
								</p>
							)}
							{occupationData?.lastMonthTakeHomeSalary && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Last Month Take Home Salary:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.lastMonthTakeHomeSalary ||
											'Not Applicable'}
									</span>
								</p>
							)}
							{/* Employed Occupation Information Section end */}
							{/* Self-Employed Occupation Information Section */}
							{occupationData?.registeredNumber && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Registered Number:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.registeredNumber || 'Not Provided'}
									</span>
								</p>
							)}
							{occupationData?.specialisation && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Specialisation:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.specialisation || 'Not Specified'}
									</span>
								</p>
							)}
							{occupationData?.gstNumber && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									GST Number:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.gstNumber || 'Not Provided'}
									</span>
								</p>
							)}
							{occupationData?.bussinessName && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Business Name:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.bussinessName || 'Not Specified'}
									</span>
								</p>
							)}
							{occupationData?.typeOfBusiness && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Type Of Business:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.typeOfBusiness || 'Not Specified'}
									</span>
								</p>
							)}
							{occupationData?.yearsInPractise && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Years In Practice:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.yearsInPractise || '0'}
									</span>
								</p>
							)}
							{occupationData?.previousYearITReturn && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Previous Year IT Return:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.previousYearITReturn || 'Not Disclosed'}
									</span>
								</p>
							)}
							{occupationData?.amountOfTaxPaid && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Amount Of Tax Paid:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.amountOfTaxPaid || 'Not Provided'}
									</span>
								</p>
							)}
							{occupationData?.bankAccountNumber && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Bank Account Number:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.bankAccountNumber || 'Not Available'}
									</span>
								</p>
							)}
							{occupationData?.bankIfsccode && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Bank IFSC Code:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.bankIfsccode || 'Not Provided'}
									</span>
								</p>
							)}
							{occupationData?.occupationAddress && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Office Address:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.occupationAddress || 'Not Provided'}
									</span>
								</p>
							)}

							{occupationData?.offCity && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									City:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.offCity || 'Not Specified'}
									</span>
								</p>
							)}

							{occupationData?.offStateOrCentral && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									State Or Central:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.offStateOrCentral || 'Not Specified'}
									</span>
								</p>
							)}
							{occupationData?.offPincode && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Pin Code:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.offPincode || 'Not Specified'}
									</span>
								</p>
							)}
							{occupationData?.natureOfWork && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Nature Of Work:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.natureOfWork || 'Not Specified'}
									</span>
								</p>
							)}
							{occupationData?.yearsOfExperience && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Years Of Experience:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.yearsOfExperience || '0'}
									</span>
								</p>
							)}
							{occupationData?.other && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Other:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.other || 'Not Provided'}
									</span>
								</p>
							)}
							{occupationData?.natureOfBusiness && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Nature Of Business:
									<span className="pl-3 font-medium capitalize">
										{occupationData?.natureOfBusiness || 'Not Specified'}
									</span>
								</p>
							)}

							{occupation?.city && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									City:
									<span className="pl-3 font-medium capitalize">
										{occupation?.city || 'Not Specified'}
									</span>
								</p>
							)}
							{occupation?.stateOrCentral && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									State Or Central:
									<span className="pl-3 font-medium capitalize">
										{occupation?.stateOrCentral || 'Not Specified'}
									</span>
								</p>
							)}
							{occupation?.pincode && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Pin Code:
									<span className="pl-3 font-medium capitalize">
										{occupation?.pincode || 'Not Specified'}
									</span>
								</p>
							)}
							{occupation?.natureOfWork && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Nature Of Work:
									<span className="pl-3 font-medium capitalize">
										{occupation?.natureOfWork || 'Not Specified'}
									</span>
								</p>
							)}
							{occupation?.yearsOfExperience && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Years Of Experience:
									<span className="pl-3 font-medium capitalize">
										{occupation?.yearsOfExperience || '0'}
									</span>
								</p>
							)}
							{occupation?.other && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Other:
									<span className="pl-3 font-medium capitalize">
										{occupation?.other || 'Not Provided'}
									</span>
								</p>
							)}
							{occupation?.natureOfBusiness && (
								<p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
									Nature Of Business:
									<span className="pl-3 font-medium capitalize">
										{occupation?.natureOfBusiness || 'Not Specified'}
									</span>
								</p>
							)}

							{/* self emplyeed data end here */}
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
export default Confirmation
